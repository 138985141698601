import { Product } from './product.model';
import { Menu } from './menu.model';


export enum ItemMenuProductType {
    product = 'product',
    menu = 'menu'
}
export class ItemMenuProduct {
    id: number;
    position: number;
    type: ItemMenuProductType;
    product: Product;
    menu: Menu;

    constructor(id: number, position: number, type: ItemMenuProductType, product: Product, menu: Menu) {
        this.id = id;
        this.position = position;
        this.type = type;
        this.product = product;
        this.menu = menu;
    }
}
