// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://access-api-qa.iloworks.be',
  googleAppId: '468571638057-dubnhsedf94jvke55vkjpn7p528os8b1.apps.googleusercontent.com',
  fbAppId: '867857651069005',
  googleApiKey: 'AIzaSyBX7j7S53_uhVNlKa1CpefU4iEbj9-Q0Lg',
  googleGeocodeApiKey: 'AIzaSyCO5a8yq-NnF9ikvPAhdQES2z8smbAdQfQ',
  googleApiUrl: 'https://maps.googleapis.com/maps/api/js?key=:api_key&libraries=drawing,places',
  geoCodeUrl: 'https://maps.googleapis.com/maps/api/geocode/json?address=:address&key=:api_key',
  geoCodeUrlReverse: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=:latLng&key=:api_key',
  stripePublicKey: 'pk_test_51IYRZfGNNlTCux0ZrtXuiUst5YG4JCGobAkQI6Jz24DC7b9shRCMdGIqfMpy0xjrAAqmXSa0lhFcopGvPD4vSiU500x1obpSXu',
  sendgrid: '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'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
