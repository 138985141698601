import { CollectionPoint } from "./collection-point.model";

export class ClickCollect {

  id: number;
  delivery: boolean;
  takeAway: boolean;
  payment: boolean;
  paymentInfo: string;
  counterPayment: boolean;
  onTableOrder: boolean;
  manufacturingTime: number;
  minOrder: number;
  scheduling: string;
  qrcodeMenuId: number;
  reservations: boolean;
  collectionPoints: CollectionPoint[];

  constructor(
    id: number,
    delivery: boolean,
    takeAway: boolean,
    payment: boolean,
    paymentInfo: string,
    counterPayment: boolean,
    onTableOrder: boolean,
    manufacturingTime: number,
    minOrder: number,
    scheduling: string,
    qrcodeMenuId: number,
    reservations: boolean,
    collectionPoints: CollectionPoint[]) {
      this.id = id;
      this.delivery = delivery;
      this.takeAway = takeAway;
      this.payment = payment;
      this.paymentInfo = paymentInfo;
      this.counterPayment = counterPayment;
      this.onTableOrder = onTableOrder;
      this.manufacturingTime = manufacturingTime;
      this.minOrder = minOrder;
      this.scheduling = scheduling;
      this.qrcodeMenuId = qrcodeMenuId;
      this.reservations = reservations;
      this.collectionPoints = collectionPoints;
  }
}
