import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client } from 'src/app/core/models/client.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';

@Component({
	selector: 'app-confirmation-click-go',
	templateUrl: './confirmation-click-go.component.html',
	styleUrls: ['./confirmation-click-go.component.scss']
})
export class ConfirmationClickGoComponent {
	
	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() hash: string;
	@Input() type: string = 'now';
	
	constructor(
		protected route: ActivatedRoute,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService

	) {}
}
