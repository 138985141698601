import { Injectable } from "@angular/core";
import { Family } from "../family.model";
import { Adapter } from "./adapter";
import { MenuAdapter } from "./menu.adapter";
import { ProductAdapter } from "./product.adapter";

@Injectable({
    providedIn: "root",
})
export class FamilyAdapter implements Adapter<Family> {

    constructor(
        protected productAdapter: ProductAdapter,
        protected menuAdapter: MenuAdapter
    ) {}

    adapt(item: any): Family {

        let products = (item.products) ? item.products.map(product => this.productAdapter.adapt(product)) : [];
        let menus = (item.menus) ? item.menus.map(menu => this.menuAdapter.adapt(menu)) : [];

        return new Family(
            item.id,
            item.position,
            item.icon,
            item.name,
            item.scheduling,
            item.isScheduled,
            item.is18YearOld,
            item.availability,
            products,
            menus,
        );
    }
}
