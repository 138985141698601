import { AfterViewInit, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';

declare var google: any;

@Component({
	selector: 'app-log-with-google',
	templateUrl: './log-with-google.component.html',
	styleUrls: ['./log-with-google.component.scss']
})
export class LogWithGoogleComponent implements AfterViewInit {
  @Input() deprecated: boolean = true;

  @Input() clientId: string
  @Output() onSignin: EventEmitter<string>;

  constructor(private ngZone: NgZone) {
    this.onSignin = new EventEmitter<string>();
  }

  ngAfterViewInit() {
    if(!this.deprecated) {
      const gAccounts = google.accounts;

      gAccounts.id.initialize({
        client_id: this.clientId,
        ux_mode: 'popup',
        cancel_on_tap_outside: true,
        callback: ({ credential }) => {
          this.ngZone.run(() => {
            this.onSignin.emit(credential);
          });
        },
      });

      gAccounts.id.renderButton(document.getElementById('gbtn') as HTMLElement, {
         size: 'large',
         width: 320,
      });
    }
  }

  signIn(e) {
    e.preventDefault()
    this.onSignin.emit('google')
  }
}


