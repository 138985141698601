import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DeliveryZoneAdapter } from '../../models/adapter/delivery-zone.adapter';
import { DeliveryZone } from '../../models/delivery-zone.model';
import { ApiUrl } from './api.url';

@Injectable({
    providedIn: 'root'
})
export class DeliveryZoneService {

    constructor(
        protected http: HttpClient,
        protected deliveryZoneAdapter: DeliveryZoneAdapter
    ) {}

    public getDeliveryZones(hash: string): Observable<DeliveryZone[]> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_ZONE_INCLUDES}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return response.sort(this.sort).map(item => this.deliveryZoneAdapter.adapt(item));
            }),
        );
    }

    public getExceptions(hash: string): Observable<DeliveryZone[]> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_ZONE_EXCEPTIONS}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return response.sort(this.sort).map(item => this.deliveryZoneAdapter.adapt(item));
            }),
        );
    }

    public sort(a, b) {
		return a.position < b.position ? -1 : (a.position > b.position ? 1 : 0)
	}

}
