import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClickCollect } from 'src/app/core/models/click-collect.model';
import { Client } from 'src/app/core/models/client.model';
import { ProcessOrder } from 'src/app/core/models/process-order.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { AppStateService } from 'src/app/core/services/app-state.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ModalChangeTableComponent } from '../../modal/modal-change-table/modal-change-table.component';

@Component({
	selector: 'app-order-table',
	templateUrl: './order-table.component.html',
	styleUrls: ['./order-table.component.scss']
})
export class OrderTableComponent implements OnInit {
	logged: boolean = false;
	tempForm : FormGroup;

	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() cc: ClickCollect;
	@Input() processOrder: ProcessOrder;
	@Input() formChoice: FormGroup;
	@Input() table: string;
	@Input() tableError: boolean = false;
	@Input() stripeError: boolean = false;

	@Output() onClose: EventEmitter<void>;
	@Output() onSelectOption: EventEmitter<number>;

	constructor(
		protected fb: FormBuilder,
		protected ref: ElementRef,
		protected route: ActivatedRoute,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected authService: AuthService,
		protected modalService: ModalService,

		protected appStateService: AppStateService
		
	) {
		this.onClose = new EventEmitter<void>()
		this.onSelectOption = new EventEmitter<number>()
	}

	
	ngOnInit(): void {
		this.tempForm = this.fb.group({
			tablenbr: [null]
		})
		this.logged = this.authService.isLoggedIn();
	}

	hasDefinedTable(): boolean {
		return this.table && this.table.trim() !== 'any';
	}

	openTableChangeModal(e) {
		e.preventDefault();
		this.tempForm.patchValue({tablenbr: this.formChoice.value.tablenbr})
		this.modalService.open(ModalChangeTableComponent, {
				tempTableForm: this.tempForm,
				tableForm: this.formChoice,
				hash: this.qrcode.hash
			}, 
			null,
			null
		)
	}
}
