import { Injectable } from "@angular/core";
import { TableRule } from "../table-rule.model";
import { Adapter } from "./adapter";

@Injectable({
    providedIn: "root",
})
export class TableRuleAdapter implements Adapter<TableRule> {
    adapt(item: any): TableRule {

        return new TableRule(
            item.id,
            item.name, 
            item.description, 
            item.values, 
            item.beginValue, 
            item.endValue, 
            item.valueType, 
            item.position,
            item.softException
        );
    }
}