import { Injectable } from "@angular/core";
import { Adapter } from "src/app/core/models/adapter/adapter";
import { ProcessOrder } from "../process-order.model";

@Injectable({
    providedIn: "root",
})
export class ProcessOrderAdapter implements Adapter<ProcessOrder> {
    adapt(item: any): ProcessOrder {
        return new ProcessOrder(
            item.type,
            item.method,
            item.address,
            item.comment,
            item.tablenbr,
            item.deliveryZone,
            item.collectionPoint,
            item.orderAt,
            item.hash
        );
    }
}