import { Product } from './product.model';
import { Menu } from './menu.model';
import { ShopItem } from './shop-item.model';

export enum ItemOrderProductType {
    product = 'product',
    menu = 'menu'
}
export class ItemOrderProduct {
    id: number;
    qt: number;
    price: number;
    shopItem: ShopItem;
    type: ItemOrderProductType;
    product: Product;
    menu: Menu;

    constructor(id: number, qt: number, price: number, shopItem: ShopItem, type: ItemOrderProductType, product: Product, menu: Menu) {
        this.id = id;
        this.qt = qt;
        this.price = price;
        this.shopItem = shopItem;
        this.type = type;
        this.product = product;
        this.menu = menu;
    }
}
