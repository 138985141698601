import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Client } from 'src/app/core/models/client.model';
import { Family } from 'src/app/core/models/family.model';
import { Product } from 'src/app/core/models/product.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { FamillyService } from 'src/app/core/services/api/familly.service';
import { ProductService } from 'src/app/core/services/api/product.service';
import { AllergenService } from 'src/app/core/services/api/allergen.service';
import { ShopCartService } from 'src/app/core/services/shop-cart.service';
import { ProcessOrder } from 'src/app/core/models/process-order.model';
import { ProcessService } from 'src/app/core/services/process.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { VersionService } from 'src/app/core/services/api/version.service';
import { RouterService } from 'src/app/core/services/router.service';
import { NavigationService } from 'src/app/core/services/navigation.service';

@Component({
	selector: 'app-click-go-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

	public loading = false;
	public client: Client;
	public table: string = 'any';
	public families: Family[];
	public suggestions: Product[];
	public selectedProduct: Product;
	public qrcode: Qrcode;
	public hash: string;
	public horaireOpen = false;
	public allergenById = {};
	public illustrations = {
		"0": [],
		"1": ["/assets/theme/1/1.jpg", "/assets/theme/1/2.jpg", "/assets/theme/1/3.jpg", "/assets/theme/1/4.png"],
		"2": ["/assets/theme/2/1.jpg", "/assets/theme/2/2.jpg", "/assets/theme/2/3.jpg"],
		"3": ["/assets/theme/3/1.jpg", "/assets/theme/3/2.jpg", "/assets/theme/3/3.jpg"],
		"4": ["/assets/theme/4/1.jpg", "/assets/theme/4/2.png", "/assets/theme/4/3.jpg"],
		"5": ["/assets/theme/5/1.jpg", "/assets/theme/5/2.jpg", "/assets/theme/5/3.jpg"],
		"6": ["/assets/theme/6/1.jpg", "/assets/theme/6/2.jpg", "/assets/theme/6/3.jpg"],
		"7": ["/assets/theme/7/1.jpg", "/assets/theme/7/2.jpg", "/assets/theme/7/3.jpg"],
		"8": ["/assets/theme/8/1.jpg", "/assets/theme/8/2.jpg", "/assets/theme/8/3.jpg"],
		"9": ["/assets/theme/9/1.jpg", "/assets/theme/9/2.jpg", "/assets/theme/9/3.jpg"],
		"10": ["/assets/theme/10/1.jpg", "/assets/theme/10/2.jpg", "/assets/theme/10/3.jpg"],
		"11": ["/assets/theme/11/1.jpg", "/assets/theme/11/2.jpg", "/assets/theme/11/3.jpg"],
		"12": ["/assets/theme/12/1.jpg", "/assets/theme/12/2.jpg", "/assets/theme/12/3.jpg"],
		"13": ["/assets/theme/13/1.jpg", "/assets/theme/13/2.jpg", "/assets/theme/13/3.jpg"],
		"14": ["/assets/theme/14/1.jpg", "/assets/theme/14/2.jpg", "/assets/theme/14/3.jpg"],
		"15": ["/assets/theme/15/1.jpg", "/assets/theme/15/2.jpg", "/assets/theme/15/3.jpg"]
	}
	public pageType: string;
	public processOrder: ProcessOrder;
	public subscriptions: Subscription[] = [];
	public version: string = "v2";

	constructor(
		protected route: ActivatedRoute,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected famillyService: FamillyService,
		protected productService: ProductService,
		protected allergenService: AllergenService,
        protected routerService: RouterService,
		protected versionService: VersionService,
		protected shopCartService: ShopCartService,
		protected processService: ProcessService,
		protected navigationService: NavigationService

	) {
		this.hash = this.route.parent.snapshot.paramMap.get('hash');
		this.table = this.route.parent.snapshot.paramMap.get('table');
	}

	ngOnInit(): void {
		this.loading = true;
		this.subscriptions.push(this.processService.getProcessOrder().subscribe(processOrder => this.processOrder = processOrder));
		this.navigationService.setData({});

		this.route.data.subscribe(({pageType}) => {
			this.pageType = pageType
		});

		this.clientService.getClient(this.hash).pipe(
			catchError(_ => {
				this.loading = false;
				return of(null);
			}),
			mergeMap(client => {
				this.client = client;
				return this.qrcodeService.getQrcode(this.hash);
			}),
			mergeMap(qrcode => {
				this.qrcode = qrcode;
				//this.qrcodeService.setColors(qrcode);
				
				return this.qrcodeService.getIllustrations(this.hash)
			}),
			mergeMap(illustrations => {
				if (illustrations) {
					this.illustrations[0] = illustrations.map(illu => illu.url);
				}
				const orderAt = this.processOrder 
					&& this.processOrder.orderAt 
					&& this.processOrder.orderAt ? this.processOrder.orderAt : null;

				return this.pageType === 'click-go' 
				? this.famillyService.getClickCollectFamilies(this.hash, true, orderAt) 
				: this.famillyService.getFamilies(this.hash, true);
			}),
			mergeMap(families => {
				if (families) { 
					this.families = families.sort((a, b) => (a.position > b.position) ? 1 : -1);
				}
				
				const orderAt = this.processOrder 
					&& this.processOrder.orderAt 
					&& this.processOrder.orderAt ? this.processOrder.orderAt : null;

				return this.pageType === 'click-go' 
				? this.productService.getSuggestions(this.hash, true, orderAt) 
				: this.productService.getSuggestions(this.hash, false);
			}),
			mergeMap(suggestions => {
				this.suggestions = suggestions;
				return this.allergenService.getAllergens(this.hash);
			})

		).subscribe(allergens => {
			this.loading = false;

			allergens.forEach(allergen => {
				this.allergenById[allergen.id] = allergen;
			});
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.map(sub => {
			if(sub) sub.unsubscribe();
		})
	}

	public suggestionAction(index: number): void {
		let product = this.suggestions[index];

		if (product.haveDetails() && this.qrcode) {
			this.selectedProduct = product;

			if(this.pageType === "in")
				this.routerService.navigate(['/', this.version, this.pageType, this.hash, this.table, 'family', product.famillyId, 'product', product.id])
			else
				this.routerService.navigate(['/', this.version, this.pageType, this.hash, 'family', product.famillyId, 'product', product.id])

		}
	}

	get cartLength() {
		return this.shopCartService.length();
	}
}
