import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClientAdapter } from '../../models/adapter/client.adapter';
import { ClientIllustration } from '../../models/client-illustration.model';
import { Client } from '../../models/client.model';
import { ApiUrl } from './api.url';

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    constructor(
        protected http: HttpClient,
        protected clientAdapter: ClientAdapter,
    ) {}

    public getIllustrations(hash: string): Observable<ClientIllustration[]> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_ILLUSTRATIONS}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return response.map(item => this.clientAdapter.adaptIllustration(item));
            }),
        );
    }

    public getClient(hash: string): Observable<Client> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_INFOS}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return this.clientAdapter.adapt(response);
            }),
        );
    }
}
