import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiUrl } from './api.url';
import { CustomTextClientAdapter } from '../../models/adapter/custom-text-client.adapter';
import { CustomTextClient } from '../../models/custom-text-client.model';

@Injectable({
    providedIn: 'root'
})
export class CustomTextService {

    constructor(
        protected http: HttpClient,
        protected customTextClientAdapter: CustomTextClientAdapter
    ) {}

    public getCustomTexts(hash: string): Observable<CustomTextClient[]> {
      return this.http.get(`${environment.baseUrl}${ApiUrl.GET_CUSTOM_TEXTS}`.replace(':hash', hash)).pipe(
        map((response: any) => {
            return response && response.length > 0 ? response.map(v => this.customTextClientAdapter.adapt(v)) : [];
        })
      );
    }
}
