import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ItemOrderProduct } from "src/app/core/models/item-order-product.model";
import { Menu } from "src/app/core/models/menu.model";
import { ProductGroupsOptions } from "src/app/core/models/product-groups-options.model";
import { Product } from "src/app/core/models/product.model";
import { ModalService } from "src/app/core/services/modal.service";
import { ShopCartService } from "src/app/core/services/shop-cart.service";
import { ModalRemoveMenuComponent } from "../../modal/modal-remove-menu/modal-remove-menu.component";

@Component({
    selector: 'app-order-menu',
    templateUrl: './order-menu.component.html',
    styleUrls: ['./order-menu.component.scss']
})
export class OrderMenuComponent implements OnInit {

    @Input() currency: string = '€';
    @Input() menu: Menu;
    @Input() onSaveOrder: boolean = false;
    @Input() productDescEnable: boolean = false;
    @Input() item: ItemOrderProduct;
    @Input() index: number;

    @Output('menuAction') onSelectMenu: EventEmitter<number>;
   
    public imageUrl: string;
    public descImageUrl: string;

    public menuProducts: Product[] = [];
    public menuOptions: ProductGroupsOptions[] = [];

    constructor(protected shopCartService: ShopCartService,
        protected modalService: ModalService) {
        this.onSelectMenu = new EventEmitter();
    }
    
	ngOnInit(): void {
		const {products, groupOptions} = this.menu.steps.reduce((acc, curr) => {
			const groupOptions = curr.products.reduce((acc, curr) => [...acc, ...curr.menuGroupOptions], [])
			return {products: [...acc.products, ...curr.products], groupOptions: [...acc.groupOptions, ...groupOptions]};
		}, {products: [], groupOptions: []})

		this.menuProducts = products;
		this.menuOptions = groupOptions;
    }

    getProduct(id) {
        return this.menuProducts.find(pr => pr.id === id)
    }

    getOption(id) {
        return this.menuOptions.find(o => o.id === id)
    }

    menuGroupsInProduct(id) {
        const ids = this.item.shopItem.menuGroupOptions.map(({id}) => id)
        const product = this.menuProducts.find(product => id === product.id);
        return product.menuGroupOptions.filter(go => ids.includes(go.id));
    }

    remove() {
        if(this.onSaveOrder) return;

        const removeMenu = () => this.shopCartService.removeMenu(
            this.menu, 
            this.item.shopItem.menuProducts, 
            this.item.shopItem.menuGroupOptions,
        )

        const menu = this.item.shopItem;

        if(this.shopCartService.getMenuQuantity(this.menu, this.item.shopItem.menuProducts, this.item.shopItem.menuGroupOptions) < 2) {
            this.modalService.open(ModalRemoveMenuComponent, {
                menu,
                currency: this.currency

              }, removeMenu, () => {});

        } else removeMenu()
    }

    add() {
        if(this.onSaveOrder) return;
        this.shopCartService.addMenu(
            this.menu, 
            this.item.shopItem.menuProducts, 
            this.item.shopItem.menuGroupOptions,
        )
    }

    removeAll() {
        if(this.onSaveOrder) return;

        const menu = this.item.shopItem;

        this.modalService.open(ModalRemoveMenuComponent, {
            menu,
            currency: this.currency

          }, () => this.shopCartService.setMenuQuantity(
            0,
            this.menu, 
            this.item.shopItem.menuProducts, 
            this.item.shopItem.menuGroupOptions,
        ), () => {});
    }

    formatPrice(price: any) {
        return typeof(price) === 'string' ? parseFloat(price.replace(',', '.')) : price;
    }

    menuAction(index: number) {
        if(this.onSaveOrder) return;
        this.onSelectMenu.emit(index)
    }

    getTotal(item) {
        return item.qt * parseFloat(item.price.replace(',',  '.'));
    }
}
