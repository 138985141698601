import { Injectable } from "@angular/core";
import { ShopItem } from "../shop-item.model";
import { Adapter } from "./adapter";

@Injectable({
    providedIn: "root",
})
export class ShopItemAdapter implements Adapter<ShopItem> {

    adapt(item: any): ShopItem {
        
        return new ShopItem(
            item.id,
            item.product,
            item.menu,
            item.price,
            item.groupOptions,
            item.menuProducts,
            item.menuGroupOptions,
            item.type,
            item.qt
        );
    }
}