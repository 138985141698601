import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationPage, NavigationService } from 'src/app/core/services/navigation.service';
import { OverlayService } from 'src/app/core/services/overlay.service';

@Component({
	selector: 'app-overlay',
	templateUrl: './overlay.component.html',
	styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit, OnDestroy {

	public show: boolean = false;
	protected subscriptions: Subscription[] = [];

	public page: NavigationPage;

	@ViewChild('modalContent', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;
	
	data: {[key: string]: any} = {};

	@Input() component: any;

	@Output() onClose: EventEmitter<void>;
	
	constructor(
		protected resolver: ComponentFactoryResolver,
		protected navigationService: NavigationService,
		protected overlayService: OverlayService
	) {
		this.onClose = new EventEmitter<void>(); 
	}

	ngOnInit(): void {
		this.subscriptions.push(this.overlayService.getData().subscribe(data => {
			this.show = !!data;
			this.data = data && data.data ?  data.data : this.data;
			if(this.show) this.open(data)
		}));

		this.subscriptions.push(this.navigationService.getPage().subscribe(page => this.page = page));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => {
		  if(sub) sub.unsubscribe();
		})
	}

	open({component, data}: any)  {
		
		setTimeout(() => {
			if(this.entry) {
				const factory = this.resolver.resolveComponentFactory(component);
				const componentRef = this.entry.createComponent(factory) as ComponentRef<any>;
			
				Object.keys(data).forEach(k => componentRef.instance[k] = data[k])
			}
			
		})
	}
	
	close(data = null) {
		this.overlayService.close(data);
		this.show = false;
	}

}
