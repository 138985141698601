import { Directive, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import intlTelInput from 'intl-tel-input';

@Directive({
  selector: '[appIntlInput]',
})
export class IntInputDirective {
    protected iti;
    protected el: ElementRef;
   
    @Input() appIntlInput: string;
    @Output() changePhone: EventEmitter<any>;

    constructor(el: ElementRef) {
        this.el = el;

        this.changePhone = new EventEmitter<any>();

        this.iti = intlTelInput(this.el.nativeElement, {
          separateDialCode: true,
          preferredCountries: ['fr', 'be', 'nl', 'gb', 'de', 'sp', 'it', 'pt'],
          utilsScript: "assets/intl-tel-input/js/utils.js",
          customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
            return "e.g. " + selectedCountryPlaceholder;
          },
        });

        this.el.nativeElement.addEventListener("countrychange", this.onChangePhone.bind(this));
    }

    onChangePhone() {
      this.changePhone.emit({...this.iti.getSelectedCountryData(), valid: this.iti.isValidNumber(this.iti.getNumber()), number: this.iti.getNumber()});
    }

    ngOnChanges(changes: SimpleChanges) {
      if(changes.appIntlInput && !(typeof(this.appIntlInput) === 'undefined' || this.appIntlInput === null)) {
        this.iti.setNumber(this.appIntlInput)
        this.changePhone.emit({...this.iti.getSelectedCountryData(), valid: this.iti.isValidNumber(this.iti.getNumber()), number: this.iti.getNumber()});
      }
    }

    ngOnDestroy() {
      this.el.nativeElement.removeEventListener("countrychange", this.onChangePhone.bind(this));
      this.iti.destroy()
    }
}

