/**
 * Class permettant de récupérer le nom des scripts
 *  des appels à l'API métier
 */
export enum ApiUrl {
    GET_ALLERGEN = '/front/qrcode-menu/:hash/allergens',
    GET_INFOS = '/front/qrcode-menu/:hash/infos',
    GET_QRCODE = '/front/qrcode-menu/:hash/qrcode',
    GET_QRCODEMIN = '/front/qrcode-menu/:hash/qrcodemin',
    GET_RELATEDS_QRCODE = '/front/qrcode-menu/:hash/related-qrcodes',
    GET_ILLUSTRATIONS = '/front/qrcode-menu/:hash/illustrations',
    GET_QRCODE_MENU_ILLUSTRATIONS = '/front/qrcode-menu/:hash/qrcode-illustrations',
    GET_FAMILIES = '/front/qrcode-menu/:hash/families',
    GET_FAMILIES_BY_DATE = '/front/qrcode-menu/:hash/families-by-date/:date',
    GET_CLICK_COLLECT_FAMILIES = '/front/qrcode-menu/:hash/click-collect-families',
    GET_CLICK_COLLECT_FAMILIES_BY_DATE = '/front/qrcode-menu/:hash/click-collect-families-by-date/:date',
    GET_FAMILY = '/front/qrcode-menu/:hash/family/:id',
    GET_FAMILY_BY_DATE = '/front/qrcode-menu/:hash/family-by-date/:id/:date',
    GET_CLICK_COLLECT_FAMILY = '/front/qrcode-menu/:hash/click-collect/:id',
    GET_CLICK_COLLECT_FAMILY_BY_DATE = '/front/qrcode-menu/:hash/click-collect-by-date/:id/:date',
    GET_CLICK_COLLECT_INFORMATION = '/front/qrcode-menu/:hash/click-collect-information',
    SAVE_CLICK_COLLECT_ORDER = '/front/click-collect-orders/:hash/saveorder',
    SAVE_CLICK_COLLECT_ORDER_NO_CUSTOMER = '/front/click-collect-orders/:hash/save-order-without-customer',
    GET_SUGGESTIONS = '/front/qrcode-menu/:hash/suggestions/:cc',
    GET_SUGGESTIONS_BY_DATE = '/front/qrcode-menu/:hash/suggestions-by-date/:cc/:date',
    LOGIN = '/front/customer/login',
    LOGIN_WITH_TOKEN = '/front/customer/login/:customerId/:token',
    REGISTER = '/front/customer/add',
    VALID_PHONE = '/front/customer/:id/valid-phone',
    EDIT_PASSWORD = '/front/customer/:id/edit-password',
    EDITREGISTER = '/front/customer/:id',
    FORGOT_PWD = '/front/customer/forgot-pwd',
    RESET_PWD = '/front/customer/reset-pwd',
    NEW_PWD = '/front/customer/new-pwd',
    NEW_CODE = '/front/customer/new-code',
    VERIFY_TOKEN = '/front/token/verify-token',
    DECODE_TOKEN = '/front/token/decode-token',
    CUSTOMER_ORDERS = '/front/click-collect-orders/find-by-customer/:id',
    STRIPE_TOKEN_CONFIRMATION = '/front/payment/clickcollect/token',
    PAY_ORDER = '/front/click-collect-orders/:hash/payorder',
    GET_CUSTOMER = '/front/customer/:id',
    ADD_ADDRESS = '/front/customer-address/add',
    EDIT_ADDRESS = '/front/customer-address/:id',
    DELETE_ADDRESS = '/front/customer-address/:id',
    HAS18_PRODUCTS = '/front/qrcode-menu/:hash/has18-products',
    HAS18_FAMILIES = '/front/qrcode-menu/:hash/has18-families',
    GET_TABLE_INCLUDES = '/front/table-include/:hash',
    GET_TABLE_EXCEPTIONS = '/front/table-exception/:hash',
    GET_ZONE_INCLUDES = '/front/delivery-zone-include/:hash',
    GET_ZONE_EXCEPTIONS = '/front/delivery-zone-exception/:hash',
    GET_VERSION = '/front/qrcode-menu/:hash/version',
    GET_CUSTOM_TEXTS = '/front/qrcode-menu/:hash/custom-texts',
    GET_DELIVERY_TIMEVALUES_AT = '/front/click-collect-orders/:hash/delivery/time-values-at?at=:date&time=:time',
    GET_DELIVERY_TIMEVALUES_FROM = '/front/click-collect-orders/:hash/delivery/time-values?from=:date&time=:time',
    GET_COLLECTION_POINTS_AT = '/front/click-collect-orders/:hash/get-collection-points-at?at=:date&time=:time',
    GET_COLLECTION_POINTS = '/front/click-collect-orders/:hash/collection-points?from=:date&onlyValid=true&time=:time',
    GET_CP_TIMEVALUES_AT = '/front/click-collect-orders/:hash/collection-points/time-values-at?at=:date&time=:time',
    GET_CP_TIMEVALUES_FROM = '/front/click-collect-orders/:hash/collection-points/time-values?from=:date&time=:time',
}
