import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from "@angular/router";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { DateTimePickerModule, DatePickerModule, TimePickerModule } from "@syncfusion/ej2-angular-calendars";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { CartComponent } from "../feature/v2/cart/cart.component";
import { ConfirmationClickGoComponent } from "./components/confirmation/confirmation-click-go/confirmation-click-go.component";
import { ConfirmationDeliveryComponent } from "./components/confirmation/confirmation-delivery/confirmation-delivery.component";
import { FamiliesComponent } from "./components/families/families.component";
import { FooterAccountComponent } from "./components/footer/account/account.component";
import { FooterInfoComponent } from "./components/footer/info/info.component";
import { MenuComponent } from "./components/menu/menu.component";
import { ModalComponent } from "./components/modal/modal.component";
import { HistoryProductComponent } from "./components/order/order-history/history-product/history-product.component";
import { OrderHistoryComponent } from "./components/order/order-history/order-history.component";
import { OrderProductComponent } from "./components/order/order-product/order-product.component";
import { OrderComponent } from "./components/order/order.component";
import { OverlayComponent } from "./components/overlay/overlay.component";
import { ModalCustomComponent } from "./components/modal/modal-custom/modal-custom.component";
import { OrderClickGoComponent } from "./components/order/order-click-go/order-click-go.component";
import { OrderTableComponent } from "./components/order/order-table/order-table.component";
import { LoginComponent } from "./components/profile/login/login.component";
import { RegisterAddressListComponent } from "./components/profile/register/address-list/address-list.component";
import { AddressComponent } from "./components/profile/register/address-list/address/address.component";
import { RegisterComponent } from "./components/profile/register/register.component";
import { VerificationComponent } from "./components/profile/verification/verification.component";
import { SuggestionComponent } from "./components/suggestion/suggestion.component";
import { FamilyComponent } from "../feature/v2/family/family.component";
import { HomeComponent } from "../feature/v2/home/home.component";
import { ConfirmationTableComponent } from "./components/confirmation/confirmation-table/confirmation-table.component";
import { ProductComponent } from "../feature/v2/product/product.component";
import { MenuProductComponent } from "../feature/v2/menu-product/menu-product.component";
import { ProductDetailComponent } from "../feature/v2/products/detail/detail.component";
import { ProductsComponent } from "../feature/v2/products/products.component";
import { QrCodesComponent } from "../feature/v2/qrcodes/qrcodes.component";
import { CounterComponent } from "./components/counter/counter.component";
import { DatetimePickerComponent } from "./components/datetime-picker/datetime-picker.component";
import { DatetimeComponent } from "./components/datetime/datetime.component";
import { MarkerMapComponent } from "./components/marker-map/marker-map.component";
import { GoogleMapsPlacesAutocompleteDirective } from "./directives/google-maps-places-autocomplete.directive";
import { UnescapePipe } from "./pipes/unescape.pipe";
import { SwiperModule } from 'swiper/angular';
import SwiperCore, {
	Autoplay,
	Pagination
} from 'swiper/core';

import { ModalResetProcessComponent } from "./components/modal/modal-reset-process/modal-reset-process.component";
import { ModalWarningComponent } from "./components/modal/modal-warning/modal-warning.component";
import { ClickGoOptionsComponent } from "./components/options/click-go-options/click-go-options.component";
import { DeliveryOptionsComponent } from "./components/options/delivery-options/delivery-options.component";
import { OrderDeliveryComponent } from "./components/order/order-delivery/order-delivery.component";
import { ProductComponent as ProductFeature } from "../feature/v2/products/product/product.component";
import { ModalChangeTableComponent } from "./components/modal/modal-change-table/modal-change-table.component";
import { AccountOrderComponent } from "./components/account/account-order.component";
import { FooterComponent } from "./components/footer/footer.component";
import { EditPasswordComponent } from "./components/profile/register/edit-password/edit-password.component";
import { HeaderComponent } from "./components/header/header.component";
import { CTAComponent } from "./components/cta/cta.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { FooterOpeningHoursComponent } from "./components/footer/opening-hours/opening-hours.component";
import { ModalRemoveProductComponent } from "./components/modal/modal-remove-product/modal-remove-product.component";
import { StripHTMLPipe } from "./pipes/strip-html.pipe";
import { IntInputDirective } from "./directives/intl-input.directive";
import { OrderMenuComponent } from "./components/order/order-menu/order-menu.component";
import { ModalRemoveMenuComponent } from "./components/modal/modal-remove-menu/modal-remove-menu.component";
import { AddToCartComponent } from "./components/add-to-cart/add-to-cart.component";
import { InputErrorComponent } from "./components/input-error/input-error.component";
import { NutriscoreAndAllergensComponent } from "./components/nutriscore-and-allergens/nutriscore-and-allergens.component";
import { SpecsComponent } from "./components/specs/specs.component";
import { LogWithGoogleComponent } from "./components/log-with-google/log-with-google.component";
import { LogWithFacebookComponent } from "./components/log-with-facebook/log-with-facebook.component";

SwiperCore.use([Pagination, Autoplay]);

@NgModule({
	declarations: [
		UnescapePipe,
		IntInputDirective,
		StripHTMLPipe,
		DatetimePickerComponent,
		MarkerMapComponent,
		MenuComponent,
		ProductComponent,
		MenuProductComponent,
		SuggestionComponent,
		FooterAccountComponent,
		FooterInfoComponent,
		LoginComponent,
		RegisterComponent,
		VerificationComponent,
		FamiliesComponent,
		ProductsComponent,
		ProductDetailComponent,
		OverlayComponent,
		ModalComponent,
		HomeComponent,
		FamilyComponent,
		QrCodesComponent,
		DatetimeComponent,
		ProductFeature,
		CounterComponent,
		RegisterAddressListComponent,
		AddressComponent,
		CartComponent,
		OrderComponent,
		OrderHistoryComponent,
		HistoryProductComponent,
		OrderProductComponent,
		OrderMenuComponent,
		ClickGoOptionsComponent,
		DeliveryOptionsComponent,
		OrderDeliveryComponent,
		OrderClickGoComponent,
		OrderTableComponent,
		ModalWarningComponent,
		ModalResetProcessComponent,
		ModalRemoveProductComponent,
		ModalRemoveMenuComponent,
		ModalCustomComponent,
		ModalChangeTableComponent,
		ConfirmationClickGoComponent,
		ConfirmationDeliveryComponent,
		ConfirmationTableComponent,
		GoogleMapsPlacesAutocompleteDirective,
		AccountOrderComponent,
		FooterComponent,
		HeaderComponent,
		EditPasswordComponent,
		CTAComponent,
		NotFoundComponent,
		FooterOpeningHoursComponent,
		AddToCartComponent,
		InputErrorComponent,
		NutriscoreAndAllergensComponent,
		SpecsComponent,
		LogWithGoogleComponent,
		LogWithFacebookComponent
	],
	imports: [
		CommonModule, 
		RouterModule, 
		HttpClientModule,
		HttpClientJsonpModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		NgbModule,
		NgbTooltipModule,
		SwiperModule,
		DateTimePickerModule,
		GoogleMapsModule,
    	NgxIntlTelInputModule,
		DatePickerModule,
		TimePickerModule
	],
	providers: [
		DatePipe
	],
	exports : [
		CommonModule, 
		IntInputDirective,
		RouterModule, 
		HttpClientModule,
		HttpClientJsonpModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		NgbModule,
		NgbTooltipModule,
		SwiperModule,
		DateTimePickerModule,
		GoogleMapsModule,
    	NgxIntlTelInputModule,
		DatePickerModule,
		TimePickerModule,
		StripHTMLPipe,
		UnescapePipe,
		DatetimePickerComponent,
		MarkerMapComponent,
		MenuComponent,
		ProductComponent,
		MenuProductComponent,
		SuggestionComponent,
		FooterAccountComponent,
		FooterInfoComponent,
		LoginComponent,
		RegisterComponent,
		VerificationComponent,
		FamiliesComponent,
		ProductsComponent,
		ProductDetailComponent,
		OverlayComponent,
		ModalComponent,
		HomeComponent,
		FamilyComponent,
		QrCodesComponent,
		DatetimeComponent,
		ProductFeature,
		CounterComponent,
		RegisterAddressListComponent,
		AddressComponent,
		CartComponent,
		OrderComponent,
		OrderHistoryComponent,
		HistoryProductComponent,
		OrderProductComponent,
		OrderMenuComponent,
		ClickGoOptionsComponent,
		DeliveryOptionsComponent,
		OrderDeliveryComponent,
		OrderClickGoComponent,
		OrderTableComponent,
		ModalWarningComponent,
		ModalResetProcessComponent,
		ModalRemoveProductComponent,
		ModalRemoveMenuComponent,
		ModalCustomComponent,
		ModalChangeTableComponent,
		ConfirmationClickGoComponent,
		ConfirmationDeliveryComponent,
		ConfirmationTableComponent,
		GoogleMapsPlacesAutocompleteDirective,
		AccountOrderComponent,
		FooterComponent,
		HeaderComponent,
		EditPasswordComponent,
		CTAComponent,
		NotFoundComponent,
		FooterOpeningHoursComponent,
		AddToCartComponent,
		InputErrorComponent,
		NutriscoreAndAllergensComponent,
		SpecsComponent,
		LogWithGoogleComponent,
		LogWithFacebookComponent
	]
})
export class SharedModule {}
