import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { Client } from 'src/app/core/models/client.model';
import { Family } from 'src/app/core/models/family.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { of, Subscription } from 'rxjs';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { FamillyService } from 'src/app/core/services/api/familly.service';
import { AllergenService } from 'src/app/core/services/api/allergen.service';
import { ClientService } from 'src/app/core/services/api/client.service';
import { ProcessService } from 'src/app/core/services/process.service';
import { ProcessOrder } from 'src/app/core/models/process-order.model';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { VersionService } from 'src/app/core/services/api/version.service';
import { RouterService } from 'src/app/core/services/router.service';
import { Menu } from 'src/app/core/models/menu.model';

@Component({
	selector: 'app-menu-product-page',
	templateUrl: './menu-product.component.html',
	styleUrls: ['./menu-product.component.scss']
})
export class MenuProductComponent implements OnInit, OnDestroy {

	public client: Client;
	public family: Family;
	public qrcode: Qrcode;
	public hash: string;
	public familyId: number;
	public menuId: number;
	public allergenById = {};
	public pageType: string;
	public loading = false;
	public menu: Menu;
	public processOrder: ProcessOrder;
	public compData = {
		type: 'menu',
		step: [],
		prices: [],
		options: {},
		prdPriceIndex: null,
		checkPrice: false,
		checkOptions: {},
		cartQt: 1
	};

	public subscriptions: Subscription[] = [];
	public table: string = 'any';
	public version: string = "v2";

	constructor(
		protected route: ActivatedRoute,
		protected routerService: RouterService,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected famillyService: FamillyService,
		protected allergenService: AllergenService,
		protected versionService: VersionService,
		protected navigationService: NavigationService,
		protected processService: ProcessService,

	) {
		this.hash = this.route.parent.snapshot.paramMap.get('hash');
		this.table = this.route.parent.snapshot.paramMap.get('table');
		this.familyId = parseInt(this.route.snapshot.paramMap.get('famillyId'), 10);
		this.menuId = parseInt(this.route.snapshot.paramMap.get('menuId'), 10);
	}

	ngOnInit() {
		this.loading = true;
		this.navigationService.setData({});

		this.subscriptions.push(this.processService.getProcessOrder().subscribe(processOrder => this.processOrder = processOrder));

		this.route.data.subscribe(({pageType}) => {
			this.pageType = pageType
		});

		this.clientService.getClient(this.hash).pipe(
			catchError(_ => {
				this.loading = false;
				return of(null);
			}),
			mergeMap(client => {
				this.client = client;
				return this.qrcodeService.getQrcode(this.hash)
			}),
			mergeMap(qrcode => {
				this.qrcode = qrcode;
				//this.qrcodeService.setColors(qrcode);
				
				const orderAt = this.processOrder 
					&& this.processOrder.orderAt 
					&& this.processOrder.orderAt ? this.processOrder.orderAt : null;

				return this.pageType === 'click-go' 
				? this.famillyService.getClickCollectFamily(this.hash, this.familyId, true, orderAt)
				: this.famillyService.getFamilly(this.hash, this.familyId, true);
			}),
			mergeMap(family => {
				if(!(family && family.availability)) this.routerService.navigate(['/', this.version, this.pageType, this.hash, 'list'])

				this.family = family;
				this.menu = this.family.menus.find(m => m.id === this.menuId);

				if(!this.menu) this.routerService.navigate(['/', this.version, this.pageType, this.hash, 'family', this.familyId])

				return this.allergenService.getAllergens(this.hash);
			}),
			finalize(() => { this.loading = false })

		).subscribe(allergens => {	
			this.navigationService.setData({family: this.family, menu: this.menu, details: this.compData});
	
			allergens.forEach(allergen => {
				this.allergenById[allergen.id] = allergen;
			});

			this.loading = false;
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.map(sub => {
			if(sub) sub.unsubscribe();
		})
	}

	back() {
		if(this.pageType === 'in')
			this.routerService.navigate(['/', this.version, this.pageType, this.hash, this.table, 'family', this.familyId])

		else
			this.routerService.navigate(['/', this.version, this.pageType, this.hash, 'family', this.familyId])
	}
}
