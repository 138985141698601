import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'input-error',
	templateUrl: './input-error.component.html',
	styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent implements OnInit {

	@Input() controlName: string;
	@Input() form: FormGroup;
	@Input() onlyTouched: boolean = false;

	constructor(protected translateService: TranslateService) {}

	ngOnInit(): void {}

	translate(err) {		
		let params: {} = typeof(err.value) === "object" && err.value !== null
			? Object.keys(err.value).reduce((acc, curr, i) => ({...acc, [`param_${i + 1}`]: err.value[curr]}), {}) 
			: {};

		return this.translateService.instant(`validators.${err.key}`, params)
	}
}
