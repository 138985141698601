export class OrderItemRequest {
    name: string;
    unitPrice: number;
    price: number;
    qt: number;
    currency: string;
    type: string;
    productId: number;
    menuId: number;
    priceId: number;
    menuProducts: {id: number, name: string}[];
    groupOptionIds: number[];
    menuGroupOptionIds: number[];

    constructor(
        name: string,
        unitPrice: number,
        price: number,
        qt: number,
        currency: string,
        type: string,
        productId: number,
        menuId: number,
        priceId: number,
        menuProducts: {id: number, name: string}[],
        groupOptionIds: number[],
        menuGroupOptionIds: number[],

    ) {
        this.name = name;
        this.unitPrice = unitPrice;
        this.price = price;
        this.qt = qt;
        this.currency = currency;
        this.type = type;
        this.productId = productId;
        this.menuId = menuId;
        this.priceId = priceId;
        this.menuProducts = menuProducts;
        this.groupOptionIds = groupOptionIds;
        this.menuGroupOptionIds = menuGroupOptionIds;

    }
}