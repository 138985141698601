import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of, Subscription, timer } from 'rxjs';
import { delay, map, mergeMap, tap } from 'rxjs/operators';
import { CustomerAddressAdapter } from 'src/app/core/models/adapter/customer-address.adapter';
import { ClickCollect } from 'src/app/core/models/click-collect.model';
import { Client } from 'src/app/core/models/client.model';
import { CustomerAddress } from 'src/app/core/models/customer-address.model';
import { ProcessMethod, ProcessOrder } from 'src/app/core/models/process-order.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { DateService } from 'src/app/core/services/date.service';
import { GoogleMapService } from 'src/app/core/services/google-map.service';
import { ViewType } from 'src/app/shared/components/datetime-picker/datetime-picker.component';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { DeliveryZoneService } from 'src/app/core/services/api/delivery-zone.service';
import { DeliveryZone } from 'src/app/core/models/delivery-zone.model';
import { ModalService } from 'src/app/core/services/modal.service';
import { ModalCustomComponent } from '../../modal/modal-custom/modal-custom.component';
import { ClickCollectService } from 'src/app/core/services/api/click-collect.service';
import { TranslateService } from '@ngx-translate/core';

export enum FromComponent {
	select = "select",
	order = "order"
}

@Component({
	selector: 'app-delivery-options',
	templateUrl: './delivery-options.component.html',
	styleUrls: ['./delivery-options.component.scss']
})
export class DeliveryOptionsComponent implements OnInit, OnChanges, OnDestroy {
	public deliveryForm: FormGroup;
	public newAddressForm: FormGroup;

	public selectedAddress: any;
	public timeValues: any;

	public selectedDate;
	public selectedTime;

	public apiloaded = false;
	public selectedTimeMode: string;
	public selectedScheduling: any[];

	public canSelectAddress: boolean = true;

	public invalidAddresses: any = {};
	public invalidSearchAddress: boolean = false;
	public invalidAddressAtTime: boolean = false;
	public invalidSyntaxSearchAddress: boolean = false;
	public hasNoNumber: boolean;

	public newAddressObj: CustomerAddress;
	public showCustomExceptionMessage: boolean = false;

	public autoCompleteOptions =  {
		//componentRestrictions: { country: ["fr", "be", "de", "nl", "it", "es", "pt", "ch", "at", "dk"] },
		fields: ["address_components", "formatted_address", "geometry", "icon", "name"],
		types: ["geocode"]
	};

	@Input() deliveryZone: DeliveryZone;
	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() hash: string;
	@Input() method: ProcessMethod;
	@Input() addresses: CustomerAddress[];
	@Input() cc: ClickCollect;
	@Input() date: string;
	@Input() time: string;
	@Input() processOrder: ProcessOrder;
	@Input() from: FromComponent = FromComponent.select;
	@Input() zones: DeliveryZone[] = [];
	@Input() exceptions: DeliveryZone[] = [];
	@Output() onSelectDate: EventEmitter<string>;
	@Output() onSelectTime: EventEmitter<string>;
	@Output() onSelectAddress: EventEmitter<any>;
	@Output() onSelectDeliveryZone: EventEmitter<DeliveryZone>;
	@Output() onSelectDateTime: EventEmitter<Date>;
	@Output() onEditAddress: EventEmitter<CustomerAddress>;

	protected subscribe: Subscription;

	constructor(
		protected fb: FormBuilder,
		protected googleMapService: GoogleMapService,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected dateService: DateService,
		protected customerAddressAdapter: CustomerAddressAdapter,
		protected deliveryZoneService: DeliveryZoneService,
		protected modalService: ModalService,
		protected clickCollectService: ClickCollectService,
		protected cdr: ChangeDetectorRef,
		protected translateService: TranslateService

	) {
		this.onSelectDate = new EventEmitter<string>()
		this.onSelectTime = new EventEmitter<string>()
		this.onSelectDateTime = new EventEmitter<Date>()
		this.onSelectAddress = new EventEmitter<any>()
		this.onEditAddress = new EventEmitter<CustomerAddress>()
		this.onSelectDeliveryZone = new EventEmitter<DeliveryZone>()
	}

	ngOnInit(): void {
		this.deliveryForm = this.fb.group({
			address: ['', Validators.required]
		});

		this.newAddressForm = this.fb.group({
			address: ['', Validators.required]
		});

		this.googleMapService.load().subscribe();

		this.genDeliveryTimeValue(this.deliveryZone);
		
		this.subscribe = this.googleMapService.isLoaded().subscribe(v => this.apiloaded = v)

		this.init();

		const scheduling = JSON.parse(this.cc.scheduling);

		if(scheduling.Week && scheduling.Navigator) {
			this.selectedTimeMode = Object.keys(scheduling).reduce((acc, curr) => scheduling[curr].active ? curr : acc, ViewType.NAVIGATOR);
			this.selectedScheduling = scheduling[this.selectedTimeMode].data;

		} else {
			this.selectedTimeMode = ViewType.NAVIGATOR;
			this.selectedScheduling = scheduling;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(!(changes.cc || changes.zones || changes.exceptions || changes.deliveryZone)) {
			this.init();
		}
	}

	ngOnDestroy(): void {
		this.subscribe.unsubscribe();
	}

	placeChanged(place) {
		if(!(place && place.geometry)) return;

		const {formatted_address} = place;
		this.newAddressForm.controls.address.patchValue(formatted_address)
		this.searchAddress();
	}

	init() {
		this.invalidAddresses = {};
		const addressIndex = this.processOrder && this.processOrder.address && this.addresses.findIndex(e => e.id === this.processOrder.address.id);

		if(this.from === FromComponent.order && addressIndex > -1) {
			this.selectedAddress = this.processOrder.address;

			if(this.deliveryForm && this.deliveryForm.controls) this.deliveryForm.controls.address.patchValue(this.processOrder.address);
			this.onSelectAddress.emit(this.processOrder.address)

		} else {
			if(this.processOrder && this.processOrder.address) {
				this.newAddressObj = this.processOrder.address;
				this.selectedAddress = this.newAddressObj;

				if(this.deliveryForm && this.deliveryForm.controls) this.deliveryForm.controls.address.patchValue(this.newAddressObj);
			}
			else {
				this.selectedAddress = null;
				if(this.deliveryForm && this.deliveryForm.controls) this.deliveryForm.controls.address.patchValue(null);

				// Select first address (with timer cause it's update the parent)
				if(this.addresses && this.addresses.length) timer().subscribe(_ => this.selectAddress(this.addresses[0]));
			}
		}

		this.onSelectAddress.emit(this.selectedAddress);
	}

	searchAddress() {
		let currentException: DeliveryZone;
		let selectedDeliveryZones: DeliveryZone[];

		this.invalidSearchAddress = false;
		this.invalidAddressAtTime = false;
		this.invalidSyntaxSearchAddress = false;
		this.selectedAddress = null;
		this.onSelectAddress.emit(null)
		this.invalidAddresses = {};
		this.hasNoNumber = false;

		if(this.newAddressForm.invalid) return null;

		let gResults;

		this.googleMapService.getCoordsByAddress(this.newAddressForm.value.address).pipe(
			mergeMap(v => {
				if(v.results 
					&& v.results.length 
					&& v.results[0].geometry 
					&& v.results[0].geometry.location) {

					gResults = v.results[0]

					// Recupère la longitude et latitude pour verifier la zone et recuperer le bon deliveryZone
					const {lat, lng} = gResults.geometry.location;

					currentException = this.exceptions.find(exception => 
						exception.description
						&& exception.description.trim().length > 0 
						&& this.googleMapService.checkDeliveryPosition(exception.delivery, lat, lng)
					);

					selectedDeliveryZones = this.zones.filter(zone => this.googleMapService.checkDeliveryPosition(zone.delivery, lat, lng));
					
					// Si aucune exception avec description trouvé, chercher une sans description
					if(!currentException) 
						currentException = this.exceptions.find(exception => this.googleMapService.checkDeliveryPosition(exception.delivery, lat, lng));

					const valid = !currentException && selectedDeliveryZones.length > 0;
						
					this.invalidSearchAddress = !valid;

					// Si flag avec message et exception avec description - afficher modal
					if(this.showCustomExceptionMessage 
						&& currentException 
						&& selectedDeliveryZones.length > 0 
						&& currentException.description.trim().length > 0) 
						
						this.modalService.open(ModalCustomComponent, {
							title:  this.translateService.instant('globals.warning'),
							intro: currentException.description.trim()

						}, () => {}, null);

					//Vérifie si on peut livrer selon le temps de livraison
					if(valid) {
						const dt = selectedDeliveryZones[0] && selectedDeliveryZones[0].deliveryTime ? selectedDeliveryZones[0].deliveryTime : 0;
						const man = this.cc.manufacturingTime ? this.cc.manufacturingTime : 0;

						return this.clickCollectService.canDeliver(
							this.hash,
							man + dt,
							this.method === ProcessMethod.organize,
							new Date()

						).pipe(delay(0), tap(v => { if(!v) this.invalidAddressAtTime = true }))
					}

				} else this.invalidSearchAddress = true;

				return of(null)
			})

		).subscribe(
			v => {
				// Récuperer composants de resultat trouver grace à l'api google
				const {address_components, formatted_address, lat, lng} = gResults;

				if(!address_components.find(e => e.types.includes('street_number'))) {
					this.invalidSearchAddress = false;
					this.invalidAddressAtTime = false;
					this.hasNoNumber = true;
					return;
				}

				if(!v) return;
				
				// Pour tester si l'addresse est complete 
				const valid = ['route', 'locality', 'country', 'postal_code', 'street_number'].every(e => address_components.find(e2 => e2.types.includes(e)))

				if(!valid) this.invalidSearchAddress = true;
				else {
					this.newAddressObj = null;

					const body = {
						name:  formatted_address,
						address: address_components.find(e => e.types.includes('route'))?.long_name + ', ' + address_components.find(e => e.types.includes('street_number'))?.long_name,
						city: address_components.find(e => e.types.includes('locality'))?.long_name,
						country: address_components.find(e => e.types.includes('country'))?.long_name,
						zipcode: address_components.find(e => e.types.includes('postal_code'))?.long_name,
						latitude: lat,
						longitude: lng
					}

					this.newAddressObj = this.customerAddressAdapter.adapt(body);
					this.selectedAddress = this.newAddressObj;
					this.deliveryForm.controls.address.patchValue(this.selectedAddress)
					this.onSelectAddress.emit(this.selectedAddress)
					this.onSelectDeliveryZone.emit(selectedDeliveryZones[0])
					this.genDeliveryTimeValue(selectedDeliveryZones[0])
					this.cdr.detectChanges();
					
				}
			}
		)
	}

	checkDeliveryZonesOfAddress(deliveryZones: DeliveryZone[]): Observable<DeliveryZone> {

		const obs$ = deliveryZones && deliveryZones.length ? forkJoin(deliveryZones.map(deliveryZone => {
			const dt = deliveryZone && deliveryZone.deliveryTime ? deliveryZone.deliveryTime : 0;
			const man = this.cc.manufacturingTime ? this.cc.manufacturingTime : 0;

			return this.clickCollectService.canDeliver(
				this.hash, 
				man + dt,
				this.method === ProcessMethod.organize,
				new Date()
			)
		})) : of([]);

		return obs$.pipe(
			map(v => v && v.length && v.findIndex(j => j) > - 1 ? deliveryZones[v.findIndex(j => j)] : null)
		)
	}

	submitForm(e) {
		e.preventDefault();
		this.searchAddress();
	}

	editAddress(e: any, addres: CustomerAddress) {
		e.preventDefault();
		e.stopPropagation();
		this.onEditAddress.emit(addres);
	}

	selectAddress(value: CustomerAddress) {
		if(this.selectedAddress === value) return ;
		this.selectedAddress = null;
		
		let currentException: DeliveryZone;
		let selectedDeliveryZones: DeliveryZone[];

		this.deliveryForm.controls.address.patchValue(null)
		this.onSelectAddress.emit(null)
		this.onSelectDeliveryZone.emit(null);
		
		if(value && this.canSelectAddress) {
			this.canSelectAddress = false;
			this.googleMapService.getCoordsByAddress(`${value.address}, ${value.zipcode} ${value.city}, ${value.country}`).pipe(
				mergeMap(v => {
					if(v.results && v.results.length && v.results[0].geometry && v.results[0].geometry.location) {
						const {lat, lng} = v.results[0].geometry.location;

						currentException = this.exceptions.find(exception => 
							exception.description
							&& exception.description.trim().length > 0 
							&& this.googleMapService.checkDeliveryPosition(exception.delivery, lat, lng)
						);
						selectedDeliveryZones = this.zones.filter(zone => this.googleMapService.checkDeliveryPosition(zone.delivery, lat, lng));

						if(!currentException) currentException = this.exceptions.find(exception => this.googleMapService.checkDeliveryPosition(exception.delivery, lat, lng));

						const valid = !currentException && selectedDeliveryZones[0];

						if(!valid) this.invalidAddresses[value.id] = {value: true, type: 1}

						// Si flag avec message et exception avec description - afficher modal
						if(this.showCustomExceptionMessage 
							&& currentException 
							&& selectedDeliveryZones[0] 
							&& currentException.description.trim().length > 0) {
								this.invalidAddresses[value.id] = {value: true, type: 1};

								this.modalService.open(ModalCustomComponent, {
									title:  this.translateService.instant('globals.warning'),
									intro: currentException.description.trim()
	
								}, () => {}, null);
							}

						if(valid) 
							return this.checkDeliveryZonesOfAddress(selectedDeliveryZones)
								.pipe(delay(0), tap(v => {if(!v) this.invalidAddresses[value.id] = {value: true, type: 2}}));
						
					}

					return of(null);
				})

			).subscribe(v => {
				this.canSelectAddress = true;
				
				if(v) {
					this.selectedAddress = value;
					this.deliveryForm.controls.address.patchValue(this.selectedAddress)
					this.onSelectAddress.emit(this.selectedAddress)
					this.onSelectDeliveryZone.emit(selectedDeliveryZones[0])
					this.invalidAddresses[value.id] = {value: false};
					this.genDeliveryTimeValue(selectedDeliveryZones[0])

				} else if(!(this.invalidAddresses[value.id] 
					&& this.invalidAddresses[value.id].value 
					&& this.invalidAddresses[value.id].type === 2)) this.invalidAddresses[value.id] = {value: true, type: 1};
			})
		}
	}

	genDeliveryTimeValue(zone: DeliveryZone, date: Date = new Date()) {

		this.clickCollectService.getDeliveryTimeValuesFrom(
			this.hash, 
			(this.cc.manufacturingTime + (zone && zone.deliveryTime ? zone.deliveryTime : 0)), 
			date,

		).subscribe(results => {
			this.timeValues = results;
		})
	}

	selectDate(value) {
		if(!value) {
			this.date = null;	
		}
		else this.date = this.dateService.convertDateToString(value)
		this.time = null;
	}

	selectTime(value) {
		this.time = value;
		this.onSelectDateTime.emit(this.dateService.fromString(`${this.date} ${this.time}`))
	}

	nothing(e: any) {
		e.preventDefault();
	}
}
