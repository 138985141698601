import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Client } from 'src/app/core/models/client.model';
import { ProcessOrder } from 'src/app/core/models/process-order.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { NavigationPage } from 'src/app/core/services/navigation.service';
import { SwiperOptions } from 'swiper';

export interface HeaderOptions {
  type: string;
  context: string;
}

@Component({
	selector: 'app-header-v2',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
  animations: [
		trigger('showWithAlpha', [
			transition(':enter', [
        style({
			  	opacity: 0
			  }),
        animate('200ms', style({
			  	opacity: 1
			  }))
      ]),
			transition(':leave', [
        style({
          opacity: 1
        }),
        animate('200ms', style({
			  	opacity: 0
			  }))
      ])
		])
	]
})
export class HeaderComponent {

  @Input('options') options: HeaderOptions;
  @Input('client') client: Client;
  @Input('qrcode') qrcode: Qrcode;
  @Input('page') page: NavigationPage;
  @Input('illustrations') illustrations: any;
  @Input('data') data: {[key: string]: any} = {};
  @Input('swiperConfig') swiperConfig: SwiperOptions;
  @Input('processOrder') processOrder: ProcessOrder;

  @Output('back') onBackAction: EventEmitter<void>

  public stickyBg: boolean = false;

  constructor() {
    this.onBackAction = new EventEmitter<void>();
  }

  nothing(e){
		e.preventDefault();
	}

  backAction(e): void {
    e.preventDefault();
    this.onBackAction.emit()
  }
  
  hasProductImage() {
    if(this.data && this.data.product && (this.data.product.descriptionPictureUrl || this.data.product.pictureUrl)) return true;
    if(this.data && this.data.product && (this.data.product.pictureUrl || this.data.product.pictureUrl)) return true

    return false;
  }

  hasMenuImage() {
    if(this.data && this.data.menu && (this.data.menu.pictureUrl || this.data.menu.pictureUrl)) return true
    return false;
  }

  public hasHeadline(qrcode: Qrcode) {
	  return qrcode.headlineEnable && qrcode.headline && qrcode.headline.trim().length > 0
  }

  public hasClientName(client: Client, qrcode: Qrcode) {
	  return qrcode.clientNameEnable && client && client.name.trim().length > 0
  }

  @HostListener('window: scroll', ['$event'])
	scroll(e) {
    if (e.srcElement && e.srcElement.scrollingElement) 
		  this.stickyBg = e.srcElement.scrollingElement.scrollTop > 50;
	}
}


