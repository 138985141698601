import { Component, Input, OnInit } from '@angular/core';
import { ProductGroupsOptions } from 'src/app/core/models/product-groups-options.model';
import { Product } from 'src/app/core/models/product.model';
import { ItemType, ShopItem } from 'src/app/core/models/shop-item.model';

@Component({
	selector: 'app-modal-remove-menu',
	templateUrl: './modal-remove-menu.component.html',
	styleUrls: ['./modal-remove-menu.component.scss']
})
export class ModalRemoveMenuComponent implements OnInit {
	
	@Input() menu: ShopItem;
	@Input() currency: string;
	@Input() confirmText: string;
	@Input() cancelText: string;

	public menuProducts: Product[] = [];
    public menuOptions: ProductGroupsOptions[] = [];

	ngOnInit(): void {
		const {products, groupOptions} = this.menu.menu.steps.reduce((acc, curr) => {
			const groupOptions = curr.products.reduce((acc, curr) => [...acc, ...curr.menuGroupOptions], [])
			return {products: [...acc.products, ...curr.products], groupOptions: [...acc.groupOptions, ...groupOptions]};
		}, {products: [], groupOptions: []})

		this.menuProducts = products;
		this.menuOptions = groupOptions;
	}

	getProduct(id) {
        return this.menuProducts.find(pr => pr.id === id)
    }

    getOption(id) {
        return this.menuOptions.find(o => o.id === id)
    }

	menuGroupsInProduct(id) {
        const ids = this.menu.menuGroupOptions.map(({id}) => id)
        const product = this.menuProducts.find(product => id === product.id);
        return product.menuGroupOptions.filter(go => ids.includes(go.id));
    }

	getFullName(shopItem: ShopItem) {
		if(!shopItem.menu) return '';
		const {name} = shopItem.menu
		return name + shopItem.menuGroupOptions.reduce((acc, curr, i) => acc + ' | ' + curr.name, '')
	}

	getPrice(item: ShopItem) {
		if(!item.menu) return null;
		const price = (item.menu.price as any).replace(',',  '.')
  
		const addCost = item.menuGroupOptions.reduce((acc, curr) => acc + curr.price, 0) 
		+ item.menuProducts.reduce((acc, curr) => curr.price ? acc + parseFloat(curr.price.replace(',',  '.')) : acc, 0)
		
		return (price + addCost) * item.qt;
	}

	
}
