import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ClickCollect } from 'src/app/core/models/click-collect.model';
import { Client } from 'src/app/core/models/client.model';
import { CollectionPoint } from 'src/app/core/models/collection-point.model';
import { MarkerMap } from 'src/app/core/models/marker-map.model';
import { ProcessMethod, ProcessOrder } from 'src/app/core/models/process-order.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClickCollectService } from 'src/app/core/services/api/click-collect.service';
import { ClientService } from 'src/app/core/services/api/client.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { AppStateService } from 'src/app/core/services/app-state.service';
import { GoogleMapService } from 'src/app/core/services/google-map.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ProcessService } from 'src/app/core/services/process.service';
import { MarkerMapComponent } from 'src/app/shared/components/marker-map/marker-map.component';
import { ModalCustomComponent } from '../../modal/modal-custom/modal-custom.component';

@Component({
	selector: 'app-order-click-go',
	templateUrl: './order-click-go.component.html',
	styleUrls: ['./order-click-go.component.scss']
})
export class OrderClickGoComponent implements OnInit {

	public selectedCp: any;
	public displayedAlert: boolean = false;
	public editDate: boolean = false;
	public floating: boolean = false;

	public markerConfirmationMode: boolean = false;
	public centerMarkerMap: google.maps.LatLngLiteral;
	public apiLoaded: boolean;
	public markerMapComponent: MarkerMapComponent;
	public markerMap: MarkerMap;
	public cps: CollectionPoint[] = [];

	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() hash: string;
	@Input() cc: ClickCollect;
	@Input() processOrder: ProcessOrder;
	@Input() editMode: ProcessOrder;
	@Input() stripeError: boolean = false;

	@Output() onClose: EventEmitter<void>;
	@Output() onSelectDateTime: EventEmitter<Date>;
	@Output() onSelectCollectionPoint: EventEmitter<CollectionPoint>;
	@Output() onSelectEditMode: EventEmitter<boolean>;

	@ViewChild('map') set content(content: MarkerMapComponent) {
		if(content) {
			this.markerMapComponent = content;
		}
	}
	
	constructor(
		protected ref: ElementRef,
		protected route: ActivatedRoute,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected googleMapService: GoogleMapService,
		protected processService: ProcessService,
		protected clickCollectService: ClickCollectService,
		protected modalService: ModalService,
		protected appStateService: AppStateService,
		protected fb: FormBuilder,
		protected translateService: TranslateService
	) {
		this.onClose = new EventEmitter<void>()
		this.onSelectDateTime = new EventEmitter<Date>()
		this.onSelectCollectionPoint = new EventEmitter<CollectionPoint>()
		this.onSelectEditMode = new EventEmitter<boolean>();
		

	}

	ngOnInit(): void {
		this.googleMapService.load().pipe(
			catchError(_ => of(null)),
			mergeMap(data => {
			  	this.apiLoaded = data;
				return this.googleMapService.getCoordsByAddress(this.processOrder.collectionPoint.deliveryZone)
			})

		).subscribe(results => {
			if(!('error_message' in results)) {
				this.centerMarkerMap = results.results[0].geometry.location;
				this.markerMap = new MarkerMap(this.centerMarkerMap);
			}
		});
	}

	getFormatedDate(time: number = 0, gap: number = 30): string {
		const datePipe: DatePipe = new DatePipe('fr-Fr');

		const [hours, minutes] = ['HH', 'mm'].map(v =>  datePipe.transform(this.processOrder.orderAt, v))
		const date = new Date();
			  date.setHours(parseInt(hours));
			  date.setMinutes(parseInt(minutes) + time);
		
		const date2 = new Date(date);
		date2.setMinutes(date2.getMinutes() + gap);

		return `Le ${datePipe.transform(this.processOrder.orderAt, 'EEEE')} ${datePipe.transform(this.processOrder.orderAt, 'dd')} ${datePipe.transform(this.processOrder.orderAt, 'MMMM')} entre ${datePipe.transform(date, 'HH:mm')} et ${datePipe.transform(date2, 'HH:mm')}`;
	}

	selectDatetime(value: any) {}

	cancel(e: any) {
		e.preventDefault();
		this.onSelectEditMode.emit(false);
	}

	edit(e: any) {
		e.preventDefault();

		if(this.processOrder.method === ProcessMethod.now) {
			this.clickCollectService.getCollectionPointsAt(this.hash, this.cc.manufacturingTime, new Date()).subscribe(value => { 
				this.cps = value;

				if(value.length < 1) {
					this.modalService.open(ModalCustomComponent, {
						title:  this.translateService.instant('globals.warning'),
						intro: this.translateService.instant('v2.click_go.selection.no_cp_available_now_2')

					}, () => {}, null)

				} else {
					this.onSelectEditMode.emit(true);
					this.appStateService.setScrollTo(2000);
				}
			});
			
		} else {
			this.clickCollectService.getCollectionPoints(this.hash, this.cc.manufacturingTime, new Date()).subscribe(value => {
				this.cps = value;

				if(value.length < 1) {
					this.modalService.open(ModalCustomComponent, {
						title:  this.translateService.instant('globals.warning'),
						intro: this.translateService.instant('v2.click_go.selection.no_cp_available_2')

					}, () => {}, null)

				} else {
					this.onSelectEditMode.emit(true);
					this.appStateService.setScrollTo(2000);
				}
			});
		}

	}

	selectDateTime(value) {
		this.onSelectDateTime.emit(value)
		this.appStateService.setScrollTo(2000);

	}

	selectCollectionPoint(value: CollectionPoint) {
		this.onSelectCollectionPoint.emit(value)
	}

	close(e: any) {
		e.preventDefault();
		this.onClose.emit();
	}
}
