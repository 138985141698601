export class Allergen {

    id: number;
    name: string;
    iconName: string;

    constructor(id: number, name: string, iconName: string) {
        this.id = id;
        this.name = name;
        this.iconName = iconName;
    }
}