import { Component, Input, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { ProductAdapter } from "src/app/core/models/adapter/product.adapter";
import { Order } from "src/app/core/models/order.model";
import { OverlayService } from "src/app/core/services/overlay.service";

@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent {

    @Input() currency: string = '€';
    @Input() order: Order;
    @Output() closeAction = new EventEmitter();

    public isOpen = false;

    constructor(
      protected productAdapter: ProductAdapter,
      protected overlayService: OverlayService

    ) {}

    public getTotal(order: Order) {
      return (order.items.reduce((acc, curr) => acc + curr.price, 0) + parseFloat(order.deliveryCosts)).toFixed(2);
    }

    close():void {
      this.overlayService.close();
    }
    
}
