import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { MarkerMap } from 'src/app/core/models/marker-map.model';
import { MarkerDrawingMode } from 'src/app/core/services/google-map-mode/marker-drawing-mode';
import { GoogleMapService } from 'src/app/core/services/google-map.service';

@Component({
  selector: 'app-marker-map',
  templateUrl: './marker-map.component.html',
  styleUrls: ['./marker-map.component.scss']
})
export class MarkerMapComponent implements AfterViewInit, OnDestroy {

  @Input('center') center: google.maps.LatLngLiteral;
  @Input('loaded') apiLoaded: boolean;
  @Input('markerMap') markerMap: MarkerMap;
  @Input('disableMarker') disable: boolean = true;

  @Output('onSave') onSave: EventEmitter<MarkerMap> = new EventEmitter<MarkerMap>();
  @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(GoogleMap, { static: false }) set map(m: GoogleMap) {
    if (m) {
      this.gmap = m;
      this.mode = new MarkerDrawingMode();
      
      if(!this.disable) {
        this.drawingManager = this.googleMapService.getDrawingManager(this.mode, this.gmap);
        this.mode.addEvents(this.drawingManager, m);
      }

      if(this.markerMap) { 
        this.changeObject(this.markerMap as MarkerMap);
        this.center = this.markerMap.coords;
      }
    }
  }

  @ViewChild('deliveryAddress') public deliveryAddress;

  protected mode: MarkerDrawingMode;

  protected modeSubject;
  protected gmap: GoogleMap;;
  protected drawingManager: google.maps.drawing.DrawingManager;

  public options: google.maps.MapOptions = {
    scrollwheel: true,
    disableDoubleClickZoom: true,
    zoom: 14,
    zoomControl: true,
  }

  constructor(private googleMapService: GoogleMapService) {}

  ngAfterViewInit(): void {
    if(!this.disable) {
        
      this.modeSubject = this.mode.getObject().pipe(delay(0)).subscribe(data => {
        this.patchAdressInput(data);
        this.markerMap = data;
        
        this.onSave.emit(data);
      });
    }
  }

  ngOnDestroy(): void {
    if(this.modeSubject) this.modeSubject.unsubscribe();
  }

  public closeInternalAction(): void {
    this.closeAction.emit();
  }

  public addDrawingEvents() {
    this.mode.addEvents(this.drawingManager, this.gmap);
  }

  public changeObject(object: any): void {
    this.mode.changeObject(object, this.drawingManager,  this.gmap);
  }

  public getObject(): Observable<any> {
    return this.mode.getObject();
  }

  public getData(): any {
    return this.mode.getData();
  }

  public saveCollectionPoint() {
    this.onSave.emit(this.markerMap);
    this.closeAction.emit();
  }

  public patchAdressInput(data: MarkerMap) {
    if(data && data.coords) {
      this.googleMapService.getAddressByCoords(data.coords).toPromise().then(r => {
         if(r.results.length > 0) this.deliveryAddress.nativeElement.value = r.results[0].formatted_address
      });
    }
  }

  public onChangeAdressValue(event: any) {
    this.googleMapService.getCoordsByAddress(this.deliveryAddress.nativeElement.value).toPromise().then(r => {
      if(r.results.length > 0) {
        const coords = r.results[0].geometry.location;
        this.center = coords;
        this.changeObject({coords} as MarkerMap);
      };
    });
  }
}
