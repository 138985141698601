import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { Client } from 'src/app/core/models/client.model';
import { Family } from 'src/app/core/models/family.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { VersionService } from 'src/app/core/services/api/version.service';

@Component({
	selector: 'app-families',
	templateUrl: './families.component.html',
	styleUrls: ['./families.component.scss']
})
export class FamiliesComponent implements OnInit {

	public swiperConfigSuggestion: SwiperOptions = {
		loop: true,
		pagination: {
			bulletClass: 'swiperBullet',
			bulletActiveClass: 'swiperBulletActive'
		},
		autoplay: {
			delay: 5000
		},
	};

	public version: string = "v2";

	@Input() client: Client;
	@Input() table: string = 'any';
	@Input() qrcode: Qrcode;
	@Input() families: Family[] = [];
	@Input() suggestions: any[] = [];
	@Input() illustrations: any[] = [];
	@Input() pageType: string = 'qrcode';

	@Output() onSuggestionClick: EventEmitter<number>;

	constructor(protected versionService: VersionService) {
		this.onSuggestionClick = new EventEmitter<number>()
	}
	
	ngOnInit(): void {		
		if (this.suggestions && this.suggestions.length == 1) {
			this.swiperConfigSuggestion.loop = false;
			this.swiperConfigSuggestion.pagination = null;
		}
	}

	public suggestionAction(e, index: number) {
		e.preventDefault();
		
		this.onSuggestionClick.emit(index);
	}

	public hasHeadline(qrcode: Qrcode) {
		return qrcode.headlineEnable && qrcode.headline && qrcode.headline.trim().length > 0
	}

	public hasClientName(client: Client, qrcode: Qrcode) {
		return qrcode.clientNameEnable && client && client.name.trim().length > 0
	}
}
