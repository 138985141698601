import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { SocialAuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Client } from 'src/app/core/models/client.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { ProcessService } from 'src/app/core/services/process.service';
import { VersionService } from 'src/app/core/services/api/version.service';
import { AppStateService } from 'src/app/core/services/app-state.service';
import { RouterService } from 'src/app/core/services/router.service';
import { environment } from 'src/environments/environment';
import jwt_decode  from 'jwt-decode';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loading = false;
  public loginForm: FormGroup;
  public loginError = false;
  public externalError = false;
  public version: string = 'v2';

  @Input() hash: string;
  @Input() table: string;
  @Input() from: string;
  @Input() client: Client;
  @Input() qrcode: Qrcode;
  @Input() code: boolean;
  @Input() phone: boolean;

  constructor(
		protected route: ActivatedRoute,
    protected routerService: RouterService,
    protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
    protected versionService: VersionService,
    protected formBuilder: FormBuilder,
    protected authService: AuthService,
		protected socialAuthService: SocialAuthService,
    protected processService: ProcessService,
    protected appStateService: AppStateService
	) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
    });
	}
  
  public loginUser(): void {
    this.loginError = false;
    this.loading = true;

    const {email, password} = this.loginForm.value;

    this.authService.login(email, password, null)
      .subscribe(connected => {
        if (connected) {
          this.processService.removeProcessOrder();

          if(this.appStateService.redirectAfterLoginOnOrder) {
            this.appStateService.redirectAfterLoginOnOrder = false;
            if(this.from === "in")
              this.routerService.navigate(['/', this.version, this.from, this.hash, this.table, 'order']);
            else
              this.routerService.navigate(['/', this.version, this.from, this.hash, 'order']);
          } else {
            if(this.from === "in")
              this.routerService.navigate(['/', this.version, this.from, this.hash, this.table, 'list']);
            else
              this.routerService.navigate(['/', this.version, this.from, this.hash, 'list']);
          }
        }
        else  this.loginError = true;
      }
    );
  }

  public externalLogin(provider: string, from = 'click-go'): void {
    let providerObject;

    switch(provider) {
      // case 'google' :
      //   providerObject = GoogleLoginProvider.PROVIDER_ID
      //   break;

      case 'facebook' :
        providerObject = FacebookLoginProvider.PROVIDER_ID
        break;
    }

      this.socialAuthService.signIn(providerObject).then((user: SocialUser) => {
        if (user) {
          this.authService.login(user.email, user.id, user.id, provider).pipe(
            catchError(_ => of(false))
            
          ).subscribe(connected => {
            if (!connected) {
              this.registerUserInformation(
                user.firstName,
                user.lastName,
                user.email,
                '',
                user.id,
                user.id,
                'login',
                from,
                provider
              );
            } else {
              this.processService.removeProcessOrder();
              
              if(from === "in")
                this.routerService.navigate(['/', this.version, from, this.hash, this.table, 'list']);
              else
                this.routerService.navigate(['/', this.version, from, this.hash, 'list']);
            }
          });
        }
    });
  }

  public signInWithGoogle(credentials, from) {
    if(!credentials) return;
    try {
      const obj: any = jwt_decode(credentials);
      
      this.authService.login(obj.email, obj.sub, obj.sub, 'google').pipe(
        catchError(_ => of(false))
        
      ).subscribe(connected => {
        if (!connected) {
          this.registerUserInformation(
            obj.given_name,
            obj.family_name,
            obj.email,
            '',
            obj.sub,
            obj.sub,
            'login',
            from,
            'google'
          );
        } else {
          this.processService.removeProcessOrder();
          
          if(from === "in")
            this.routerService.navigate(['/', this.version, from, this.hash, this.table, 'list']);
          else
            this.routerService.navigate(['/', this.version, from, this.hash, 'list']);
        }

      })

    } catch(e) {
      throw e
    }
  }

  public registerUserInformation (firstname, lastname, email, phone, password, externalId, origin, from = 'click-go', connectedBy: string = null) {
    if(!(firstname && lastname && email && password)) {
      return;
    }

    this.authService.register(firstname, lastname, email, phone, password, externalId)
      .subscribe(registered => {
        this.loading = false;

        if (registered) {
          this.authService.login(email, password, externalId, connectedBy).subscribe(connected => {
            if (!connected) {
              this.loginError = true;
              
            } else {
              this.processService.removeProcessOrder();

              if(from === "in")
                this.routerService.navigate(['/', this.version, from, this.hash, this.table, 'list']);
              else
                this.routerService.navigate(['/', this.version, from, this.hash, 'list']);
            }
          
          });

        } else {
            if (origin === 'login') {
              this.externalError = true;
            } else {
              if(from === "in")
                this.routerService.navigate(['/', this.version, from, this.hash, this.table, 'list']);
              else
                this.routerService.navigate(['/', this.version, from, this.hash, 'list']);
            }
        }
    });
  }

  get clientId() {
    return environment.googleAppId;
  }
}
