import { Component, Input } from '@angular/core';
import { Product } from 'src/app/core/models/product.model';

@Component({
	selector: 'app-specs',
	templateUrl: './specs.component.html',
	styleUrls: ['./specs.component.scss']
})
export class SpecsComponent {
  @Input() product: Product;
  @Input() productList: boolean = false;
}
