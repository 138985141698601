import { Injectable } from "@angular/core";
import { DeliveryZone } from "../delivery-zone.model";
import { Adapter } from "./adapter";

@Injectable({
    providedIn: "root",
})
export class DeliveryZoneAdapter implements Adapter<DeliveryZone> {
    adapt(item: any): DeliveryZone {

        return new DeliveryZone(
            item.id,
            item.name, 
            item.description, 
            item.delivery, 
            item.position,
            item.deliveryTime, 
            item.minOrder,
            item.freeDelivery,
            item.fixedDeliveryCost
        );
    }
}