export class CustomTextClient {
    id: number;
    name: string;
    text: string;

    constructor(id: number, name: string, text: string) {
        this.id = id;
        this.name = name;
        this.text = text;
    }

    public getArrayText(): string[] {
        return this.text ? this.text.split(/(?:\r\n|\r|\n)/) : [];
    }
}