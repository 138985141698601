import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Client } from 'src/app/core/models/client.model';
import { CustomTextClient } from 'src/app/core/models/custom-text-client.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { CustomTextService } from 'src/app/core/services/api/custom-text.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';

@Component({
	selector: 'app-confirmation-table',
	templateUrl: './confirmation-table.component.html',
	styleUrls: ['./confirmation-table.component.scss']
})
export class ConfirmationTableComponent implements OnInit {
	
	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() hash: string;
	@Input() type: string = 'now';

	public loading: boolean;
	public customTexts: CustomTextClient[] = [];
	
	private defaults: {[key: string]: string};

	constructor(
		protected route: ActivatedRoute,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected customTextService: CustomTextService,
		protected translateService: TranslateService
	) {}
	
	ngOnInit(): void {
		this.loading = true;

		this.defaults = {
			"order.table.thanks": this.translateService.instant("v2.components.confirmation.confirmation_table.thanks_2"),
			"order.table.title": this.translateService.instant("v2.components.confirmation.confirmation_table.thanks_title")
		}

		this.customTextService.getCustomTexts(this.hash).pipe(
			catchError(_ => {
				this.loading = false;
				return of(null)
			})

		).subscribe(customTexts => {
			this.loading = false;
			this.customTexts = customTexts;
		})
	}

	getArrayText(text: string): string[] {
        return text ? text.split(/(?:\r\n|\r|\n)/) : [];
    }

	getCustomTextOrDefault(name: string): string[] {
		const found = this.customTexts.find(f => f.name === name)
		return  found ? this.getArrayText(found.text) : (this.defaults[name] ? this.getArrayText(this.defaults[name]) : [""]);
	}
}
