import { Component, Input } from '@angular/core';
import { ProductAdapter } from 'src/app/core/models/adapter/product.adapter';
import { ItemOrderProduct, ItemOrderProductType } from 'src/app/core/models/item-order-product.model';
import { Product } from 'src/app/core/models/product.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ItemType, ShopItem } from 'src/app/core/models/shop-item.model';

@Component({
	selector: 'app-modal-remove-product',
	templateUrl: './modal-remove-product.component.html',
	styleUrls: ['./modal-remove-product.component.scss']
})
export class ModalRemoveProductComponent {

	@Input() product: ShopItem;
	@Input() currency: string;
	@Input() confirmText: string;
	@Input() cancelText: string;

	constructor(
		protected productAdapter: ProductAdapter
	) {}

	getFullName(shopItem: ShopItem) {
		if(!shopItem.product) return null;

		const {name} = shopItem.product
		const hasMultiPrice = !!shopItem.price
		return name + ( hasMultiPrice ? ' | ' + shopItem.price.name : '' ) + shopItem.groupOptions.reduce((acc, curr, i) => acc + ' | ' + curr.name, '')
	}

	getPrice(item: ShopItem) {
		if(!item.product) return null;

		const price = item.type === ItemType.both || item.type === ItemType.multiprice 
		? parseFloat(item.price.price.replace(',',  '.')) : parseFloat(item.product.price.replace(',',  '.'));
  
		const addCost = item.type === ItemType.both || item.type === ItemType.groupOption 
		? item.groupOptions.reduce((acc, curr) => acc + curr.price, 0) : 0;
		
		return (price + addCost) * item.qt;
	}

	
}
