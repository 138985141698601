import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-datetime',
	templateUrl: './datetime.component.html',
	styleUrls: ['./datetime.component.scss']
})
export class DatetimeComponent implements OnInit {
	
	public datetimeForm: FormGroup;

	@Input() date: string;
	@Input() time: string;

	selectedDate: string;
	selectedTime: string;

	@Input() dates: string[];
	@Input() times: string[];

	@Output() onSelectTime: EventEmitter<string>;
	@Output() onSelectDate: EventEmitter<string>;
	@Output() onSelectDateTime: EventEmitter<Date>;

	constructor(
		protected fb: FormBuilder
	) {
		this.onSelectTime = new EventEmitter<string>()
		this.onSelectDate = new EventEmitter<string>()
		this.onSelectDateTime = new EventEmitter<Date>()
	}

	ngOnInit() {
		this.selectedDate = this.date;
		this.selectedTime = this.time;

		this.datetimeForm = this.fb.group({
			datetime: [null, Validators.required]
		});
	}

	selectDate(e: any) {
		const {value} = e.target;

		this.selectedDate = value;

		if(this.selectedTime) this.datetimeForm.controls.datetime.patchValue(`${this.selectedDate} ${this.selectedTime}`)
		else this.datetimeForm.controls.datetime.patchValue(null)

		this.onSelectDate.emit(value)

		if(this.datetimeForm.valid) {
			const {datetime} = this.datetimeForm.value;
			this.onSelectDateTime.emit(new Date(datetime))
		}
	}

	selectDateTime(e: any) {
		
	}

	selectTime(e: any) {
		const {value} = e.target;

		this.selectedTime = value;

		if(this.selectedDate) this.datetimeForm.controls.datetime.patchValue(`${this.selectedDate} ${this.selectedTime}`)
		else this.datetimeForm.controls.datetime.patchValue(null)

		this.onSelectTime.emit(value)

		if(this.datetimeForm.valid) {
			const {datetime} = this.datetimeForm.value;
			this.onSelectDateTime.emit(new Date(datetime))
		}
	}
}
