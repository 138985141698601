import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-modal-custom',
	templateUrl: './modal-custom.component.html',
	styleUrls: ['./modal-custom.component.scss']
})
export class ModalCustomComponent {

	@Input() intro: string = "";
	@Input() text: string = "";
	@Input() title: string = "";
	@Input() confirmText: string;
	@Input() cancelText: string;

}
