import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClientAdapter } from '../../models/adapter/client.adapter';
import { QrcodeAdapter } from '../../models/adapter/qrcode.adapter';
import { Qrcode } from '../../models/qrcode.model';
import { ApiUrl } from './api.url';
import { QrcodeIllustration } from '../../models/qrcode-illustration.model';

@Injectable({
    providedIn: 'root'
})
export class QrCodeService {

    constructor(
        protected http: HttpClient,
        protected clientAdapter: ClientAdapter,
        protected qrcodeAdapter: QrcodeAdapter
    ) {}

    public getIllustrations(hash: string): Observable<QrcodeIllustration[]> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_QRCODE_MENU_ILLUSTRATIONS}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return response.map(item => this.clientAdapter.adaptIllustration(item));
            }),
        );
    }

    public getQrcode(hash: string, min: boolean = false): Observable<Qrcode> {
        const uri = min ? `${environment.baseUrl}${ApiUrl.GET_QRCODEMIN}` : `${environment.baseUrl}${ApiUrl.GET_QRCODE}`
        return this.http.get(uri.replace(':hash', hash)).pipe(
            map((response: any) => {
                return this.qrcodeAdapter.adapt(response);
            }),
        );
    }

    public getQrcodes(hash: string): Observable<Qrcode[]> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_RELATEDS_QRCODE}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return response.map(item => this.qrcodeAdapter.adapt(item));
            }),
        );
    }

    public initColors() {

        const names =[
            '--primary-color',
            '--secondary-color',
            '--third-color',
            '--fourth-color',
            '--fifth-color',
            '--sixth-color',
            '--seventh-color',
            '--eighth-color'
        ];

        names.forEach((n, i) => {
            document.documentElement.style.setProperty(n, '#ffffff');
        })
        
    }

    public setColors(qrcode: Qrcode) {
        if(qrcode.qrcodeThemes) {
            const {versionId} = qrcode;   
            const qrcodeTheme = qrcode.qrcodeThemes.find(th => th.versionId === versionId);  
            if(!qrcodeTheme) return;
            const {colors} = qrcodeTheme;

            const names =[
                '--primary-color',
                '--secondary-color',
                '--third-color',
                '--fourth-color',
                '--fifth-color',
                '--sixth-color',
                '--seventh-color',
                '--eighth-color'
            ];

            colors.forEach(({color}, i) => {
                document.documentElement.style.setProperty(names[i], color);
            })
        }
    }
}
