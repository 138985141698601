import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-history-product',
    templateUrl: './history-product.component.html',
    styleUrls: ['./history-product.component.scss']
})
export class HistoryProductComponent {
    @Input() item: any;
    @Input() currency: string = '€';

}
