import { Directive, ElementRef, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appGoogleAutoComplete]'
})
export class GoogleMapsPlacesAutocompleteDirective {
	protected autocomplete: google.maps.places.Autocomplete;

  @Input() appGoogleAutoComplete: any = {};
  @Input() apiloaded: boolean = false;

  @Output() placeChanged: EventEmitter<any>

  constructor(private el: ElementRef) {
      this.placeChanged = new EventEmitter<any>()
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges){
    if(changes && changes.apiloaded && changes.apiloaded.currentValue && !this.autocomplete) {
      this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.appGoogleAutoComplete);
      this.autocomplete.addListener('place_changed', this.changePlace.bind(this));
    }
  }

  ngOnDestroy() {
    if(this.el) google.maps.event.clearInstanceListeners(this.el);
  }

  changePlace() {
    this.placeChanged.emit(this.autocomplete.getPlace());
  }
}