import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProcessOrder } from 'src/app/core/models/process-order.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { NavigationPage } from 'src/app/core/services/navigation.service';
import { OverlayState } from 'src/app/core/services/overlay.service';


@Component({
	selector: 'app-cta',
	templateUrl: './cta.component.html',
	styleUrls: ['./cta.component.scss']
})
export class CTAComponent {

  @Input('page') page: NavigationPage;
  @Input('pageType') pageType: string = 'click-go';

  @Input('minOrder') minOrder: boolean;
  @Input('minOrderValue') minOrderValue: number;
  @Input('totalCart') totalCart: number;
  @Input('totalCartWithoutDelivery') totalCartWtD: number;

  @Input('cartLength') cartLength: number;
  @Input('processOrder') processOrder: ProcessOrder;
  @Input('qrcode') qrcode: Qrcode;
  @Input('state') state: OverlayState;

  @Output('order') onOrder: EventEmitter<void>;
  @Output('validate') onValidate: EventEmitter<void>;

  constructor() {
    this.onOrder = new EventEmitter<void>()
    this.onValidate = new EventEmitter<void>()
  }

  getCurrency() {
		if(!this.qrcode) return '';
		return this.qrcode.currencyTableValue || this.qrcode.currency;
	}

  onClickOrder() {
    this.onOrder.emit()
  }

  onClickValidate() {
    this.onValidate.emit()
  }

}
