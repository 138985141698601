export enum AccessLicence {
	free = "free",
	premium = "premium",
	clickAndCollect = "clickAndCollect",
}

export class QrcodeTheme {

    public id: number;
    public colors: any[];
    public versionId: number;

    constructor(
        id: number,
        colors: any[],
        versionId: number
    ) {

        this.id = id;
        this.colors = colors;
        this.versionId = versionId;
    }
}