import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ClickCollect } from 'src/app/core/models/click-collect.model';
import { Client } from 'src/app/core/models/client.model';
import { CustomerAddress } from 'src/app/core/models/customer-address.model';
import { DeliveryZone } from 'src/app/core/models/delivery-zone.model';
import { ProcessMethod, ProcessOrder } from 'src/app/core/models/process-order.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClickCollectService } from 'src/app/core/services/api/click-collect.service';
import { ClientService } from 'src/app/core/services/api/client.service';
import { CustomerService } from 'src/app/core/services/api/customer.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { AppStateService } from 'src/app/core/services/app-state.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DateService } from 'src/app/core/services/date.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { ModalCustomComponent } from '../../modal/modal-custom/modal-custom.component';
import { AddressComponent } from '../../profile/register/address-list/address/address.component';

@Component({
	selector: 'app-order-delivery',
	templateUrl: './order-delivery.component.html',
	styleUrls: ['./order-delivery.component.scss']
})
export class OrderDeliveryComponent implements OnInit, OnDestroy {

	public displayedAlert: boolean = false;
	public addresses: CustomerAddress[];
	public dataSubscribe: Subscription;
	public loading: boolean;
	public floating: boolean = false;

	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() hash: string;
	@Input() cc: ClickCollect;
	@Input() processOrder: ProcessOrder;
	@Input() editMode: boolean = false;
	@Input() zones: DeliveryZone[] = [];
	@Input() exceptions: DeliveryZone[] = [];
	@Input() stripeError: boolean = false;

	@Output() onClose: EventEmitter<void>;
	@Output() onSelectAddress: EventEmitter<CustomerAddress>;
	@Output() onSelectDateTime: EventEmitter<Date>;
	@Output() onSelectEditMode: EventEmitter<boolean>;
	@Output() onSelectDeliveryZone: EventEmitter<DeliveryZone>;

	constructor(
		protected ref: ElementRef,
		protected route: ActivatedRoute,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected customerService: CustomerService,
		protected overlayService: OverlayService,
		protected clickCollectService: ClickCollectService,
		protected dateService: DateService,
		protected modalService: ModalService,
		protected appStateService: AppStateService,
		protected authService: AuthService,
		protected translateService: TranslateService

	) {
		this.onClose = new EventEmitter<void>()
		this.onSelectAddress = new EventEmitter<CustomerAddress>()
		this.onSelectDateTime = new EventEmitter<Date>()
		this.onSelectEditMode = new EventEmitter<boolean>();
		this.onSelectDeliveryZone = new EventEmitter<DeliveryZone>()

	}

	ngOnInit(): void {
		this.loading = true;

		this.dataSubscribe = this.overlayService.getDataAfterClose().pipe(
			mergeMap(data => {
				if(!this.authService.getCustomerId()) return of([]);

				if(data && data.form) {
					const {form} = data;
					form.favorite = false;

					form.customerId = this.authService.getCustomerId();

					return (!data.edit ? this.customerService.addAddress(form) :
					this.customerService.editAddress(data.id, form) ).pipe(
						mergeMap(_ => this.customerService.getAddresses(this.authService.getCustomerId()))
					);
				}

				return this.customerService.getAddresses(this.authService.getCustomerId())
			})

		).subscribe((addresses: CustomerAddress[]) => {
			this.loading = false;
			this.addresses = addresses;
		})
	}

	ngOnDestroy(): void {
		if(this.dataSubscribe) this.dataSubscribe.unsubscribe()
	}

	getFormatedDate(time: number = 0, gap: number = 30): string {
		const datePipe: DatePipe = new DatePipe('fr-Fr');

		const [hours, minutes] = ['HH', 'mm'].map(v =>  datePipe.transform(this.processOrder.orderAt, v))
		const date = new Date();
			  date.setHours(parseInt(hours));
			  date.setMinutes(parseInt(minutes) + time);
		
		const date2 = new Date(date);
		date2.setMinutes(date2.getMinutes() + gap);

		return `Le ${datePipe.transform(this.processOrder.orderAt, 'EEEE')} ${datePipe.transform(this.processOrder.orderAt, 'dd')} ${datePipe.transform(this.processOrder.orderAt, 'MMMM')} entre ${datePipe.transform(date, 'HH:mm')} et ${datePipe.transform(date2, 'HH:mm')}`;
	}

	editAddress(address: CustomerAddress) {
		this.overlayService.open(AddressComponent, {
			client: this.client,
			edit: true,
			address
		})
	}

	selectAddress(value: CustomerAddress) {
		this.onSelectAddress.emit(value)
	}

	selectDeliveryZone(value: DeliveryZone) {
		this.onSelectDeliveryZone.emit(value)
	}

	selectDateTime(value) {
		this.onSelectDateTime.emit(value);
		this.appStateService.setScrollTo(2000);
	}

	cancel(e: any) {
		e.preventDefault();
		this.onSelectEditMode.emit(false);
	}

	edit(e: any) {
		e.preventDefault();
		if(this.processOrder.method === ProcessMethod.now) {
			this.clickCollectService.canDeliver(this.qrcode.hash, this.cc.manufacturingTime, false, new Date()).subscribe(value => { 
				if(!value) {
					this.modalService.open(ModalCustomComponent, {
						title:  this.translateService.instant('globals.warning'),
						intro: this.translateService.instant('v2.click_go.selection.no_delivery_available_now_2')

					}, () => {}, null)

				} else  {
					this.onSelectEditMode.emit(true);
					this.appStateService.setScrollTo(2000);
				}
			});
			
		} else {
			this.clickCollectService.canDeliver(this.qrcode.hash, this.cc.manufacturingTime, true, new Date()).subscribe(value => {
				if(!value) {
					this.modalService.open(ModalCustomComponent, {
						title:  this.translateService.instant('globals.warning'),
						intro: this.translateService.instant('v2.click_go.selection.no_delivery_available_2')

					}, () => {}, null)

				} else {
					this.onSelectEditMode.emit(true)
					this.appStateService.setScrollTo(2000);
				}
			});
		}	
	}

	evaluateTime() {
		return (this.cc.manufacturingTime ? this.cc.manufacturingTime : 0 ) + 
		(this.processOrder 
			&& this.processOrder.deliveryZone
			&& this.processOrder.deliveryZone.deliveryTime ? this.processOrder.deliveryZone.deliveryTime : 0)
	}
}
