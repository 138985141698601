import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { Client } from 'src/app/core/models/client.model';
import { Family } from 'src/app/core/models/family.model';
import { ItemMenuProduct, ItemMenuProductType } from 'src/app/core/models/item-menu-product.model';
import { Product } from 'src/app/core/models/product.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { of, Subscription } from 'rxjs';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { FamillyService } from 'src/app/core/services/api/familly.service';
import { AllergenService } from 'src/app/core/services/api/allergen.service';
import { ClientService } from 'src/app/core/services/api/client.service';
import { ProcessService } from 'src/app/core/services/process.service';
import { ProcessOrder } from 'src/app/core/models/process-order.model';
import { ItemType } from 'src/app/core/models/shop-item.model';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { VersionService } from 'src/app/core/services/api/version.service';
import { RouterService } from 'src/app/core/services/router.service';

@Component({
	selector: 'app-qrcode-family',
	templateUrl: './family.component.html',
	styleUrls: ['./family.component.scss']
})
export class FamilyComponent implements OnInit, OnDestroy {

	public loading = false;
	public client: Client;
	public family: Family;
	public qrcode: Qrcode;
	public hash: string;
	public familyId: number;
	public tmpUrl = "url('https://i.pinimg.com/564x/4e/90/2e/4e902e1b9bf980fb97015c41eb543ddb.jpg')";
	public items: ItemMenuProduct[];
	public suggestions: Product[];
	public selectedProduct: Product;
	public horaireOpen = false;
	public allergenById = {};
	public pageType: string;
	public processOrder: ProcessOrder;
	public subscriptions: Subscription[] = [];
	public table: string = 'any';
	public version: string = "v2";

	constructor(
		protected route: ActivatedRoute,
        protected routerService: RouterService,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected navigationService: NavigationService,
		protected versionService: VersionService,
		protected famillyService: FamillyService,
		protected allergenService: AllergenService,
		protected processService: ProcessService
	) {
		this.hash = this.route.parent.snapshot.paramMap.get('hash');
		this.table = this.route.parent.snapshot.paramMap.get('table');
		this.familyId = parseInt(this.route.snapshot.paramMap.get('famillyId'), 10);
	}

	
	ngOnInit() {
		this.loading = true;
		this.navigationService.setData({});

		this.subscriptions.push(this.processService.getProcessOrder().subscribe(processOrder => this.processOrder = processOrder));

		this.route.data.subscribe(({pageType}) => {
			this.pageType = pageType
		});

		this.clientService.getClient(this.hash).pipe(
			catchError(_ => {
				this.loading = false;
				return of(null);
			}),
			mergeMap(client => {
				this.client = client;
				return this.qrcodeService.getQrcode(this.hash)
			}),
			mergeMap(qrcode => {
				this.qrcode = qrcode;
				//this.qrcodeService.setColors(qrcode);
				
				const orderAt = this.processOrder 
					&& this.processOrder.orderAt 
					&& this.processOrder.orderAt ? this.processOrder.orderAt : null;

				return this.pageType === 'click-go' 
				? this.famillyService.getClickCollectFamily(this.hash, this.familyId, true, orderAt) 
				: this.famillyService.getFamilly(this.hash, this.familyId, true)
			}),
			mergeMap(family => {
				if(!(family && family.availability)) this.routerService.navigate(['/', this.version, this.pageType, this.hash, 'list'])

				this.navigationService.setData({family});

				this.family = family;
				return this.allergenService.getAllergens(this.hash);
			}),
			finalize(() => { this.loading = false })

		).subscribe(allergens => {
			allergens.forEach(allergen => {
				this.allergenById[allergen.id] = allergen;
			});

			this.items = [];
			this.suggestions = [];
			this.family.products.forEach(product => {
				this.items.push(
					new ItemMenuProduct(
						product.id, 
						product.position, 
						ItemMenuProductType.product
						, product, 
						null
					)
				);

				if (product.isPromote && product.availability) {
					this.suggestions.push(product);
				}
			});

			this.family.menus.forEach(menu => {
				this.items.push(
					new ItemMenuProduct(
						menu.id, 
						menu.position, 
						ItemMenuProductType.menu, 
						null,
						menu
					));
			});

			this.items.sort((a, b) => (a.position > b.position) ? 1 : -1);
			this.loading = false;
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.map(sub => {
			if(sub) sub.unsubscribe();
		})
	}

	haveDescription(product: Product) {
		return !(product.ingredient === '' || product.ingredient === null) 
	}

	haveSpecs(product: Product) {
		return product.isBio || product.isVegan || product.isVegetarian || product.isHomemade;
	}

	haveDetails(product: Product) {
		return (this.qrcode.nutriscoreEnable && product.nutriscore && product.nutriscore !== '0') 
		|| (this.qrcode.alergenEnable && product.alergen)
	}

	havePicture(product: Product) {
		return (product.pictureUrl && product.pictureUrl.length > 0) || (product.descriptionPictureUrl && product.descriptionPictureUrl.length > 0);
	}

	isComplexProduct(product: Product) {
		const productType = product.groupOptions.length > 0 && product.isMultiprice
          ? ItemType.both : (product.groupOptions.length > 0 ? ItemType.groupOption : (product.isMultiprice ? ItemType.multiprice : ItemType.simple) );

		if((this.pageType === 'in' || this.pageType === 'click-go') && productType !== ItemType.simple)
			return true;

		return this.haveSpecs(product) || this.haveDescription(product) || this.haveDetails(product) || this.havePicture(product);
	}

	public productAction(index: number): void {
		let product = this.items[index].product;
		
		if (this.isComplexProduct(product) && this.qrcode) {
			this.routerService.navigate(this.pageType === 'in' ? ['/', this.version, this.pageType, this.hash, this.table, 'family', this.family.id, 'product', product.id] : ['/', this.version, this.pageType, this.hash, 'family', this.family.id, 'product', product.id])
		}
	}

	public suggestionAction(index: number): void {
		let product = this.suggestions[index];

		if ((this.isComplexProduct(product) || (this.pageType === 'click-go' || this.pageType === 'in')) && this.qrcode) {
			this.routerService.navigate(this.pageType === 'in' ? ['/', this.version, this.pageType, this.hash, this.table, 'family', this.family.id, 'product', product.id] : ['/', this.version, this.pageType, this.hash, 'family', this.family.id, 'product', product.id])
		}
	}

	public closeOverlay(): void {
		this.selectedProduct = null;
	}
}
