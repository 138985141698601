import { Injectable } from "@angular/core";
import { Customer } from '../customer.model';
import { Adapter } from "./adapter";

@Injectable({
    providedIn: "root",
})
export class CustomerAdapter implements Adapter<Customer> {

    constructor() { }

    adapt(item: any): Customer {
        return new Customer(
            item.id,
            item.lastname,
            item.firstname,
            item.email,
            item.phone,
            item.externalId,
            item.verifCode
        );
    }
}
