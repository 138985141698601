import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { NavigationPage, NavigationService } from 'src/app/core/services/navigation.service';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	animations: [
		trigger('slide', [
			state('open', style({
				opacity: 1,
				transform: 'translateY(-50%)'
			})),
			state('close', style({
				opacity: 0,
				transform: 'translateY(-40%)'
			})),
			transition('close => open', [
				animate(300)
			]),
			transition('open => close', [
				animate(200)
			])
		])
	]
})
export class ModalComponent implements OnInit, OnDestroy {
	public active: boolean = false;
	public show: boolean = false;
	protected subscriptions: Subscription[] = [];

	public page: NavigationPage;

	@ViewChild('modalContent', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;

	data: {[key: string]: any} = {};
	
	@Input() component: any;

	public defaultConfirmText: string = 'globals.btn.confirm';
	public defaultCancelText: string = 'globals.btn.cancel';

	public confirmText: string;
	public cancelText: string;

	@Output() onClose: EventEmitter<void>;
	
	constructor(
		protected resolver: ComponentFactoryResolver,
		protected navigationService: NavigationService,
		protected modalService: ModalService,

	) {
		this.onClose = new EventEmitter<void>(); 
	}

	ngOnInit(): void {
		this.confirmText = this.defaultConfirmText;
		this.cancelText = this.defaultCancelText;

		this.subscriptions.push(this.modalService.getData().subscribe(data => {
			this.show = !!data;
			this.data = data && data.data ? data.data : this.data;

			if(this.data.confirmText) this.confirmText = this.data.confirmText;
			else this.confirmText = this.defaultConfirmText;

			if(this.data.cancelText) this.cancelText = this.data.cancelText;
			else this.cancelText = this.defaultCancelText;

			if(this.show) {
				setTimeout(() => this.active = true);
				this.open(data)
			}
		}));

		this.subscriptions.push(this.navigationService.getPage().subscribe(page => this.page = page));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => {
		  if(sub) sub.unsubscribe();
		})
	}

	open({component, data}: any)  {
		setTimeout(() => {
			if(this.entry) {
				const factory = this.resolver.resolveComponentFactory(component);
				const componentRef = this.entry.createComponent(factory) as ComponentRef<any>;
			
				Object.keys(data).forEach(k => componentRef.instance[k] = data[k])
			}
		})
	}

	get hasCancel(): boolean {
		return this.modalService.hasCancel;
	}

	get hasConfirm(): boolean {
		return this.modalService.hasConfirm;
	}
	
	cancel(e: any) {
		e.preventDefault();
		this.active = false;

		setTimeout(() => {
			this.modalService.fireCancel();
			this.show = false
		}, 300);
	}

	confirm(e: any) {
		e.preventDefault();
		this.active = false;

		setTimeout(() => {
			this.modalService.fireConfirm();
			this.show = false
		}, 300);
	}
}
