import { Component, Input } from '@angular/core';
import { ProductAdapter } from 'src/app/core/models/adapter/product.adapter';
import { ShopItem } from 'src/app/core/models/shop-item.model';

@Component({
	selector: 'app-modal-warning',
	templateUrl: './modal-warning.component.html',
	styleUrls: ['./modal-warning.component.scss']
})
export class ModalWarningComponent {

	@Input() shopItems: ShopItem[] = [];
	@Input() confirmText: string;
	@Input() cancelText: string;
	
	constructor(
		protected productAdapter: ProductAdapter
	) {}

	getNameOfMenuProduct(productId: number, shopItem: ShopItem) {
		const product = shopItem.menuProducts.find(p => p.id === productId);
	
		if(!product) return '';
		if(!(product.menuGroupOptions && product.menuGroupOptions.length)) return product.name;
	
		const goInProduct = product.menuGroupOptions.map(go => go.id);
		const options = shopItem.menuGroupOptions.filter(opt => goInProduct.includes(opt.id));
	
		return product.name + (options && options.length ? ' | ' + options.map(({name}) => name).join(' | ') : '');
	}

	getMenuItems(shopItem: ShopItem) {
		return shopItem.menu ? shopItem.menuProducts.map(p => this.getNameOfMenuProduct(p.id, shopItem)) : []

	}

	getFullName(shopItem: ShopItem) {
		if(shopItem.menu || shopItem.product) {
			const name = shopItem.product ? shopItem.product.name : shopItem.menu.name;
			const hasMultiPrice = !!shopItem.price

			if(shopItem.menu) {
				return name + " " + shopItem.menuProducts.map(p => this.getNameOfMenuProduct(p.id, shopItem)).join(' | ')
			}

			return name + ( hasMultiPrice ? ' | ' + shopItem.price.name : '' ) + shopItem.groupOptions.reduce((acc, curr, i) => acc + ' | ' + curr.name, '')
		}	
		return null;
	}
}
