import { ClickCollect } from "./click-collect.model";
import { OpeningHours } from "./opening-hours.model";
import { QrcodeTheme } from "./qrcode-theme.model";

export enum AccessLicence {
	free = "free",
	premium = "premium",
	clickAndCollect = "clickAndCollect",
}

export enum ShowPhoneOptions {
    phone = "phone",
    whatsapp = "whatsapp",
	both = "both"
}

export class Qrcode {
    id: number;
    name: string;
    headline: string;
    licence: AccessLicence;
    stripeCurrency: string;
    currencyTableValue: string;
    currencyTableMinOrder: number;
    hash: string;
    clickCollectEnable: boolean;
    logoEnable: boolean;
    isPromote: boolean;
    alergenEnable: boolean;
    nutriscoreEnable: boolean;
    showPhone: ShowPhoneOptions;
    productDescEnable: boolean;
    themeIllustrationEnable: boolean;
    qrcodeNameEnable: boolean;
    clientNameEnable: boolean;
    headlineEnable: boolean;
    currency: string;
    menuType: string;
    activation: string;
    color1: string;
    color2: string;
    color3: string;
    color4: string;
    color5: string;
    color6: string;
    color7: string;
    color8: string;
    theme: number;
    websiteUrl: string;
    reservationUrl: string;
    facebookUrl: string;
    instagramUrl: string;
    youtubeUrl: string;
    twitterUrl: string;
    tripadvisorUrl: string;
    whatsAppUrl: string;
    tikTokUrl: string;
    wifi: string;
    wifiPassword: string;
    customOpeningHours: boolean;
    hours: string;
    phone: string;
    address: string;
    zipcode: string;
    city: string;
    country: string;
    longitude: string;
    latitude: string;
    familly: string;
    subFamilly: string;
	beginAt: string;
	endAt: string;
    parentId: number;
    currencyTableId: number;
    versionId: number;
    version: any;
    clickCollect: ClickCollect;
    qrcodeThemes: QrcodeTheme[];
    openingHours: OpeningHours[];

    constructor(
        id: number,
        name: string,
        headline: string,
        licence: AccessLicence,
        stripeCurrency: string,
        currencyTableValue: string,
        currencyTableMinOrder: number,
        hash: string,
        clickCollectEnable: boolean,
        logoEnable: boolean,
        isPromote: boolean,
        alergenEnable: boolean,
        nutriscoreEnable: boolean,
        showPhone: ShowPhoneOptions,
        productDescEnable: boolean,
        themeIllustrationEnable: boolean,
        qrcodeNameEnable: boolean,
        clientNameEnable: boolean,
        headlineEnable: boolean,
        currency: string,
        menuType: string,
        activation: string,
        color1: string,
        color2: string,
        color3: string,
        color4: string,
        color5: string,
        color6: string,
        color7: string,
        color8: string,
        theme: number,
        websiteUrl: string,
        reservationUrl: string,
        facebookUrl: string,
        instagramUrl: string,
        youtubeUrl: string,
        twitterUrl: string,
        tripadvisorUrl: string,
        whatsAppUrl: string,
        tikTokUrl: string,
        wifi: string,
        wifiPassword: string,
        customOpeningHours: boolean,
        hours: string,
        phone: string,
        address: string,
        zipcode: string,
        city: string,
        country: string,
        longitude: string,
        latitude: string,
        familly: string,
        subFamilly: string,
        beginAt: string,
        endAt: string,
        parentId: number,
        currencyTableId: number,
        versionId: number,
        version: any,
        clickCollect: ClickCollect,
        qrcodeThemes: QrcodeTheme[],
        openingHours: OpeningHours[]) {

        this.id = id;
        this.name = name;
        this.headline = headline;
        this.licence = licence ?? AccessLicence.clickAndCollect;
        this.stripeCurrency = stripeCurrency ?? 'EUR';
        this.currencyTableValue = currencyTableValue;
        this.currencyTableMinOrder = currencyTableMinOrder ?? 0;
        this.hash = hash;
        this.clickCollectEnable = clickCollectEnable;
        this.logoEnable = logoEnable;
        this.isPromote = isPromote;
        this.alergenEnable = alergenEnable;
        this.nutriscoreEnable = nutriscoreEnable;
        this.showPhone = showPhone;
        this.productDescEnable = productDescEnable;
        this.themeIllustrationEnable = themeIllustrationEnable;
        this.qrcodeNameEnable = qrcodeNameEnable;
        this.clientNameEnable = clientNameEnable;
        this.headlineEnable = headlineEnable;
        this.currency = currency;
        this.menuType = menuType;
        this.activation = activation;
        this.color1 = color1;
        this.color2 = color2;
        this.color3 = color3;
        this.color4 = color4;
        this.color5 = color5;
        this.color6 = color6;
        this.color7 = color7;
        this.color8 = color8;
        this.theme = theme;
        this.websiteUrl = websiteUrl;
        this.reservationUrl = reservationUrl;
        this.facebookUrl = facebookUrl;
        this.instagramUrl = instagramUrl;
        this.youtubeUrl = youtubeUrl;
        this.twitterUrl = twitterUrl;
        this.tripadvisorUrl = tripadvisorUrl;
        this.whatsAppUrl = whatsAppUrl;
        this.tikTokUrl = tikTokUrl;
        this.wifi = wifi;
        this.wifiPassword = wifiPassword;
        this.customOpeningHours = customOpeningHours;
        this.hours = hours;
        this.phone = phone;
        this.address = address;
        this.zipcode = zipcode;
        this.city = city;
        this.country = country;
        this.longitude = longitude;
        this.latitude = latitude;
        this.familly = familly;
        this.subFamilly = subFamilly;
        this.beginAt = beginAt;
        this.endAt = endAt;
        this.parentId = parentId;
        this.currencyTableId = currencyTableId;
        this.versionId = versionId;
        this.version = version;
        this.clickCollect = clickCollect;
        this.qrcodeThemes = qrcodeThemes;
        this.openingHours = openingHours;
    }

    public getHours(): string[] {
        return this.hours ? this.hours.split(/(?:\r\n|\r|\n)/) : [];
    }

    public getAddressForGoogle(): string {
        return `${this.address.replace(' ', '+')}+${this.zipcode}+${this.city}`;
    }
}