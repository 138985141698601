import { GoogleMap } from "@angular/google-maps";
import { BehaviorSubject, Observable } from "rxjs";
import { MarkerMap } from "../../models/marker-map.model";

export class MarkerDrawingMode {
    protected object;
    protected object$: BehaviorSubject<MarkerMap>;
    protected listeners = {};

    public readonly drawingOptions = {
        drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: true,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
                google.maps.drawing.OverlayType.MARKER
            ],
        },
        markerOptions: {}
    };

    constructor() {
        this.object$ = new BehaviorSubject<MarkerMap>(null);
    }

    /**
     * Rajoute les événements pour le marqueur
     * 
     * @param {google.maps.drawing.DrawingManager} drawingManager 
     * @param {GoogleMap} map 
     */
    public addEvents(drawingManager: google.maps.drawing.DrawingManager, map: GoogleMap) {
        google.maps.event.addListener(drawingManager, 'markercomplete', (o) => {
            this.deleteObject(drawingManager)
            this.object = o;

            const coords: {lat: any, lng: any} = JSON.parse(this.getData());
            this.object$.next({coords});

            drawingManager.setDrawingMode(null);
            this.object.setMap(map.googleMap);
        });
    } 

    /**
     * Change le marqueur
     * 
     * @param {MarkerMap} markerMap 
     * @param {google.maps.drawing.DrawingManager} drawingManager 
     * @param {GoogleMap} map 
     */
    public changeObject(markerMap: MarkerMap, drawingManager: google.maps.drawing.DrawingManager, map: GoogleMap, edit: boolean = false): void {
        this.deleteObject(drawingManager);

        this.object = new google.maps.Marker({
            position: markerMap.coords
        });

        this.object$.next(markerMap);
        if(edit) drawingManager.setDrawingMode(null);
        this.object.setMap(map.googleMap);
    }

    /**
     * Supprimer le marqueur
     * 
     * @param {google.maps.drawing.DrawingManager} drawingManager 
     */
    public deleteObject(drawingManager: google.maps.drawing.DrawingManager, edit: boolean = false): void {
        if(!this.object) return;
        this.object.setMap(null);
        this.object = null;
        if(edit) drawingManager.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
        this.object$.next(null);
    }

    /**
     * Renvoie le marqueur en observable
     *
     * @returns {Observable.<MarkerMap>}
     */
    public getObject(): Observable<MarkerMap> {
        return this.object$.asObservable();
    }

    /**
     * Renvoie les données
     *
     * @returns {string}
     */
    public getData(): string {
        if(!this.object) return "";
        let position = this.object.getPosition();
        let [lat, lng] = [position.lat(), position.lng()]
        return JSON.stringify({lat, lng});
    }
}