import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Product } from "src/app/core/models/product.model";
import { Qrcode } from "src/app/core/models/qrcode.model";
import { ShopCart } from "src/app/core/models/shop-cart.model";
import { ItemType } from "src/app/core/models/shop-item.model";
import { ShopCartService } from "src/app/core/services/shop-cart.service";
import { VersionService } from "src/app/core/services/api/version.service";
import { RouterService } from "src/app/core/services/router.service";

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {

    @Input() qrcode: Qrcode;
    @Input() product: Product;
    @Input() table: string = 'any';
    @Input() hash: string;
    @Input() productDescEnable: boolean = false;
    @Input() fullWidth: boolean = false;
    @Input() pageType: string = 'qrcode';

    public imageUrl: string;
    public descImageUrl: string;

    public subscribe: Subscription;
    public cart: ShopCart;
    public productType: ItemType;
    public version: string = 'v2';

    constructor(
		protected routerService: RouterService,
        protected versionService: VersionService,
		protected shopCartService: ShopCartService
    ) {}

    ngOnInit() {
        this.productType = this.product.groupOptions.length > 0 && this.product.isMultiprice
          ? ItemType.both : (this.product.groupOptions.length > 0 ? ItemType.groupOption : (this.product.isMultiprice ? ItemType.multiprice : ItemType.simple) );

        this.subscribe = this.shopCartService.getShopCart().subscribe(cart => this.cart = cart);

        if (this.product.pictureUrl && this.product.pictureUrl !== '') {
            this.imageUrl = `url(${this.product.pictureUrl})`
        }

        if (this.product.descriptionPictureUrl && this.product.descriptionPictureUrl !== '') {
            this.descImageUrl = `url(${this.product.descriptionPictureUrl})`
        }
    }

    ngOnDestroy(): void {
        this.subscribe.unsubscribe();
    }

    haveDescription() {
       return !(this.product.ingredient === '' || this.product.ingredient === null) 
    }

    haveSpecs() {
        return this.product.isBio || this.product.isVegan || this.product.isVegetarian || this.product.isHomemade;
    }

    haveDetails() {
        return (this.qrcode.nutriscoreEnable && this.product.nutriscore && this.product.nutriscore !== '0') 
        || (this.qrcode.alergenEnable && this.product.alergen)
    }

    havePicture() {
		return (this.product.pictureUrl && this.product.pictureUrl.length > 0) || (this.product.descriptionPictureUrl && this.product.descriptionPictureUrl.length > 0);
    }

    isComplexProduct() {
        if((this.pageType === 'in' || this.pageType === 'click-go') && this.productType !== ItemType.simple)
            return true;

        return this.haveDescription() || this.haveSpecs() || this.haveDetails() || this.havePicture();
    }

    addItem(product: Product) {
        let added = false;

        this.cart.items.forEach((item, i) => {
            if(item.product && item.product.id === product.id) {
                added = true;
                this.shopCartService.addProduct(product)
            }
        });

        if(!added) {
            this.shopCartService.addProduct(product);
        }
    }

    removeItem(product: Product) {
        this.cart.items.forEach((item, i) => {
           if(item.product && item.product.id === product.id) this.shopCartService.removeProduct(product)
        });
    }

    removeAllItems(product: Product) {
        this.shopCartService.removeAllProduct(product.id);
    }

    selectItem(product: Product) {
        if(this.pageType === "in")
            this.routerService.navigate(['/', this.version, this.pageType, this.hash, this.table, 'family', product.famillyId, 'product', product.id])
        else 
            this.routerService.navigate(['/', this.version, this.pageType, this.hash, 'family', product.famillyId, 'product', product.id])

    }   

    repeat(nbr: number, product: Product) {
        return Array(nbr).fill(product.name).join(' ');
    }

    get countItem() {
        return this.shopCartService.getProductLength(this.product.id);
    }
}
