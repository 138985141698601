import { ProductGroupsOptions } from "./product-groups-options.model";
import { ProductPrice } from "./product-price.model";

export class Product {

    id: number;
    position: number;
    name: string;
    ingredient: string;
    pictureUrl: string;
    descriptionPictureUrl: string;
    nutriscore: string;
    price: string;
    priceIndex?: number;
    cartPrice?: number;
    alergen: string;
    isEnable: boolean;
    isPromote: boolean;
    isHomemade: boolean;
    isMultiprice: boolean;
    isVegan: boolean;
    isVegetarian: boolean;
    isBio: boolean;
    scheduling: string;
    isScheduled: boolean;
    is18YearOld: boolean;
    availability: boolean;
    famillyId: number;
    menuStepId: number;
    prices: ProductPrice[];
    groupOptions: ProductGroupsOptions[];
    menuGroupOptions: ProductGroupsOptions[];

    constructor(
        id: number,
        position: number,
        name: string,
        ingredient: string,
        pictureUrl: string,
        descriptionPictureUrl: string,
        nutriscore: string,
        price: string,
        priceIndex: number,
        cartPrice: number,
        alergen: string,
        isEnable: boolean,
        isPromote: boolean,
        isHomemade: boolean,
        isMultiprice: boolean,
        isVegan: boolean,
        isVegetarian: boolean,
        isBio: boolean,
        scheduling: string,
        isScheduled: boolean,
        is18YearOld: boolean,
        availability: boolean,
        famillyId: number,
        menuStepId: number,
        prices: ProductPrice[],
        groupOptions: ProductGroupsOptions[],
        menuGroupOptions: ProductGroupsOptions[]
    ) {
        this.id = id;
        this.position = position;
        this.name = name;
        this.ingredient = ingredient;
        this.pictureUrl = pictureUrl;
        this.descriptionPictureUrl = descriptionPictureUrl;
        this.nutriscore = nutriscore;
        this.price = price;
        this.priceIndex = priceIndex;
        this.cartPrice = cartPrice;
        this.alergen = alergen;
        this.isEnable = isEnable;
        this.isPromote = isPromote;
        this.isHomemade = isHomemade;
        this.isMultiprice = isMultiprice;
        this.isVegan = isVegan;
        this.isVegetarian = isVegetarian;
        this.isBio = isBio;
        this.scheduling = scheduling;
        this.isScheduled = isScheduled;
        this.is18YearOld = is18YearOld;
        this.availability = availability;
        this.famillyId = famillyId;
        this.menuStepId = menuStepId;
        this.prices = prices;
        this.groupOptions = groupOptions;
        this.menuGroupOptions = menuGroupOptions;
    }

    public pictureUrlForBg(): string {
        return `url(${this.pictureUrl})`;
    }

    public descriptionPictureUrlForBg(): string {
        return `url(${this.descriptionPictureUrl})`;
    }

    public getAllergenIds(): number[] {
        if (this.alergen) {
            let ids = this.alergen.split(',');
            return ids.map(id => parseInt(id));
        }
        return [];
    }

    public haveDetails(): boolean {
        return (this.descriptionPictureUrl || this.pictureUrl || this.alergen !== null || (this.nutriscore !== null && this.nutriscore !== "0")) ? true : false;
    }
}