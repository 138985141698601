import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-modal-reset-process',
	templateUrl: './modal-reset-process.component.html',
	styleUrls: ['./modal-reset-process.component.scss']
})
export class ModalResetProcessComponent {
	@Input() confirmText: string;
	@Input() cancelText: string;
}
