import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend  } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GoogleMap } from '@angular/google-maps';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapService {
    protected apiKey = environment.googleApiKey;
    protected geoApiKey = environment.googleGeocodeApiKey;
    protected $apiLoaded: BehaviorSubject<boolean>;
    protected httpb: HttpClient;

    constructor(
        protected handler: HttpBackend,
        protected http: HttpClient,
    ) {
        this.httpb = new HttpClient(handler);
        this.$apiLoaded = new BehaviorSubject<boolean>(false);
    }

    /**
     * Charge la carte avec le module de dessin
     *
     * @returns {Observable.<boolean>}
     */
    public load(): Observable<boolean> {
        if(this.$apiLoaded.value) return of(true);
        return this.http.jsonp(environment.googleApiUrl.replace(':api_key', this.apiKey), 'callback')
            .pipe(
                tap(() => this.$apiLoaded.next(true)),
                map(() => true),
                catchError(_ => of(false)),
            );
    }

    /**
     * Initialise boîte à dessin
     * 
     * @param {string} name 
     */
    public getDrawingManager(mode: any, map: GoogleMap): google.maps.drawing.DrawingManager  {
        const drawingOptions = mode.drawingOptions;
        const drawingManager = new google.maps.drawing.DrawingManager(drawingOptions);
        drawingManager.setMap(map.googleMap);
        return drawingManager;
    }

    /**
     * Récupère les coordonnées selon l'adresse (avec Geocode API)
     *
     * @param {string} address
     *
     * @returns {Observable.<*>}
     */
    public getCoordsByAddress(address: string): Observable<any> {
        return this.httpb.get(environment.geoCodeUrl.replace(':address', encodeURI(address)).replace(':api_key', this.geoApiKey))
        .pipe(
            map((response: any) => {
                return response;
            }),
            catchError(_ => of(false))
        );
    }

    /**
     * Récupère les coordonnées selon l'adresse (avec Geocode API)
     *
     * @param {string} address
     *
     * @returns {Observable.<*>}
     */
     public getAddressByCoords(coords: {lat: number, lng: number}): Observable<any> {
        const {lat, lng} = coords;
        const url = environment.geoCodeUrlReverse
        .replace(':latLng', encodeURI(`${lat},${lng}`))
        .replace(':api_key', this.geoApiKey);

        return this.httpb.get(url)
            .pipe(
                catchError(_ => of(false))
            );
    }

    /**
     * Vérifie si l'api est chargé
     * 
     * @returns {} - Vrai si l'api est chargé
     */
    public isLoaded(): Observable<boolean> {
        return this.$apiLoaded.asObservable();
    }

    /**
     * Vérifie si l'adresse se trouve dans la zone
     * 
     * @param {string} deliveryZone - addresse en json
     * @param {number} lat - latitude
     * @param {number} lng - longitude
     * 
     * @returns {boolean} - Vrai si il se trouve
     */
    public checkDeliveryPosition(deliveryZone: string, lat: number, lng: number): boolean {
        if(!deliveryZone || deliveryZone.trim() === "") return false;
    
        let polygon = JSON.parse(deliveryZone);

        if(typeof(polygon) === 'string') polygon = JSON.parse(polygon)

        if(!polygon) return false;
        
        let j = 0,  i = 0, isInside = false;
        
        // Algorithme de calcul
        for (i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            if (
                polygon[i].lng > lng != (polygon[j].lng > lng) 
                && lat < (polygon[j].lat - polygon[i].lat) * (lng - polygon[i].lng) / (polygon[j].lng - polygon[i].lng) + polygon[i].lat
            ){
                isInside = !isInside;
            }
        }
    
        return isInside;
    }
}
