import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { forkJoin, of, Subscription, throwError } from 'rxjs';
import { ClickCollect } from 'src/app/core/models/click-collect.model';
import { Client } from 'src/app/core/models/client.model';
import { ItemOrderProduct, ItemOrderProductType } from 'src/app/core/models/item-order-product.model';
import { Product } from 'src/app/core/models/product.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ShopCartService } from 'src/app/core/services/shop-cart.service';
import { ClickCollectService } from 'src/app/core/services/api/click-collect.service';
import { AllergenService } from 'src/app/core/services/api/allergen.service';
import { ClientService } from 'src/app/core/services/api/client.service';
import { FamillyService } from 'src/app/core/services/api/familly.service';
import { AppStateService } from 'src/app/core/services/app-state.service';
import { ShopCart } from 'src/app/core/models/shop-cart.model';
import { ItemType, ShopItem } from 'src/app/core/models/shop-item.model';
import { ProcessService } from 'src/app/core/services/process.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ProcessMethod, ProcessOrder, ProcessType } from 'src/app/core/models/process-order.model';
import { OrderService } from 'src/app/core/services/api/order.service';
import { GoogleMapService } from 'src/app/core/services/google-map.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { StripeService } from 'src/app/core/services/stripe.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderRequest } from 'src/app/core/models/requests/order.request';
import { CustomerService } from 'src/app/core/services/api/customer.service';
import { CustomerAddressRequest } from 'src/app/core/models/requests/customer-address.request';
import { NavigationPage, NavigationService } from 'src/app/core/services/navigation.service';
import { ProductService } from 'src/app/core/services/api/product.service';
import { OrderDetailsRequest } from 'src/app/core/models/requests/order-details.request';
import { OrderItemRequest } from 'src/app/core/models/requests/order-item.request';
import { OrderStatus } from 'src/app/core/models/order.model';
import { TableRuleService } from 'src/app/core/services/api/table-rule.service';
import { DeliveryZone } from 'src/app/core/models/delivery-zone.model';
import { DeliveryZoneService } from 'src/app/core/services/api/delivery-zone.service';
import { VersionService } from 'src/app/core/services/api/version.service';
import { ProcessOrderAdapter } from 'src/app/core/models/adapter/process-order.adapter';
import { ModalCustomComponent } from 'src/app/shared/components/modal/modal-custom/modal-custom.component';
import { ModalWarningComponent } from 'src/app/shared/components/modal/modal-warning/modal-warning.component';
import { RouterService } from 'src/app/core/services/router.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Menu } from 'src/app/core/models/menu.model';

@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {

	public hash: string;
	public loading = false;
	public client: Client;
	public qrcode: Qrcode;
  public clickcollect: ClickCollect;
  public deliveryZone: DeliveryZone;
  public products: Product[];
	public allergenById = {};
	public items: ItemOrderProduct[];
  public cartValues: Product[] = [];
  public totalCart = 0;
  public minOrder = false;
  public deliveryCost = 0;
  public cart: ShopCart;
	public pageType: string;
	public processOrder: ProcessOrder;
  public confirmationMode: boolean = false;
  public confirmationType: ProcessType;
	public comment: string;
  public openPayment: boolean = false;
  public formChoice: FormGroup;
  public has18YearOldItems: boolean = false;
  public formOrder: FormGroup;
  protected subscriptions: Subscription[] = [];
  public status: { [key: string]: any };
  public logged: boolean = false;
  public onSaveOrder: boolean = false;
  public stripeError: boolean = false;
  public is18YearOldError: boolean = false;
	public table: string = 'any';
  public tableError: boolean = false;
  public zones: DeliveryZone[] = [];
  public exceptions: DeliveryZone[] = [];
  public showCustomExceptionMessage: boolean = false;
  public version: string = 'v2';
  public editMode: boolean = false;
  public editProcessOrder: ProcessOrder;
  public selectedProcessOrder: ProcessOrder;

	constructor(
		protected route: ActivatedRoute,
    protected authService: AuthService,
    protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
    protected famillyService: FamillyService,
    protected productService: ProductService,
    protected clickCollectService: ClickCollectService,
    protected allergenService: AllergenService,
    protected shopCartService: ShopCartService,
    protected appStateService: AppStateService,
    protected processService: ProcessService,
    protected modalService: ModalService,
    protected orderService: OrderService,
    protected googleMapService: GoogleMapService,
    protected stripeService: StripeService,
    protected customerService: CustomerService,
    protected navigationService: NavigationService,
    protected tableRuleService: TableRuleService,
    protected deliveryZoneService: DeliveryZoneService,
    protected versionService: VersionService,
    protected routerService: RouterService,
    protected processOrderAdapter: ProcessOrderAdapter,
    protected loaderService: LoaderService,
    protected fb: FormBuilder,
    protected translateService: TranslateService
	) {
    this.table = this.route.parent.snapshot.paramMap.get('table');
		this.hash = this.route.parent.snapshot.paramMap.get('hash');
	}

	ngOnInit() {
    this.loading = true;

    this.formOrder = this.fb.group({
      comment: [''],
      is18YearOld: [false],
    })

    this.processService.isEditMode = false;
    this.appStateService.setMinOrder(this.minOrder);
    this.shopCartService.setTotalCart(this.totalCart);

    this.route.data.subscribe(({pageType}) => {
			this.pageType = pageType
		});

    this.subscriptions.push(this.shopCartService.getShopCart().pipe(
      mergeMap((cart) => {
        this.cart = cart;

        if(!this.loading) {
          this.reloadCart();
        } 

        const productIds = [...new Set(this.cart.items.map(item => item.product && item.product.id))];
        const famillyIds = [...new Set(this.cart.items.map(item => item.product && item.product.famillyId))];

        return forkJoin([
          this.famillyService.has18YearOld(this.hash, famillyIds),
          this.productService.has18YearOld(this.hash, productIds)

        ]).pipe(
          mergeMap(values => of(values.some(e => !!e)))
        )
        
      }
    )).subscribe(e => this.has18YearOldItems = e));

    this.subscriptions.push(this.appStateService.getInOrder().subscribe(order => {
      if(order) {
        if(this.cartLength > 0 && !this.minOrder) 
            this.saveOrder(this.selectedProcessOrder);
       
        this.appStateService.setInOrder(false);
      }
    }));

    this.logged = this.authService.isLoggedIn();

    this.subscriptions.push(this.processService.status().subscribe(status => this.status = status));
    this.subscriptions.push(this.processService.getProcessOrder().subscribe(processOrder => {
      if(this.pageType !== 'in') {
        this.processOrder = processOrder;
        if(this.processOrder) this.resetEditProcess();
      } else this.processOrder = new ProcessOrder(ProcessType.table, null, null, null, null, null, null, null, this.hash); 

      this.selectedProcessOrder = this.processOrder;
    }));

    this.clientService.getClient(this.hash).pipe(
      catchError(_ => {
				this.loading = false;
				return of(null);
			}),
			mergeMap(client => {
				this.client = client;
				return this.qrcodeService.getQrcode(this.hash)
			}),
      mergeMap(qrcode => {
        this.qrcode = qrcode;
        this.clickcollect = qrcode.clickCollect;

				return forkJoin([
          this.deliveryZoneService.getDeliveryZones(this.hash),
          this.deliveryZoneService.getExceptions(this.hash)
        ])
			}),
      mergeMap(([zones, exceptions]) => {
        this.zones = zones;
        this.exceptions = exceptions;
        
        this.formChoice = this.fb.group({
          option: [this.clickcollect.payment ? '1' : '2', Validators.required],
          tablenbr: [!this.hasDefinedTable() ? '1' : this.table.trim(), Validators.required]
        })

        if(this.processOrder && this.processOrder.type === ProcessType.delivery && this.processOrder.deliveryZone) {
          this.processOrder.deliveryZone = this.zones.find(z => z.id === this.processOrder.deliveryZone.id);
          this.processService.save(this.processOrder);
        
        } else {
          if(this.processOrder && this.processOrder.type === ProcessType.delivery && !this.processOrder.deliveryZone) {
            return this.searchDeliveryZone().pipe(
              mergeMap(deliveryZone => {
                if(deliveryZone) this.deliveryZone = deliveryZone;
                this.processOrder.deliveryZone = this.deliveryZone;
                this.reloadCart();

                return this.allergenService.getAllergens(this.hash);
              })
            )
          }
        }
    
        this.reloadCart();

        return this.allergenService.getAllergens(this.hash);
			}),
			finalize(() => { this.loading = false })
			
		).subscribe(allergens => {
      allergens.forEach(allergen => {
        this.allergenById[allergen.id] = allergen;
      });

      this.loading = false;
    })
	}

  resetEditProcess() {
    this.editProcessOrder = this.processOrderAdapter.adapt({hash: this.hash});
    this.editProcessOrder.type = this.processOrder.type;
    this.editProcessOrder.method = this.processOrder.method;
    this.editProcessOrder.deliveryZone = this.processOrder.deliveryZone;
    this.editProcessOrder.address = this.processOrder.address;
  }

  applyMinOrderByZone(zone: DeliveryZone) {
    let minOrder = Math.max(
        0,
        this.qrcode.currencyTableMinOrder, 
        zone.minOrder ? zone.minOrder : 0
    );

    if (this.totalCart < minOrder) {
      this.minOrder = true;
      this.appStateService.setMinOrder(this.minOrder);
    }

    this.appStateService.setMinOrderValue(minOrder);
  }

  applyMinOrderByCC(clickcollect: ClickCollect) {
    let minOrder =  Math.max(0, this.qrcode.currencyTableMinOrder, clickcollect.minOrder);
  
    if (this.totalCart < minOrder) {
      this.minOrder = true;
      this.appStateService.setMinOrder(this.minOrder);
    }

    this.appStateService.setMinOrderValue(minOrder);
  }

  saveProcess() {
    this.processService.save(this.editProcessOrder, true)
    this.reloadCart();
  }

  hasDefinedTable(): boolean {
    return this.table && this.table.trim() !== 'any';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if(sub) sub.unsubscribe();
    })
  }

  changeDeliveryCost(zone: DeliveryZone) {
    this.deliveryCost = zone.fixedDeliveryCost;
  }

  searchDeliveryZone() {
    if(!(this.processOrder && this.processOrder.address)) return of(null);
    
		let currentException: DeliveryZone;
		let selectedDeliveryZones: DeliveryZone[];
		let gResults;

		return this.googleMapService.getCoordsByAddress(`${this.processOrder.address.address}, ${this.processOrder.address.zipcode} ${this.processOrder.address.city}, ${this.processOrder.address.country}`).pipe(
			mergeMap(v => {
				if(v.results 
					&& v.results.length 
					&& v.results[0].geometry 
					&& v.results[0].geometry.location) {

					gResults = v.results[0]

					// Recupère la longitude et latitude pour verifier la zone et recuperer le bon deliveryZone
					const {lat, lng} = gResults.geometry.location;

					currentException = this.exceptions.find(exception => 
						exception.description
						&& exception.description.trim().length > 0 
						&& this.googleMapService.checkDeliveryPosition(exception.delivery, lat, lng)
					);

					selectedDeliveryZones = this.zones.filter(zone => this.googleMapService.checkDeliveryPosition(zone.delivery, lat, lng));
					
					// Si aucune exception avec description trouvé, chercher une sans description
					if(!currentException) 
						currentException = this.exceptions.find(exception => this.googleMapService.checkDeliveryPosition(exception.delivery, lat, lng));

					const valid = !currentException && selectedDeliveryZones.length > 0;
						
					// Si flag avec message et exception avec description - afficher modal
					if(this.showCustomExceptionMessage 
						&& currentException 
						&& selectedDeliveryZones.length > 0 
						&& currentException.description.trim().length > 0) 
						
						this.modalService.open(ModalCustomComponent, {
							title:  this.translateService.instant('globals.warning'),
							intro: currentException.description.trim()

						}, () => {}, null);

					//Vérifie si on peut livrer selon le temps de livraison
					if(valid) 
            return of(selectedDeliveryZones[0]);
          
          return of(null);

				} 

				return of(null)
			})
		)
	}

  reloadCart() {
    this.minOrder = false;
    this.appStateService.setMinOrder(this.minOrder);
    this.totalCart = 0;
    this.items = [];
    this.cartValues = [];
    this.deliveryCost = 0;

    this.cart.items.forEach(item => {
      const price = item.type === ItemType.menu ? parseFloat((item.menu.price as any).replace(',',  '.')) : (item.type === ItemType.both || item.type === ItemType.multiprice 
      ? parseFloat(item.price.price.replace(',',  '.')) : parseFloat(item.product.price.replace(',',  '.')));

      // Verifier si il ya des group options avec des prix
      const addCost = item.type === ItemType.menu 
			  ? item.menuGroupOptions.reduce((acc, curr) => acc + curr.price, 0) 
        + item.menuProducts.reduce((acc, curr) => curr.price ? acc + parseFloat(curr.price.replace(',',  '.')) : acc, 0)
        : (item.type === ItemType.both || item.type === ItemType.groupOption 
          ? item.groupOptions.reduce((acc, curr) => acc + curr.price, 0) : 0);
      
      // Creer un orderId pour chaque entrée
      this.items = [...this.items, new ItemOrderProduct(
        item.type === ItemType.menu ? null : item.product.id,
        item.qt,
        price + addCost,
        item,
        item.type === ItemType.menu ? ItemOrderProductType.menu : ItemOrderProductType.product,
        item.type === ItemType.menu ? null : item.product,
        item.type === ItemType.menu ? item.menu : null
      )]

      // Incrementer la total Cart
      this.totalCart += (price + addCost) * item.qt;
      this.shopCartService.setTotalCart(this.totalCart);
      
    });
   
    if(this.selectedProcessOrder 
      && this.selectedProcessOrder.type 
      && this.selectedProcessOrder.type === ProcessType.clickgo
    ) {
      this.applyMinOrderByCC(this.clickcollect)
  
    } else if(this.selectedProcessOrder 
      && this.selectedProcessOrder.type 
      && this.selectedProcessOrder.type === ProcessType.delivery
      && this.selectedProcessOrder.deliveryZone
    ) { 
      this.applyMinOrderByZone(this.selectedProcessOrder.deliveryZone)

    } else {
      let minOrder = Math.max(0, this.qrcode.currencyTableMinOrder)

      if (this.totalCart < minOrder) {
        this.minOrder = true;
        this.appStateService.setMinOrder(this.minOrder);
      }

      this.appStateService.setMinOrderValue(minOrder);

    }
    
    if(this.selectedProcessOrder 
      && this.selectedProcessOrder.type 
      && this.selectedProcessOrder.type === ProcessType.delivery
      && this.selectedProcessOrder.deliveryZone
      && this.selectedProcessOrder.deliveryZone.fixedDeliveryCost
    ) {

      if(this.selectedProcessOrder.deliveryZone.freeDelivery == null || this.selectedProcessOrder.deliveryZone.freeDelivery == 0) {
        this.changeDeliveryCost(this.selectedProcessOrder.deliveryZone);

      } else if(this.selectedProcessOrder.deliveryZone.freeDelivery) {
        if (this.totalCart < this.selectedProcessOrder.deliveryZone.freeDelivery && this.selectedProcessOrder.deliveryZone.fixedDeliveryCost > 0 && !this.minOrder){
          this.changeDeliveryCost(this.selectedProcessOrder.deliveryZone);
        }
      }
    }
  }

  syncItemOrders(products: Product[], menus: Menu[]): ShopItem[] {
    // Piocher les produit de la famille (garder que les disponibles)
    return this.cart.items.filter(item => {
      const foundProduct = products.find(({id}) => item.product && item.product.id === id);
      const foundMenu = menus.find(({id}) => item.menu && item.menu.id === id);

      if(!(foundProduct || foundMenu)) return false;

      return foundMenu ? this.compareMenu(foundMenu, item) : this.compareProduct(foundProduct, item);
    });
  }

  compareProduct(product: Product, item: ShopItem) {
    const goIds = product.groupOptions.map(({id}) => id);
    
    switch(item.type) {
      case ItemType.both:
        if(item.price
          && item.groupOptions 
          && item.groupOptions.length > 0) {
            return product.prices.find(({id}) => item.price.id === id) && item.groupOptions.every(({id}) => goIds.includes(id));
          }
        break;

      case ItemType.groupOption:
        if(!item.price 
          && item.groupOptions 
          && item.groupOptions.length > 0) {
            return product.groupOptions.every(({id}) => goIds.includes(id));
        } 
        break;

      case ItemType.multiprice:
        if(item.price
          && !(item.groupOptions 
            && item.groupOptions.length > 0)) {
              return product.prices.find(({id}) => item.price.id === id);
        }  
        break;

      default:
        if(!item.price
          && !(item.groupOptions 
            && item.groupOptions.length > 0)) return true;
      
      return false;
        
    }
  }

  compareMenu(menu: Menu, item: ShopItem) {
    const {productIds, groupOptionIds} = menu.steps.reduce((acc, curr) => {
			const groupOptions = curr.products.reduce((acc, curr) => [...acc, ...curr.menuGroupOptions], []).map(({id}) => id)
			return {
        productIds: [...acc.productIds, ...curr.products.map(({id}) => id)], 
        groupOptionIds: [...acc.groupOptionIds, ...groupOptions]};
		}, { productIds: [], groupOptionIds: [] })
    
    if(item.menuProducts && item.menuProducts.length > 0){
      const hasProducts = item.menuProducts.every(({id}) => productIds.includes(id));
      if(!hasProducts) return false;
      return item.menuGroupOptions && item.menuGroupOptions.length > 0 ? item.menuGroupOptions.every(({id}) => groupOptionIds.includes(id)) : true
    } 

    return false;
  }

  syncValidCart(shopItems: ShopItem[]) {
    return () => {
      this.shopCartService.save(shopItems);
    }
  }

  doNothing() {}

  closeConfirmationOverlay(): void {
		this.confirmationMode = false;
	}

  openConfirmationOverlay(): void {
		this.confirmationMode = true;
    if(this.pageType === 'in') this.navigationService.setPage(NavigationPage.tableConfirmation)
    else  this.navigationService.setPage(NavigationPage.confirmation)
	}

  getNameOfMenuProduct(productId: number, item: ItemOrderProduct) {
    const product = item.shopItem.menuProducts.find(p => p.id === productId);

    if(!product) return '';
    if(!(product.menuGroupOptions && product.menuGroupOptions.length)) return product.name;

    const goInProduct = product.menuGroupOptions.map(go => go.id);
    const options = item.shopItem.menuGroupOptions.filter(opt => goInProduct.includes(opt.id));

    return product.name + (options && options.length ? ' | ' + options.map(({name}) => name).join(' | ') : '');
  }

  getOrderRequest(processOrder: ProcessOrder) {
    let deliveryCosts = this.deliveryCost > 0 && processOrder && processOrder.type === ProcessType.delivery ? this.deliveryCost : 0;
    
    const items = this.items.map(e => {
      const shopItem = e.shopItem;

      switch(shopItem.type) {
        case ItemType.both:
          return {
            id: shopItem.product.id,
            name: shopItem.product.name + ' | ' + shopItem.price.name + ' | ' + shopItem.groupOptions.map(e => e.name).join(' | '), 
            unit_price: e.price, 
            price: (e.qt * e.price), 
            qt: e.qt,
            type: shopItem.type,
            menuProducts: [] as number[],
            groupOptions: shopItem.groupOptions.map(e => e.id),
            menuGroupOptions: [],
            productId: shopItem.product.id,
            priceId: shopItem.price.id
          }
        case ItemType.groupOption:
          return {
            id: shopItem.product.id,
            name: shopItem.product.name + ' | ' + shopItem.groupOptions.map(e => e.name).join(' | '), 
            unit_price: e.price, 
            price: (e.qt * e.price), 
            qt: e.qt,
            type: shopItem.type,
            menuProducts: [] as number[],
            groupOptions: shopItem.groupOptions.map(e => e.id),
            menuGroupOptions: [],
            productId: shopItem.product.id,
            priceId: null
          }
        case ItemType.multiprice:
          return {
            id: shopItem.product.id,
            name: shopItem.product.name + ' | ' + shopItem.price.name, 
            unit_price: e.price, 
            price: (e.qt * e.price), 
            qt: e.qt,
            type: shopItem.type,
            menuProducts: [] as number[],
            groupOptions: [],
            menuGroupOptions: [],
            productId: shopItem.product.id,
            priceId: shopItem.price.id
          }
        case ItemType.menu:
          return {
            id: shopItem.menu.id,
            name: shopItem.menu.name, 
            unit_price: e.price, 
            price: (e.qt * e.price), 
            qt: e.qt,
            type: shopItem.type,
            menuProducts: shopItem.menuProducts.map(e => e.id),
            groupOptions: [],
            menuGroupOptions: shopItem.menuGroupOptions.map(e => e.id),
            menuId: shopItem.menu.id,
          }
        default:
          return {
            id: shopItem.product.id,
            name: shopItem.product.name, 
            unit_price: e.price, 
            price: (e.qt * e.price), 
            qt: e.qt,
            type: ItemType.simple,
            menuProducts: [] as number[],
            groupOptions: [],
            menuGroupOptions: [],
            productId: shopItem.product.id,
            priceId: null,
          }
      }

    });

    const status = this.pageType === 'in' && this.formChoice.value.option === '2' 
      ? 'not-paid' : (this.pageType === 'in' 
        ? 'paid' : (this.clickcollect.payment ? 'paid' : 'not-paid'))

    const orderAt = processOrder && processOrder.orderAt ?  processOrder.orderAt : null;

    const details = new OrderDetailsRequest(
      null, 
      null, 
      null, 
      this.hash,
      this.getCurrency(this.qrcode),
      processOrder && processOrder.type === ProcessType.clickgo ? processOrder.collectionPoint.name : null,
      processOrder && processOrder.type === ProcessType.clickgo ? processOrder.collectionPoint.deliveryZone : null,
      processOrder && processOrder.type === ProcessType.delivery ? processOrder.address.address + ', ' + processOrder.address.zipcode + ' ' + processOrder.address.city +', '+ processOrder.address.country : null,
      this.pageType === 'in' ? this.formChoice.value.tablenbr : null,
      orderAt
    )
    
    const transformedItems = items.map((item, i) => new OrderItemRequest(
      item.name, 
      item.unit_price, 
      item.price, 
      item.qt, 
      this.getCurrency(this.qrcode), 
      item.type, 
      item.productId, 
      item.menuId, 
      item.priceId, 
      item.menuProducts && item.menuProducts.length ? item.menuProducts.map(id => ({id, name: this.getNameOfMenuProduct(id, this.items[i])})) : [],
      item.groupOptions,
      item.menuGroupOptions
    ))

    const newOrder = new OrderRequest(
      this.authService.getCustomerId(),
      this.client.id,
      this.qrcode.id,
      OrderStatus.open,

      JSON.stringify({
        type: processOrder && processOrder.type ? processOrder.type : ProcessType.table,
        method: processOrder && processOrder.method ? processOrder.method : ProcessMethod.now,
        orderAt: processOrder && processOrder.orderAt ?  processOrder.orderAt : null,
        status
      }),

      this.formOrder.value.comment,
      processOrder && processOrder.type === ProcessType.delivery ? processOrder.address.address + ', ' + processOrder.address.zipcode + ' ' + processOrder.address.city +', '+ processOrder.address.country : '',
      deliveryCosts.toString(),
      '',
      processOrder && processOrder.type ? processOrder.type : ProcessType.table,
      processOrder && processOrder.type === ProcessType.clickgo ? 1 : 0,
      this.totalCart + (processOrder && processOrder.type === ProcessType.delivery ? this.deliveryCost : 0),
      this.pageType === 'in' ? this.formChoice.value.tablenbr : null,
      this.authService.getCustomerEmail(),
      details,
      transformedItems,
      null,
      null,
      this.getStripeCurrency(this.qrcode),
      orderAt
    );

    return newOrder;
  }

  changeEditMode(editMode: boolean) {
    this.editMode = editMode;
    this.selectedProcessOrder = this.editMode ? this.editProcessOrder : this.processOrder;
    this.processService.isEditMode = this.editMode;
    if(!this.editMode) this.resetEditProcess();
    if(this.editMode) this.processService.save(this.editProcessOrder, true)
    this.reloadCart();
  }
  
  saveToDB(processOrder: ProcessOrder) {
    const order = this.getOrderRequest(processOrder);

    if(order.customerId) {
      return this.orderService.save(this.hash, order).pipe(
        mergeMap(value => {
          if(processOrder 
            && processOrder.type 
            && processOrder.type === ProcessType.delivery 
            && (processOrder.address && !processOrder.address.id)
          ) {
            const {address} = processOrder;
            const body = new CustomerAddressRequest(
              address.name ?? '',
              address.address ?? '',
              address.zipcode ?? '',
              address.city ?? '',
              address.country ?? '', 
              address.longitude ?? '',
              address.latitude ?? '',
              false,
              this.authService.getCustomerId()
            )

            return this.customerService.addAddress(body);
          }
  
          return of(value);
        })
      )
    } else 
      return this.orderService.saveWthCustomer(this.hash, order)
  }

  getCurrency(qrcode: Qrcode) {
		if(!qrcode) return '';
		return qrcode.currencyTableValue || qrcode.currency;
	}

  makePayment(processOrder: ProcessOrder, order: OrderRequest) {
    if(!this.openPayment) {
      this.stripeError = false;
      this.openPayment = true;

      const errorHandling = () => {
        this.stripeError = true;
        return of(false)
      };
      
      this.stripeService.makePayment(
        this.hash,
        order, 
        this.client.name,
        this.clickcollect.paymentInfo,
        errorHandling

      ).pipe(
        catchError(errorHandling),
        mergeMap((e: any) => {

          if(e) {
            if(processOrder 
              && processOrder.type 
              && processOrder.type === ProcessType.delivery 
              && (processOrder.address && !processOrder.address.id)
            ) {
              const {address} = processOrder;
              const body = new CustomerAddressRequest(
                address.name ?? '',
                address.address ?? '',
                address.zipcode ?? '',
                address.city ?? '',
                address.country ?? '', 
                address.longitude ?? '',
                address.latitude ?? '',
                false,
                this.authService.getCustomerId()
              )

              return this.customerService.addAddress(body);
            }

            return of(true);

          } else {
            this.stripeError = true;
          }

          return of(null);
          
      })
      
      
      ).subscribe(e => {
        if(e) {
          this.openConfirmationOverlay();
          this.shopCartService.empty();
          this.confirmationType = processOrder && processOrder.type ? processOrder.type : ProcessType.table;
          this.processService.removeProcessOrder();
          this.processService.isEditMode = false;

        }
        
      })
    }
  }

  getStripeCurrency(qrcode: Qrcode) {
    return qrcode.stripeCurrency;
  }
  
  checkDelivery(clickcollect: ClickCollect, processOrder: ProcessOrder, orderAt: string, checkTime: boolean = true) {
    let currentException: DeliveryZone;
		let selectedDeliveryZones: DeliveryZone[];

    const dt = processOrder.deliveryZone && processOrder.deliveryZone.deliveryTime ? processOrder.deliveryZone.deliveryTime : 0;
    const man = clickcollect.manufacturingTime ? clickcollect.manufacturingTime : 0;

    return this.clickCollectService.canDeliver(
      this.hash, 
      checkTime ? man + dt : 0,
      false,
      orderAt ? new Date(orderAt) : new Date(), 
      
    ).pipe(
      mergeMap(value => {
        if (!value) {
          this.modalService.open(ModalCustomComponent, {
            title: this.translateService.instant('globals.warning'),
            intro: this.translateService.instant(
              processOrder.method === ProcessMethod.now ? 'v2.cart.errors.delivery_now' : 'v2.cart.errors.delivery_date'
            )

          }, () => {}, null);

          return of(false);

        } else if(!processOrder.address) {
          this.modalService.open(ModalCustomComponent, {
            title:  this.translateService.instant('globals.warning'),
            intro: this.translateService.instant('v2.cart.errors.select_address')

          }, () => {}, null);

          return of(false);
        }

        return this.googleMapService.getCoordsByAddress(`${processOrder.address.address}, ${processOrder.address.zipcode} ${processOrder.address.city}, ${processOrder.address.country}`).pipe(
            mergeMap(v => {
              if (!v) return of(false)
              if (v.results && v.results.length && v.results[0].geometry && v.results[0].geometry.location) {
                const {lat, lng} = v.results[0].geometry.location;

                currentException = this.exceptions.find(exception => 
                  exception.description
                  && exception.description.trim().length > 0 
                  && this.googleMapService.checkDeliveryPosition(exception.delivery, lat, lng)
                );

                selectedDeliveryZones = this.zones.filter(zone => this.googleMapService.checkDeliveryPosition(zone.delivery, lat, lng));

                if(!currentException) 
                  currentException = this.exceptions.find(exception => this.googleMapService.checkDeliveryPosition(exception.delivery, lat, lng));

                return of(!currentException && selectedDeliveryZones.length > 0)
              }
              return of(false);
            }),
            tap(v => {
              if(this.showCustomExceptionMessage 
                && currentException 
                && selectedDeliveryZones.length > 0 
                && currentException.description.trim().length > 0) 
                this.modalService.open(ModalCustomComponent, {
                  title:  this.translateService.instant('globals.warning'),
                  intro: currentException.description.trim()

                }, () => {}, null);

              else if(!v) this.modalService.open(ModalCustomComponent, {
                title: this.translateService.instant('globals.warning'),
                intro: this.translateService.instant('v2.cart.errors.address')

              }, () => {}, null);
            })
          );
      })
    )
  }

  checkClickGo(clickcollect: ClickCollect, orderAt: string, manu: boolean = true) {
    return this.clickCollectService.getCollectionPointsAt(
      this.hash, 
      manu ? clickcollect.manufacturingTime : 0,
      orderAt ? new Date(orderAt) : new Date()
    );
  }

  hasTableFn(table) {
    return (rule) => {
      if(rule.valueType === 'rng') {
        if(typeof(table) === 'string' && !/^\s*[0-9]+\s*$/.test(table)) return false;

        const parsed = parseInt(table);
          if(!parsed) return false;
          return parsed >= rule.beginValue && parsed <= rule.endValue;
      }
      else if(rule.valueType === 'csv') {
          const values = rule.values.split(';');
          if(!values || !values.length) return false
          return values.some(value => value.trim().toLowerCase() === table.trim().toLowerCase());
      }
      
      return rule.values.trim().toLowerCase() === table.trim().toLowerCase()
    }
  }

  saveOrder(processOrder: ProcessOrder) {
    if(this.onSaveOrder) return;

    const rid = `${Math.random()}`;
    this.loaderService.add(rid);

    this.tableError = false;
    this.openPayment = false;
    this.onSaveOrder = true;
    this.is18YearOldError = false;

    if(this.pageType === 'in' && this.formChoice.invalid) {
      this.onSaveOrder = false;
      this.loaderService.remove(rid);
      
      return;
    }

    if(this.pageType === 'in' && this.formChoice.value.option === '1' && !this.logged) {
      this.appStateService.redirectAfterLoginOnOrder = true;
      this.loaderService.remove(rid);
      this.routerService.navigate(['/', this.version, 'profile', this.hash, 'in', this.table, 'account'])
      return;
    }

    if(this.has18YearOldItems && !this.formOrder.value.is18YearOld) {
      this.onSaveOrder = false;
      this.is18YearOldError = true;
      this.loaderService.remove(rid);
      return;
    }

    const orderAt = processOrder && processOrder.orderAt ? processOrder.orderAt : null;
    const bObser$ = processOrder.type === ProcessType.delivery ? forkJoin([
      this.deliveryZoneService.getDeliveryZones(this.hash),
      this.deliveryZoneService.getExceptions(this.hash)
    
    ]) : of([]);

    bObser$.pipe(
      catchError(_ => {
        this.onSaveOrder = false;
        this.loaderService.remove(rid);
        return of(null)
      }),
      mergeMap(values => {
        if(values.length) {
          this.zones = values[0];
          this.exceptions = values[1];
        }
       
        return this.clickCollectService.getClickCollect(this.hash)
      }),
      mergeMap(cc => {
        this.clickcollect = cc;
        const fn = this.hasTableFn(this.formChoice.value.tablenbr);

        if(this.pageType === 'in')
          return forkJoin(
            [
              this.tableRuleService.getExceptions(this.hash),
              this.tableRuleService.getTableRules(this.hash)
              
            ]).pipe(
            mergeMap(([exceptions, tableRules]) => {
              let valid = true;

              if(!(this.hasDefinedTable() && this.table.trim() == this.formChoice.value.tablenbr) 
                && exceptions.length > 0) valid = !exceptions.some(fn);
              
              if(valid && tableRules.length > 0) valid = tableRules.some(fn);
              else valid = false

              if(!valid) {
                this.tableError = true;
                return throwError(() => new Error('Invalid table'));
              }

              return this.famillyService.getFamilies(this.hash, true)
            })
          )
  
        else if (processOrder.type === ProcessType.delivery) {
          return this.checkDelivery(this.clickcollect, processOrder, orderAt, orderAt ? false : true).pipe(
            mergeMap(v => {
              if (v) return this.famillyService.getClickCollectFamilies(this.hash, true, orderAt);
              return of([]);

            })
          )
  
        } else if (processOrder.type === ProcessType.clickgo) {
          if(!processOrder.collectionPoint) return of([]);
          return this.checkClickGo(this.clickcollect, orderAt, orderAt ? false : true).pipe(
            mergeMap(cps => {
              const selectedCp = cps.find(cp => processOrder.collectionPoint.id === cp.id)
              if(!selectedCp) {
                this.modalService.open(ModalCustomComponent, {
                  title:  this.translateService.instant('globals.warning'),
                  intro: this.translateService.instant('v2.cart.errors.cp')
      
                }, () => {}, null);
      
                return of([]);
              }
      
              return this.famillyService.getClickCollectFamilies(this.hash, true, orderAt);
            })
          )
        } 

        return of([]);
      }),
      mergeMap(families => {
        if(!(families && families.length > 0)) return of(false);
        return forkJoin((families as any[]).map(({id}) => this.famillyService.getFamilly(this.hash, id, true, orderAt)))
      }),
      finalize(() => {
        this.loaderService.remove(rid);
        this.onSaveOrder = false
      })
  
    ).subscribe((families: any) => {
      const products = families && families.length > 0 
        ? families.reduce((acc, curr) => [...acc, ...curr.products], []) : [];
      
      const menus = families && families.length > 0 
        ? families.reduce((acc, curr) => [...acc, ...curr.menus], []) : [];

      // On vérifie l'activation des produits selon la plage d'horaire
      const avitems = this.syncItemOrders(products, menus);
      const unavailables = this.cart.items.filter(i => !avitems.map(({id}) => id).includes(i.id));
  
      if (unavailables.length > 0) {
        this.modalService.open(ModalWarningComponent, {
          shopItems: unavailables
          
        }, this.syncValidCart(avitems), () => this.doNothing);
  
      } else {
        if(this.pageType === 'in') {
          if(this.totalCart < this.qrcode.currencyTableMinOrder) {
            this.onSaveOrder = false;
            this.loaderService.remove(rid);
            return;
          }

          const {option} = this.formChoice.value;

          if(option === '1' && this.clickcollect.payment) this.makePayment(null, this.getOrderRequest(null));
          else  {
            this.saveToDB(null).subscribe(e => {
              this.openConfirmationOverlay();
              this.shopCartService.empty();
              this.confirmationType = ProcessType.table;
              this.processService.removeProcessOrder();
              this.processService.isEditMode = false;

            });
            
          }

        } else if(processOrder) {
          if(this.totalCart < Math.max(0, this.qrcode.currencyTableMinOrder, this.appStateService.getMinOrderValue())) {
            this.onSaveOrder = false;
            this.loaderService.remove(rid);
            return
          }

          // CHECK LOGIN
          if (this.logged) {
            // ON PROCEDE AU PAIEMENT
            if (!this.clickcollect.payment) {
              this.saveToDB(processOrder).subscribe(e => {
                this.openConfirmationOverlay();
                this.shopCartService.empty();
                this.confirmationType = processOrder.type;
                this.processService.removeProcessOrder();
                this.processService.isEditMode = false;

              });
        
            } else this.makePayment(processOrder, this.getOrderRequest(processOrder));
          } 
        } 
      }
      
      

      this.onSaveOrder = false;
      this.loaderService.remove(rid);
    })
    
  }

  get cartLength() {
    return this.shopCartService.length();
  }
}

