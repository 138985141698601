import { Menu } from "./menu.model";
import { Product } from "./product.model";

export class Family {

    id: number;
    position: number;
    icon: string;
    name: string;
    scheduling: string;
    isScheduled: boolean;
    is18YearOld: boolean;
    availability: boolean;
    products: Product[];
    menus: Menu[];

    constructor(id: number, position: number, icon: string, name: string, scheduling: string, isScheduled: boolean, is18YearOld: boolean, availability: boolean, products: Product[], menus: Menu[]) {
        this.id = id;
        this.icon = icon;
        this.position = position;
        this.name = name;
        this.scheduling = scheduling;
        this.isScheduled = isScheduled;
        this.is18YearOld = is18YearOld;
        this.availability = availability;
        this.products = products;
        this.menus = menus;
    }
}