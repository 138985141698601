import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerAddressAdapter } from '../../models/adapter/customer-address.adapter';
import { CustomerAddressRequest } from '../../models/requests/customer-address.request';
import { CustomerAddress } from '../../models/customer-address.model';
import { ApiUrl } from './api.url';
import { CustomerAdapter } from '../../models/adapter/customer.adapter';
import { Customer } from '../../models/customer.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
	
    constructor(
        protected http: HttpClient,
        protected customerAdapter: CustomerAdapter,
        protected customerAddressAdapter: CustomerAddressAdapter

    ) {}

    getCustomer(customerId: number): Observable<Customer> {
        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_CUSTOMER}`.replace(':id', customerId.toString())).pipe(
            map((response: any) => {
                return response ? this.customerAdapter.adapt(response) : null;
            }),
        );
    }

    getAddresses(customerId: number): Observable<CustomerAddress[]> {
        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_CUSTOMER}`.replace(':id', customerId.toString())).pipe(
            map((response: any) => {
                return response && response.addresses ? response.addresses.map(item => this.customerAddressAdapter.adapt(item)) : [];
            }),
        );
    }

    addAddress(address: CustomerAddressRequest): Observable<CustomerAddress> {
        return this.http.post(`${environment.baseUrl}${ApiUrl.ADD_ADDRESS}`, address).pipe(
            map((response: any) => {
                return response && this.customerAddressAdapter.adapt(response);
            }),
        );
    }

    editAddress(id: number, address: CustomerAddressRequest): Observable<CustomerAddress> {
        return this.http.put(`${environment.baseUrl}${ApiUrl.EDIT_ADDRESS}`.replace(':id', id.toString()), address).pipe(
            map((response: any) => {
                return response && this.customerAddressAdapter.adapt(response);
            }),
        );
    }

    deleteAddress(id: number): Observable<any> {
        return this.http.delete(`${environment.baseUrl}${ApiUrl.DELETE_ADDRESS}`.replace(':id', id.toString()));
    }

}
