import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export enum NavigationPage {
  qrcode = 'qrcode',
  familly = 'familly',
  product = 'product',
  cardList = 'card-list',

  cc = 'cc',
  ccSelection = 'cc-selection',
  ccFamilly = 'cc-familly',
  ccProduct = 'cc-product',
  ccCardList = 'cc-card-list',
  ccOrder = 'cc-order',

  table = 'table',
  tableSelection = 'table-selection',
  tableFamilly = 'table-familly',
  tableProduct = 'table-product',
  tableCardList = 'table-card-list',
  tableOrder = 'table-order',
  letsgo = 'letsgo',

  history = 'history',
  notFound = '404',
  account = 'account',
  register = 'register',
  info = 'info',
  login = 'login',
  confirmation = 'confirmation',
  tableConfirmation = 'table-confirmation',

  other = 'other',
}

@Injectable({
  providedIn: "root"
})
export class NavigationService {

  private $page: BehaviorSubject<NavigationPage>;
  private $table: BehaviorSubject<string>;
  private $data: BehaviorSubject<{[key: string]: any}>;

  constructor() {
    this.$page = new BehaviorSubject<NavigationPage>(null);
    this.$table = new BehaviorSubject<NavigationPage>(null);
    this.$data = new BehaviorSubject<{[key: string]: any}>(null);
  }

  setPage(page: NavigationPage) {
    this.$page.next(page);
  }

  setTable(table: string) {
    this.$table.next(table);
  }

  getPage(): Observable<NavigationPage> {
    return this.$page.asObservable();
  }

  getTable(): Observable<string> {
    return this.$table.asObservable();
  }

  setData(data: {[key: string]: any}) {
    this.$data.next(data);
  }

  getData(): Observable<{[key: string]: any}> {
    return this.$data.asObservable();
  }

  getBaseUrl(page?: NavigationPage, version: string = "v2"): string[] {
    const value = page ?? this.$page.value;
    const pages = {
      'profile' : [
        NavigationPage.account,
        NavigationPage.register,
        NavigationPage.info,
        NavigationPage.login
      ],
      'qrcode' : [
        NavigationPage.qrcode,
        NavigationPage.familly,
        NavigationPage.product,
        NavigationPage.cardList
      ],
      'click-go' : [
        NavigationPage.cc,
        NavigationPage.ccSelection,
        NavigationPage.ccFamilly,
        NavigationPage.ccProduct,
        NavigationPage.ccCardList,
        NavigationPage.ccOrder,
        NavigationPage.history
      ],
      'in' : [
        NavigationPage.table,
        NavigationPage.tableSelection,
        NavigationPage.tableFamilly,
        NavigationPage.tableProduct,
        NavigationPage.tableCardList,
        NavigationPage.tableOrder

      ]
    }
    return Object.keys(pages).reduce((acc, curr) => pages[curr].some(v => value === v) && !acc.length ? ['/', version, curr] : acc ,[])
  }
}
