import { TranslateService } from "@ngx-translate/core";
import { TimeSlot } from "./time-slot.model";

export enum OpeningHoursType {
    regular = 'regular',
    occasional = 'occasional',
    event = 'event',
}

export enum OpeningHoursShowOption {
    never = 'never',
    always = 'always',
    date = 'date'
}

export const dayTable = [
    {name: 'monday', value: 1, index: 1},
    {name: 'tuesday', value: 2, index: 2},
    {name: 'wednesday', value: 4, index: 3},
    {name: 'thursday', value: 8, index: 4},
    {name: 'friday', value: 16, index: 5},
    {name: 'saterday', value: 32, index: 6},
    {name: 'sunday', value: 64, index: 7}
]

export const monthTable = [
    {name: 'january', value: 1},
    {name: 'february', value: 2},
    {name: 'march', value: 3},
    {name: 'april', value: 4},
    {name: 'may', value: 5},
    {name: 'june', value: 6},
    {name: 'july', value: 7},
    {name: 'august', value: 8},
    {name: 'septembers', value: 9},
    {name: 'october', value: 10},
    {name: 'november', value: 11},
    {name: 'december', value: 12}
]


export const typeTable = [
    {label: 'Régulier', name: 'regular'},
    {label: 'Ponctuel', name: 'occasional'},
    {label: 'Évènement', name: 'event'}
];

export class OpeningHours {
	id: number;
    title: string;
    description: string;
    days: number;
    full24h: boolean;
    type: OpeningHoursType;
    icon: string;
    close: boolean;
    delivery: boolean;
    clickGo: boolean;
    showOption: OpeningHoursShowOption;
    showAt: Date;
    activeAt: Date;
    beginAt: Date;
    endAt: Date;
    timeSlots: TimeSlot[];

	constructor(
		id: number,
        title: string,
        description: string,
        days: number,
        full24h: boolean,
        type: string,
        icon: string,
        close: boolean,
        delivery: boolean,
        clickGo: boolean,
        showOption: OpeningHoursShowOption,
        showAt: Date,
        activeAt: Date,
        beginAt: Date,
        endAt: Date,
        timeSlots: TimeSlot[]

	) {
		this.id = id;
        this.title = title;
        this.description = description;
        this.days = days;
        this.full24h = full24h;
        this.type = OpeningHoursType[type];
        this.icon = icon;
        this.close = close;
        this.delivery = delivery;
        this.clickGo = clickGo;
        this.showOption = OpeningHoursShowOption[showOption];
        this.showAt = showAt;
        this.activeAt = activeAt;
        this.beginAt = beginAt;
        this.endAt = endAt;
        this.timeSlots = timeSlots;
	}

    public getDaysToString(translateService: TranslateService, pref: string = 'v2.components.footer.opening_hours') {
        let days = dayTable.reduce((acc, curr) => curr.value & this.days ? [...acc, curr.name] : acc, []) 
        let suite = dayTable.reduce((acc, curr, i) => {
            const hasDay = days.includes(curr.name);
            const al = acc.length;

            if((al === 0 || i === 0) && hasDay) return [[curr.name]]
            if(hasDay) {
                const currArr = acc[al - 1];
                if(currArr[currArr.length - 1] === dayTable[i-1].name) {
                    acc[al - 1].push(curr.name)
                    return acc;

                } else return [...acc, [curr.name]]
            }

            return acc;

        }, [])
        
        if(suite.length < 2){
            suite = suite.map(v => {
                if(v.length === 7) return translateService.instant(`${pref}.all_days`)

                const mapped =  v.map(e => translateService.instant(`${pref}.${e}`));

                return v.length === 2 
                    ? translateService.instant(`${pref}.days.d1`, {d1: mapped[0], d2: mapped[1]}) 
                    : (v.length > 2 
                        ? translateService.instant(`${pref}.days.d0`, {d1: mapped[0], d2: mapped[mapped.length - 1]}) 
                        : mapped[0])
            })
        } else {
            suite = [suite.reduce((acc, curr) => [...acc, ...curr])]
                .map(v => v.length === 7 
                    ? translateService.instant(`${pref}.all_days`) 
                    : v.map(e => translateService.instant(`${pref}.${e}`)).join(v.length < 3 ? ' ' + translateService.instant(`${pref}.and`) + ' ' : ', '))
        }

        // First letter
        return suite.map(e => e.charAt(0).toUpperCase() + e.slice(1));
    }

    public getHoursToString(translateService: TranslateService, pref: string = 'v2.components.footer.opening_hours') {
        if(this.full24h) return [translateService.instant(`${pref}.full24h`)]

        const edd_st = this.close ? 'closed_' : '';

        const mapped = this.timeSlots
                .map(v => translateService.instant(`${pref}.timeslot.ts0`, {t1: v.beginAt, t2: v.endAt}))
                .sort((a, b) => a < b ? -1 : (a > b ? 1 : 0))

        return mapped.length === 2 
            ? [translateService.instant(`${pref}.timeslot.${edd_st}ts2`, {t1: mapped[0], t2: mapped[1]})]
            : (mapped.length === 1 
                ? [translateService.instant(`${pref}.timeslot.${edd_st}ts1`, {t: mapped[0]})] 
                : [translateService.instant(`${pref}.timeslot.${edd_st}ts1`, {t: mapped.join(', ')})])
            
    }
}
