import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { TableRuleService } from 'src/app/core/services/api/table-rule.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
	selector: 'app-modal-change-table',
	templateUrl: './modal-change-table.component.html',
	styleUrls: ['./modal-change-table.component.scss']
})
export class ModalChangeTableComponent  {

	public tableError: boolean = false;

	@Input() tempTableForm: FormGroup;
	@Input() tableForm: FormGroup;
	@Input() confirmText: string;
	@Input() cancelText: string;
	@Input() hash: string;

	constructor(
		protected tableRuleService: TableRuleService,
		protected modalService: ModalService,
		protected fb: FormBuilder
	) {}

	hasTableFn(table) {
		return (rule) => {
		  if(rule.valueType === 'rng') {
			if(typeof(table) === 'string' && !/^\s*[0-9]+\s*$/.test(table)) return false;

			const parsed = parseInt(table);
			  if(!parsed) return false;
			  return parsed >= rule.beginValue && parsed <= rule.endValue;
		  }
		  else if(rule.valueType === 'csv') {
			  const values = rule.values.split(';');
			  if(!values || !values.length) return false
			  return values.some(value => value.trim().toLowerCase() === table.trim().toLowerCase());
		  }
		  
		  return rule.values.trim().toLowerCase() === table.trim().toLowerCase()
		}
	}

	confirm(e) {
		e.preventDefault();

		if(this.tempTableForm.invalid) return;

		const values = this.tempTableForm.getRawValue()

		const fn = this.hasTableFn(values.tablenbr);

		forkJoin([
			this.tableRuleService.getExceptions(this.hash),
			this.tableRuleService.getTableRules(this.hash)
			
		]).subscribe(([exceptions, tableRules]) => {
			let valid = true;
			
			if(exceptions.length > 0) valid = !exceptions.some(fn);

			if(valid && tableRules.length > 0) valid = tableRules.some(fn);
			else valid = false

			if(!valid) {
				this.tableError = true;
				throw new Error('Invalid table');
			}

			this.tableForm.patchValue({tablenbr: this.tempTableForm.value.tablenbr})
			this.modalService.fireConfirm();
		})
	}

	cancel() {
		this.modalService.fireCancel()
	}
}
