import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProcessOrder } from 'src/app/core/models/process-order.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { NavigationPage } from 'src/app/core/services/navigation.service';
import { ProcessService } from 'src/app/core/services/process.service';
import { ShopCartService } from 'src/app/core/services/shop-cart.service';
import { VersionService } from 'src/app/core/services/api/version.service';
import { RouterService } from 'src/app/core/services/router.service';

@Component({
	selector: 'app-footer-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})
export class FooterAccountComponent implements OnInit, OnDestroy {

  processOrder: ProcessOrder;
  processSub: Subscription;

  public version: string = 'v2';

  @Input() hash: string;
  @Input() open: boolean = false;
  @Input() page: NavigationPage;
  @Input() pageType: string = 'click-go';
  @Input() data: {[key: string]: any};

  @Output() onOpenRegisterOverlay: EventEmitter<void>;
  @Output() onCloseAccount: EventEmitter<void>;
  @Output() onLogout: EventEmitter<void>;

  constructor(
		protected route: ActivatedRoute,
		protected routerService: RouterService,
		protected shopCartService: ShopCartService,
    protected authService: AuthService,
    protected versionService: VersionService,
    protected processService: ProcessService
	) {

    this.onOpenRegisterOverlay = new EventEmitter<void>();
    this.onCloseAccount = new EventEmitter<void>();
    this.onLogout = new EventEmitter<void>();

  }

  ngOnInit() { 
    this.processSub = this.processService.getProcessOrder().subscribe(processOrder => this.processOrder = processOrder);
  }

  ngOnDestroy(): void {
    this.processSub.unsubscribe()
  }

  public logout(): void {
    this.onLogout.emit();
	}

  public reloadPage() {
    this.routerService.getRouter().navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.routerService.navigate(['/', this.version, 'click-go', this.hash]);
    });
  }

  public closeAccount(): void {
    this.onCloseAccount.emit();
  }

  public openRegisterOverlay(): void {
    this.onCloseAccount.emit();
    this.onOpenRegisterOverlay.emit();
  }

  public resetCart(): void {
		this.shopCartService.empty();
    this.onCloseAccount.emit();
	}

  public openHistorical(): void {
    this.routerService.navigate(['/', this.version, 'click-go', this.hash, 'historical']);
    this.onCloseAccount.emit();
  }
}