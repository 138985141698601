import { Injectable } from "@angular/core";
import { CustomerAddress } from "../customer-address.model";
import { Adapter } from "./adapter";
import { CustomerAdapter } from "./customer.adapter";

@Injectable({
    providedIn: "root",
})
export class CustomerAddressAdapter implements Adapter<CustomerAddress> {
    constructor(
        protected customerAdapter: CustomerAdapter
    ) {}

    adapt(item: any): CustomerAddress {
        let customer = item.customer ?  this.customerAdapter.adapt(item.customer) : null;
        
        return new CustomerAddress(
            item.id,
            item.name,
            item.address,
            item.zipcode,
            item.city,
            item.country,
            item.longitude,
            item.latitude,
            item.favorite,
            customer
        );
    }
}
