import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export enum ModalState {
  close = "close",
  open = "open"
}

@Injectable({
  providedIn: "root"
})
export class ModalService {
  protected $data: BehaviorSubject<{component: any, data: {[key: string]: any}}>;
  protected $state: BehaviorSubject<ModalState>;
  protected confirmFn: Function;
  protected cancelFn: Function;
  protected isOpen: boolean = false;

  constructor() {
    this.$data =  new BehaviorSubject<{component: any, data: {[key: string]: any}}>(null);
    this.$state =  new BehaviorSubject<any>(ModalState.close);
  }

  open(component: any, data: {[key: string]: any} = {}, confirm: Function = () => {}, cancel: Function = () => {})  {
    if(this.isOpen) return;
    this.isOpen = true;
    this.$data.next({component, data});
    this.confirmFn = confirm;
    this.cancelFn = cancel;
    this.$state.next(ModalState.open);
  }

  getData(): Observable<any> {
    return this.$data.asObservable();
  }

  getState(): Observable<ModalState> {
    return this.$state.asObservable();
  }

  fireConfirm() {
    this.$data.next(null);
    this.$state.next(ModalState.close);
    if(this.confirmFn) this.confirmFn();
    this.confirmFn = null;
    this.cancelFn = null;
    this.isOpen = false;
  }

  fireCancel() {
    this.$data.next(null);
    this.$state.next(ModalState.close);
    if(this.cancelFn) this.cancelFn();
    this.confirmFn = null;
    this.cancelFn = null;
    this.isOpen = false;
  }

  get hasCancel() {
		return !!this.confirmFn
	}

  get hasConfirm() {
		return !!this.cancelFn
	}
}
