import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-log-with-facebook',
	templateUrl: './log-with-facebook.component.html',
	styleUrls: ['./log-with-facebook.component.scss']
})
export class LogWithFacebookComponent  {
  @Input() deprecated: boolean = true;
  @Output() onSignin: EventEmitter<string>;

  constructor() {
    this.onSignin = new EventEmitter<string>();
  }

  signIn(e) {
    e.preventDefault()
    this.onSignin.emit('facebook')
  }

}


