import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProcessOrder } from 'src/app/core/models/process-order.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { NavigationPage, NavigationService } from 'src/app/core/services/navigation.service';
import { OverlayState } from 'src/app/core/services/overlay.service';

@Component({
	selector: 'app-add-to-cart',
	templateUrl: './add-to-cart.component.html',
	styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent implements OnInit, OnDestroy {

  protected subscriptions: Subscription[];
  protected compData: any;

  @Input('page') page: NavigationPage;
  @Input('pageType') pageType: string = 'click-go';

  @Input('processOrder') processOrder: ProcessOrder;
  @Input('qrcode') qrcode: Qrcode;
  @Input('state') state: OverlayState;
  @Input('minOrder') minOrder: boolean;
  @Input('minOrderValue') minOrderValue: number;
  @Input('totalCart') totalCart: number;
  
  constructor(protected navigationService: NavigationService) { }
 
  ngOnInit() {
    this.subscriptions.push(this.navigationService.getData().subscribe(data => this.compData = data && data.compData ? data.compData : null));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if(sub) sub.unsubscribe()
    })
  }

  getCurrency() {
		if(!this.qrcode) return '';
		return this.qrcode.currencyTableValue || this.qrcode.currency;
	}

  addToCart() {}
  addMenuToCart() {}

}
