import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ClickCollect } from 'src/app/core/models/click-collect.model';
import { Client } from 'src/app/core/models/client.model';
import { CollectionPoint } from 'src/app/core/models/collection-point.model';
import { MarkerMap } from 'src/app/core/models/marker-map.model';
import { ProcessMethod, ProcessOrder } from 'src/app/core/models/process-order.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { AppStateService } from 'src/app/core/services/app-state.service';
import { DateService } from 'src/app/core/services/date.service';
import { GoogleMapService } from 'src/app/core/services/google-map.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { MarkerMapComponent } from 'src/app/shared/components/marker-map/marker-map.component';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { ClickCollectService } from 'src/app/core/services/api/click-collect.service';

export enum FromComponent {
	select = "select",
	order = "order"
}

@Component({
	selector: 'app-click-go-options',
	templateUrl: './click-go-options.component.html',
	styleUrls: ['./click-go-options.component.scss']
})
export class ClickGoOptionsComponent implements OnChanges {

	public deliveryForm: FormGroup;
	public selectedCp: any;
	public selectedDate;
	public selectedTime;
	public timeValues: any;
	public selectedTimeValue: any;

	public centerMarkerMap: google.maps.LatLngLiteral;
	public apiLoaded: boolean;
	public markerMapComponent: MarkerMapComponent;
	public markerMap: MarkerMap;
	public onlyOne: boolean;

	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() hash: string;
	@Input() method: ProcessMethod;
	@Input() date: string;
	@Input() time: string;
	@Input() cc: ClickCollect;
	@Input() cps: CollectionPoint[];
	@Input() processOrder: ProcessOrder;

	@Input() from: FromComponent = FromComponent.select;
	
	@Output() onLoaded: EventEmitter<boolean>;
	@Output() onSelectDate: EventEmitter<string>;
	@Output() onSelectTime: EventEmitter<string>;
	@Output() onSelectDateTime: EventEmitter<Date>;
	@Output() onSelectCollectionPoint: EventEmitter<any>;

	@ViewChild('map') set content(content: MarkerMapComponent) {
		if(content) {
			this.markerMapComponent = content;
		}
	}

	constructor(
		protected fb: FormBuilder,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected modalService: ModalService,
		protected googleMapService: GoogleMapService,
		protected dateService: DateService,
		protected appStateService: AppStateService,
		protected clickCollectService: ClickCollectService,
		protected cdr: ChangeDetectorRef

	) {
		this.onLoaded = new EventEmitter<boolean>()
		this.onSelectDate = new EventEmitter<string>()
		this.onSelectTime = new EventEmitter<string>()
		this.onSelectDateTime = new EventEmitter<Date>()
		this.onSelectCollectionPoint = new  EventEmitter<any>();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(!changes.cps) return;
		this.onlyOne = this.cps.length === 1;
		
		const selectedIndex = this.processOrder 
			&& this.processOrder.collectionPoint 
			&& this.cps.findIndex(e => e.id === this.processOrder.collectionPoint.id) > -1 
				? this.cps.findIndex(e => e.id === this.processOrder.collectionPoint.id) : 0;

		this.clickCollectService.getCollectionPointTimeValuesFrom(
			this.hash, 
			this.cc.manufacturingTime, 
			this.processOrder && this.processOrder.orderAt ? new Date(this.processOrder.orderAt) : new Date()

		).pipe(
			catchError(_ => of(null)),
			mergeMap(data => {
				this.timeValues = data;

				return this.googleMapService.load()
			}),
			mergeMap(data => {
				this.apiLoaded = data;
				const {address, city, zipcode, country} = this.client;

				if(this.cps.length > 0) 
				{
					this.schedule(this.cps[selectedIndex]);
					this.onSelectCollectionPoint.emit(this.cps[selectedIndex]);
				}

				return this.googleMapService.getCoordsByAddress(this.cps.length > 0 ? this.cps[selectedIndex].deliveryZone : `${country} - ${address} - ${zipcode} ${city}`)
			})

		).subscribe(results => {
			if(!('error_message' in results)) {
				this.centerMarkerMap = results.results[0].geometry.location;
				this.markerMap = new MarkerMap(this.centerMarkerMap);
			}

			this.onLoaded.emit(true);
		});

		this.deliveryForm = this.fb.group({
			collectionPoint: [selectedIndex, Validators.required]
		});
	}

	selectDate(value) {
		if(!value) {
			this.date = null;	
		}
		else this.date = this.dateService.convertDateToString(value);
		this.time = null;
	}

	selectTime(value) {
		this.time = value;
		this.onSelectDateTime.emit(this.dateService.fromString(`${this.date} ${this.time}`))
	}

	selectCollectionPoint(e: any) {
		const {value} = e.target;

		this.date = null;	
		this.time = null;

		this.onSelectDateTime.emit(null)
		this.onSelectCollectionPoint.emit(this.cps[parseInt(value)]);

		this.googleMapService.getCoordsByAddress(this.cps[parseInt(value)].deliveryZone).subscribe(results => {
			if(!('error_message' in results)) {
				this.centerMarkerMap = results.results[0].geometry.location;
				this.markerMap = new MarkerMap(this.centerMarkerMap);
				this.markerMapComponent.changeObject(this.markerMap);
			}

			this.schedule(this.cps[parseInt(value)]);
		})
	}

	schedule(collectionPoint: any) {		
		this.selectedTimeValue = null;
		this.selectedDate = null;
		this.selectedTime = null;

		this.selectedCp = collectionPoint;

		const scheduling = JSON.parse(this.selectedCp.scheduling);

		if(scheduling.Week && scheduling.Navigator) {
			this.selectedTimeValue = this.timeValues.find(d => d.collectionPointId === collectionPoint.id)

		} 
	}
}
