import { ShopItem } from "./shop-item.model";

export class ShopCart {
    hash: string;
    items: ShopItem[];

    constructor(hash: string, items: ShopItem[]) {
        this.hash = hash;
        this.items = items;
    }
}