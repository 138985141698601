import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from "@angular/core";
import { DatePickerComponent, FocusEventArgs } from "@syncfusion/ej2-angular-calendars";
import { DateService } from "src/app/core/services/date.service";

declare var isSafari;

export enum ViewType {
    WEEK = "Week",
    NAVIGATOR = "Navigator"
}
@Component({
    selector: 'app-datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['./datetime-picker.component.scss']
})
export class DatetimePickerComponent implements OnChanges {

    @ViewChild(DatePickerComponent) date: DatePickerComponent;
    @Input() value: Date;
    @Input() timeValues: any;

    @Output('onSelectTime') onSelectTimeEv:EventEmitter<any>;
    @Output('onSelectDate') onSelectDateEv:EventEmitter<any>;

    public inputValueDate: Date;
    public timeValue: string = "";
    public datePipe: DatePipe = new DatePipe('en-US');
    public newTimeValues: string[] = [];

    public min: Date = new Date();
    public max: Date;

    public dayValues = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7
    };
    
    constructor(
        protected dateService: DateService,
        private cdr: ChangeDetectorRef
    ) {
        this.onSelectTimeEv = new EventEmitter<any>();
        this.onSelectDateEv = new EventEmitter<any>();
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.max = new Date(this.min);
        this.max.setMonth(this.max.getMonth() + 3)
        this.init();


        if(changes.value && changes.value.currentValue) {
            this.inputValueDate = changes.value.currentValue;
            this.onSelectDateEv.emit(this.getDate(changes.value.currentValue));

            this.timeValue = "";

            const dString = this.getDate(this.inputValueDate);
            const found = this.timeValues.find(({date}) => date === dString);

            // recuperer les temps en rajoutant en tenant compte du décalage (addSeconds)
            this.newTimeValues = found ? found.timeslots : [];

            const timeIndex = this.newTimeValues.findIndex(e => e === this.getTime(changes.value.currentValue));

            if(timeIndex > -1){
                this.timeValue = this.newTimeValues[timeIndex];
                this.onSelectTimeEv.emit(this.timeValue);

            }else if(this.newTimeValues.length > 0) {
                this.timeValue = this.newTimeValues[0];
                this.onSelectTimeEv.emit(this.timeValue);
            }
            
        }
        this.cdr.detectChanges();
    }


    isSafariBrowser() {
      return isSafari;
    }

    getDate(date: Date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd')
    }

    getTime(date: Date) {
        return this.datePipe.transform(date, 'HH:mm')
    }
    
    /** Initialise le calendrier */
    init() {
        this.inputValueDate = null;
        this.timeValue = "";
    }

    /**
     * Creer les date dans le calendrier dépendemment des disponibilités
     * 
     * @param {*} args 
     */
    onRenderCell(args): void { 
        // Transforme la date en str
        const date = this.datePipe.transform(this.dateService.fromString(args.date), 'yyyy-MM-dd');
        const found = this.timeValues.find(e => e.date === date);
        const mapped = this.timeValues.map(e => e.date);

        // Si la date n'est pas incluse - désactiver la date dans le calendrier
        if(!(mapped && mapped.includes(date))) args.isDisabled = true;
        // Si la date est incluse mais qu'il n'ya pas de temps disponible, désacctiver la date
        else if(!(found && found.timeslots.length > 0)) args.isDisabled = true;
        else args.isDisabled = false;
    } 

    /**
     * Affiche la date
     * 
     * @param {FocusEventArgs} args 
     */
    onFocus(args: FocusEventArgs): void {
        this.date.show();
    }

    /**
     * Quand on selectionne une date
     * 
     * @param {Date} value
     */
    onChangeDate({value}): void { 
        if(value) {
            this.inputValueDate = value;
            this.onSelectDateEv.emit(value);
            this.timeValue = "";

            const dString = this.getDate(this.inputValueDate);
            const found = this.timeValues.find(({date}) => date === dString);

            // recuperer les temps en rajoutant en tenant compte du décalage (addSeconds)
            this.newTimeValues = found ? found.timeslots : [];

            if(this.newTimeValues.length > 0) {
                this.timeValue = this.newTimeValues[0];
                // Selection par defaut du premier temps
                this.onSelectTimeEv.emit(this.timeValue);

            }
        } else {
            // Time à null
            this.inputValueDate = null;
            this.onSelectDateEv.emit(value);
            this.timeValue = "";
        }

    } 

    /**
     * Ajoute le temps de AddSeconds
     * 
     * @param {string} current - date à changer
     * 
     * @returns {string} - temps avec ajout
     */
    formatTime(current, gap: number = 1800): string {
        const time =  this.dateService.setTime(current, this.dateService.fromString('1970-01-01'));
        time.setSeconds(time.getSeconds());

        const time2 = new Date(time);
        time2.setSeconds(time2.getSeconds() + gap);

        return `${this.datePipe.transform(time, 'HH:mm')} - ${this.datePipe.transform(time2, 'HH:mm')}`
    
    }
    
    /**
     * Lorsque l'on sélectionne un temp
     * 
     * @param {*} e 
     */
    onSelectTime(e): void { 
        e.preventDefault();
       this.timeValue = e.target.value;
       this.onSelectTimeEv.emit(this.timeValue);
    } 

    
}
