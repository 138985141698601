import { Component, Input } from '@angular/core';
import { Product } from 'src/app/core/models/product.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';

@Component({
	selector: 'app-nutriscore-and-allergens',
	templateUrl: './nutriscore-and-allergens.component.html',
	styleUrls: ['./nutriscore-and-allergens.component.scss']
})
export class NutriscoreAndAllergensComponent {
  @Input() type: string = 'product';
  @Input() productList: boolean = false;
  @Input() qrcode: Qrcode;
  @Input() product: Product;
  @Input() products: Product[];
  @Input() show: boolean = true;
  @Input() allergens: any;
}
