import { DecimalPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { DeliveryZone } from "src/app/core/models/delivery-zone.model";
import { Menu } from "src/app/core/models/menu.model";
import { ProcessOrder } from "src/app/core/models/process-order.model";
import { ProductGroupsOptions } from "src/app/core/models/product-groups-options.model";
import { ProductPrice } from "src/app/core/models/product-price.model";
import { Product } from "src/app/core/models/product.model";
import { Qrcode } from "src/app/core/models/qrcode.model";
import { ShopCart } from "src/app/core/models/shop-cart.model";
import { ItemType } from "src/app/core/models/shop-item.model";
import { AppStateService } from "src/app/core/services/app-state.service";
import { ProcessService } from "src/app/core/services/process.service";
import { ShopCartService } from "src/app/core/services/shop-cart.service";

export enum OrderMode {
  add = "add",
  replace = "replace"
}

@Component({
    selector: 'app-product-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class ProductDetailComponent implements OnInit, OnDestroy {
    public orderMode: OrderMode = OrderMode.add;

    public productType: ItemType;

    public submited = false;
    public cart: ShopCart;
    public processOrder: ProcessOrder;
    public deliveryCost: number = 0;
    public choiceForm: FormGroup;

    // menu Form
    public menuForm: FormGroup;
    public formSteps: FormArray;
    public menuProducts: Product[] = [];
    public menuOptions: ProductGroupsOptions[] = [];

    public stepObjects: {} = {};
    public selectedProduct: number = null

    public subscribtions: Subscription[] = [];

    @Input() type: string = 'product';

    @Input() qrcode: Qrcode;
    @Input() product: Product;
    @Input() menu: Menu;

    @Input() allergenById: any;
    @Input() selectedIndex: number;
    @Input() pageType: string = 'qrcode';
    @Input() compData = {
      type: 'product',
      prices: [],
      options: {},
      prdPriceIndex: null,
      checkPrice: false,
      checkOptions: {},
      steps: {},
      cartQt: 1
    }

    @Output() onAddToCart: EventEmitter<void>;

    constructor(
      protected fb: FormBuilder,
      protected decimalPipe: DecimalPipe,
      protected shopCartService: ShopCartService,
      protected processService: ProcessService,
      protected appStateService: AppStateService,

    ) {
      this.onAddToCart = new EventEmitter<void>();
    }

    changeDeliveryCost(zone: DeliveryZone) {
      this.deliveryCost = zone.fixedDeliveryCost;
    }

    ngOnInit() {
      this.choiceForm = this.fb.group({
        product: [null],
        price: [null],
        groupOptions: this.fb.array([])
      })

      // Steps in array
      this.formSteps = this.fb.array([]);

      // Create intial form
      this.menuForm = this.fb.group({
        steps: this.formSteps
      })

      if(this.type === 'menu' && this.menu) this.initMenu();
      else if(this.product) this.initProduct();

      this.subscribtions.push(this.processService.getProcessOrder().subscribe(po => {
        this.processOrder = po;

        if(this.pageType === 'click-go') {
          if(this.processOrder.deliveryZone && (this.processOrder.deliveryZone.freeDelivery == null || this.processOrder.deliveryZone.freeDelivery == 0)) {
            this.changeDeliveryCost(this.processOrder.deliveryZone);
    
          } else if(this.processOrder.deliveryZone && this.processOrder.deliveryZone.freeDelivery) {
            if (this.totalCart < this.processOrder.deliveryZone.freeDelivery && this.processOrder.deliveryZone.fixedDeliveryCost > 0){
              this.changeDeliveryCost(this.processOrder.deliveryZone);
            }
          }
        }
      
      }));
    }

    initProduct() {
      this.subscribtions.push(this.shopCartService.getShopCart().subscribe(cart => {
        this.cart = cart;
        this.submited = false;
        this.compData.type = 'product';
        this.compData.checkPrice = false;
        this.compData.checkOptions = {};
        this.compData.options = {};
        this.compData.prdPriceIndex  = null;
        this.productType = this.product.groupOptions.length > 0 && this.product.isMultiprice
          ? ItemType.both : (this.product.groupOptions.length > 0 ? ItemType.groupOption : (this.product.isMultiprice ? ItemType.multiprice : ItemType.simple) );

        if (this.product.groupOptions.length > 0) {
          this.product.groupOptions.forEach(option => {
            if (!option.parentId) {
              this.compData.checkOptions[option.id] = false;
              this.compData.options[option.id] = null;
            } 
          });

          this.product.groupOptions.forEach(option => {
            if (option.parentId && option.isDefault) {
              this.compData.checkOptions[option.parentId] = true;
              this.compData.options[option.parentId] = option.id;
            }
          });

          if(this.orderMode === OrderMode.replace) this.changeQtCartByGroups();

        } else if(this.orderMode === OrderMode.replace && this.productType === ItemType.simple)
          this.compData.cartQt = this.count;
      }))

      if(this.productType == ItemType.multiprice) {
        this.compData.prices = this.product.prices.map(price => {
          let found = this.shopCartService.findProduct(this.product, price);
          return {id: price.id, name: price.name, qt: found ? found.qt : 0, price: price.price}
        });
      }
    }

    hasError(group: FormGroup) {
      return group.dirty && group.invalid;
    }

    initMenu() {
      this.subscribtions.push(this.shopCartService.getShopCart().subscribe(cart => {
        this.cart = cart;
        this.submited = false;
        this.compData.type = 'menu';
        this.compData.checkPrice = false;
        this.compData.checkOptions = {};
        this.compData.options = {};
        this.compData.prdPriceIndex  = null;
        this.productType = ItemType.menu;
      }))

      this.stepObjects = this.menu.steps.reduce((acc, curr) => {
        if(!(curr.products && curr.products.length)) return acc;

        const groupOptions = this.fb.array([]);

        // Init steps with  product id
        const newStepForm = this.fb.group({
            productId: [null, Validators.compose([Validators.required])],
            groupOptions
        });

        this.formSteps.push(newStepForm);

        const obj: any = { step: curr, products: [], form: newStepForm}
      
        curr.products.forEach(pr => {
            this.menuProducts.push(pr);
            // Check if has groups options or do nothing
            if(pr.menuGroupOptions && pr.menuGroupOptions.length) {
                // Split child and parent
                const {childOptions, groupOptions} = pr.menuGroupOptions.reduce((acc, curr) => {
                    this.menuOptions.push(curr);

                    return curr.parentId 
                        ? {...acc, childOptions: [...acc.childOptions, curr]} 
                        : {...acc, groupOptions: [...acc.groupOptions, curr]};

                }, {childOptions: [], groupOptions: []})

                if(groupOptions && groupOptions.length) {
                    const options = groupOptions.reduce((acc, curr) => {
                        const childrens = childOptions.filter(child => child.parentId && child.parentId === curr.id)
                        return {...acc, [curr.id]: {groupOption: curr, childrens}}

                    }, {})

                    // Place in products of step object
                    obj.products.push({product: pr, options})
                }
            }
        }) 
        
        // return with id as keys
        return  {...acc, [curr.id]: obj }
           
      }, {})
    }

    ngOnDestroy(): void {
      this.subscribtions.forEach(sub => {
        if(sub) sub.unsubscribe();
      })
    }

    getPicture() {
      if(this.type == 'product'){
        if(!this.product) return null;
        if(this.product.descriptionPictureUrl && this.product.descriptionPictureUrl.trim() !== '') return this.product.descriptionPictureUrl.trim()
        if(this.product.pictureUrl && this.product.pictureUrl.trim() !== '') return this.product.pictureUrl.trim()

        return null;

      } else if(this.type == 'menu'){
        if(!this.menu) return null;
        if(this.menu.pictureUrl && this.menu.pictureUrl.trim() !== '') return this.menu.pictureUrl.trim()

        return null;
      }
    }

    formatPrice(price: any) {
      return typeof(price) === 'string' ? parseFloat(price.replace(',', '.')) : price;
    }

    getCurrency(qrcode: Qrcode) {
      if(!qrcode) return '';
      return qrcode.currencyTableValue || qrcode.currency;
    }
    
    haveDescription(product: Product) {
      return !(product.ingredient === '' || product.ingredient === null) 
    }

    haveSpecs(product: Product) {
        return product.isBio || product.isVegan || product.isVegetarian || product.isHomemade;
    }

    haveDetails(product: Product) {
        return (this.qrcode.nutriscoreEnable && product.nutriscore && product.nutriscore !== '0') 
        || (this.qrcode.alergenEnable && product.alergen)
    }

    havePicture(product: Product) {
      return (product.pictureUrl && product.pictureUrl.length > 0) 
            || (product.descriptionPictureUrl && product.descriptionPictureUrl.length > 0);
    }

    isComplexProduct(product: Product) {
      if((this.pageType === 'in' || this.pageType === 'click-go') && this.productType !== ItemType.simple)
        return true;

      return this.haveDescription(product) || this.haveSpecs(product) || this.haveDetails(product) || this.havePicture(product);
    }

    addQt(e) {
      e.preventDefault();
      this.compData.cartQt = this.compData.cartQt + 1;
    }

    removeQt(e) {
      e.preventDefault();
      if (this.compData.cartQt > 1) {
        this.compData.cartQt = this.compData.cartQt - 1;
      }
    }

    addToCart(e): void {
      e.preventDefault();
      if(this.pageType === 'click-go' || this.pageType === 'in') {
        if(this.type === 'menu') this.addMenu();
        else this.addProduct();
      }
    }

    getPriceValue() {
      if(this.type === 'menu') {
        return this._getMenuPrice();
      } else {
        if (!(this.product.isMultiprice || this.product.groupOptions.length > 0)) {
          return this._getProductPrice();
          
        } else if(this.product.isMultiprice) {
          if(!this.product.prices[this.compData.prdPriceIndex]) return 0
          return this._getProductPrice(this.product.prices[this.compData.prdPriceIndex], this.product.groupOptions.length > 0);
        }
          
        else return this._getProductPrice(null, true);
      }
    }

    isValid() {
      let result = true;

      if(this.type === 'menu') {
        return this.menuForm.valid;

      } else {
        if (this.product.isMultiprice) {
          if (this.compData.prdPriceIndex == null){
            result = false;
          }
        }

        if (this.product.groupOptions.length > 0) {
          Object.keys(this.compData.checkOptions).forEach(option => {
            if (this.compData.checkOptions[option] === false) {
              result = false;
            }
          });
        }
      }
      return result;
    }


    get minOrder(): boolean{
      return this.appStateService.getMinOrder();
    }
  
    get minOrderValue(): number{
      return this.appStateService.getMinOrderValue();
    }

    get totalCart(): number{
      if(!this.cart) return 0;
      
      return this.cart.items.reduce((acc, curr) => {
        const price = curr.type === ItemType.menu ? parseFloat((curr.menu.price as any).replace(',',  '.')) : (curr.type === ItemType.both || curr.type === ItemType.multiprice 
        ? parseFloat(curr.price.price.replace(',',  '.')) : parseFloat(curr.product.price.replace(',',  '.')));

        const addCost =  curr.type === ItemType.menu 
          ? curr.menuGroupOptions.reduce((acc, curr) => acc + curr.price, 0) 
          + curr.menuProducts.reduce((acc, curr) => curr.price ? acc + parseFloat(curr.price.replace(',',  '.')) : acc, 0)
            : (curr.type === ItemType.both || curr.type === ItemType.groupOption 
            ? curr.groupOptions.reduce((acc2, curr2) => acc2 + curr2.price, 0) : 0);
        
        return acc + (price + addCost) * curr.qt;
      }, 0);
    }

    get count() {
      return this.shopCartService.getProductLength(this.product.id);
    }

  
    /** PRODUCT */
    addProduct() {
      if (!(this.product.isMultiprice || this.product.groupOptions.length > 0)) {
        this._addProduct();
        
        this.onAddToCart.emit();

      } else if(this.formVerification()){
          if(this.product.isMultiprice) 
            this._addProduct(this.product.prices[this.compData.prdPriceIndex], this.product.groupOptions.length > 0);

          else this._addProduct(null, true);

          this.onAddToCart.emit();
      }
    }

    _addProduct(price?: ProductPrice, options: boolean = false) {
      const groups = !options ? [] : Object.keys(this.compData.options).map(v => this.product.groupOptions.find(f => f.id === this.compData.options[v]));
      if(this.orderMode === OrderMode.replace
        && this.shopCartService.findProduct(this.product, price, groups)
      )
        this.shopCartService.setProductQuantity(
            this.compData.cartQt,
            this.product, 
            price,
            groups
        );
          
      else
        this.shopCartService.addProduct(
          this.product, 
          price,
          groups,
          this.compData.cartQt
        );
    }

    _getProductPrice(price?: ProductPrice, options: boolean = false): number {
      const groups = !options ? [] : Object.keys(this.compData.options).map(v => this.product.groupOptions.find(f => f.id === this.compData.options[v]));
      const pricevalue = price ? parseFloat(price.price.replace(',','.')) : parseFloat(this.product.price.replace(',','.'))
      return (pricevalue + groups.reduce((acc, curr) => acc + (curr && curr.price ? curr.price : 0), 0)) * this.compData.cartQt
    }


    formVerification() {
      let result = true;
      this.submited = true;

      if (this.product.isMultiprice) {
        if (this.compData.prdPriceIndex == null){
          this.compData.checkPrice = true;
          result = false;
        }
      }

      if (this.product.groupOptions.length > 0) {
        Object.keys(this.compData.checkOptions).forEach(option => {
          if (this.compData.checkOptions[option] === false) {
            result = false;
          }
        });
      }

      return result;
    }

    changeQtCartByGroups() {
      if(Object.keys(this.compData.checkOptions).every(option => this.compData.checkOptions[option])) {
        const groups = Object.keys(this.compData.options).map(v => this.product.groupOptions.find(f => f.id === this.compData.options[v]));

        if(this.product.isMultiprice && !this.compData.checkPrice && typeof(this.compData.prdPriceIndex) !== 'undefined') {
          const selItem = this.shopCartService.findProduct(this.product, this.product.prices[this.compData.prdPriceIndex], groups);
          if(selItem) this.compData.cartQt = selItem.qt;
          else this.compData.cartQt = 1;

        } else if(!this.product.isMultiprice) {
          const selItem = this.shopCartService.findProduct(this.product, null, groups);
          if(selItem) this.compData.cartQt = selItem.qt;
          else this.compData.cartQt = 1;
        }
      }
    }

    changeQtCartByMultiprices() {
      if(!this.compData.checkPrice && typeof(this.compData.prdPriceIndex) !== 'undefined') {
        if(this.product.groupOptions.length < 1) {
          const selItem = this.shopCartService.findProduct(this.product, this.product.prices[this.compData.prdPriceIndex], []);
          if(selItem) this.compData.cartQt = selItem.qt;
          else this.compData.cartQt = 1;
  
        } else if(this.product.groupOptions.length > 0 && Object.keys(this.compData.checkOptions).every(option => this.compData.checkOptions[option])) {
          const groups = Object.keys(this.compData.options).map(v => this.product.groupOptions.find(f => f.id === this.compData.options[v]));
          const selItem = this.shopCartService.findProduct(this.product, this.product.prices[this.compData.prdPriceIndex], groups);
          if(selItem) this.compData.cartQt = selItem.qt;
          else this.compData.cartQt = 1;
        }
      }
    }

    propagateOptionsChange(optIndex, parentId) {
      this.compData.checkOptions[parentId] = true;
      this.compData.options[parentId] = this.product.groupOptions[optIndex].id;

      if(this.orderMode === OrderMode.replace) this.changeQtCartByGroups();
    }

    propagatePriceChange(id) {
      this.compData.checkPrice = false;
      this.compData.prdPriceIndex = id;

      if(this.orderMode === OrderMode.replace) this.changeQtCartByMultiprices();
    }

    isDisabled() {
      return this.product.isMultiprice && this.product.groupOptions.length > 0 ?
        !(this.product.groupOptions.some(option => this.compData.checkOptions[option.id]) && this.compData.prices.some((price, i) => this.compData.prdPriceIndex === i))
          :(this.product.groupOptions.length > 0 ? this.product.groupOptions.every(option => !this.compData.checkOptions[option.id]) 
            : (this.product.isMultiprice ? this.compData.prices.every(price => price.qt < 1) : this.compData.cartQt < 1) )
    }

    /** MENU */
    addMenu() {      
      const raw = this.menuForm.getRawValue();

      const productIds = Object.keys(raw.steps).reduce((acc, curr) => [...acc, raw.steps[curr].productId], [])
      const optionIds = Object.keys(raw.steps).reduce((acc, curr) => [...acc, ...raw.steps[curr].groupOptions.map(({optionId}) => optionId)], [])
      const products = this.menu.steps.reduce((acc, curr) => [...acc, ...curr.products.filter(pr => productIds.includes(pr.id))], [])

      const groups = Object.keys(this.stepObjects).reduce((acc, curr) => {
        if(!this.stepObjects[curr].options) return acc;
        const options = Object.keys(this.stepObjects[curr].options).map(k => this.stepObjects[curr].options[k])

        const opts = options.reduce((acc, curr) => {
          return [...acc, ...curr.option.childrens]
        }, []).filter(c => optionIds.includes(c.id))

        return [...acc, ...opts];
      }, [])

      if(this.orderMode === OrderMode.replace
        && this.shopCartService.findMenu(this.menu, products, groups)
      )
        this.shopCartService.setMenuQuantity(
            this.compData.cartQt,
            this.menu, 
            products,
            groups
        );
          
      else
        this.shopCartService.addMenu(
          this.menu, 
          products,
          groups,
          this.compData.cartQt
        );

      this.onAddToCart.emit();
    }

    _getMenuPrice() {
      const raw = this.menuForm.getRawValue();

      const productIds = Object.keys(raw.steps).reduce((acc, curr) => {
        return [...acc, raw.steps[curr].productId];
      }, [])

      const productPrice = this.menu.steps.reduce((acc, curr) => {
        const addCost = curr.products.reduce((acc, curr) => {
          if(!curr.price) return acc;
          if(!productIds.includes(curr.id)) return acc;

          const price = this.formatPrice(curr.price);
          return acc + price;
        }, 0)

        return acc + addCost;
      }, 0);

      const optionIds = Object.keys(raw.steps).reduce((acc, curr) => {
        return [...acc, ...raw.steps[curr].groupOptions.map(({optionId}) => optionId)];
      }, [])

      const price = Object.keys(this.stepObjects).reduce((acc, curr) => {
        const options = this.stepObjects[curr].options
        if(!options) return acc;

        const addCost = Object.keys(options).reduce((acc, curr) => {
          const childrens = options[curr].option.childrens.filter(c => optionIds.includes(c.id))
          childrens.forEach(c => acc += c.price)
          return acc;
        }, 0)
        
        return acc + addCost;
      }, productPrice)
      
      return (this.menu.price ? parseFloat((this.menu.price as any).replace(',',  '.')) + price: price) * this.compData.cartQt;
    }

     /**
     * Return key of steps
     * 
     * @returns {number} 
     */
      getKeysOfStepObjects() {
        return Object.keys(this.stepObjects)
    }

    /**
     * Called after select product
     * 
     * @param {number} id of product
     * @param {FormGroup} stepForm form to edit form array
     */
    propagateMenuOptionForm(id, stepForm: FormGroup) {
      const groupOptionForm = stepForm.controls['groupOptions'] as FormArray;

      // Reset form array
      groupOptionForm.clear();

      const step: any = Object.keys(this.stepObjects).reduce((acc, curr) => {
          if(acc) return acc;
          const {products} = this.stepObjects[curr];
          const product = products.find(pr => pr.product.id == id);

          return product ? this.stepObjects[curr] : acc;
      }, null)

      if(!step) return ;

      // Reset option objects in step object
      step.options = {}

      // Check if product is in step object (can verify f object has options)
      const obj = this.getProductIn(step.products, id)

      if(obj && obj.options && Object.keys(obj.options)) {
          this.selectedProduct = id;

          // Create the new option Objects
          step.options = Object.keys(obj.options).reduce((acc, curr) => {
              const defaultChild = obj.options[curr].childrens.find(({isDefault}) => isDefault)
              const newOptionForm = this.fb.group({
                  optionId: [defaultChild ? defaultChild.id : null, Validators.compose([Validators.required])],
              });

              groupOptionForm.push(newOptionForm)

              return {...acc, [curr]: { productId: id, option: obj.options[curr], form: newOptionForm } }
          } , {}) 
      }
    }
    
    /**
     * Verify if a product is selected checkForm
     * 
     * @param {number} productId 
     * @param {FormGroup} stepForm 
     * 
     * @returns {boolean}
     */
    hasProductInStep(productId: number, stepForm: FormGroup): boolean {
        return stepForm.controls['productId'].value == productId;
    }

    /**
     * Return a product in products array
     * 
     * @param {Array.<*>} products 
     * @param {number} id 
     * 
     * @returns {*} 
     */
    getProductIn(products, id): any {
        return products.find(pr => pr.product.id === id)
    }

    /**
     * Return options in product object
     * 
     * @param {number} productId 
     * @param {*} step 
     * 
     * @returns {Array.<*>} 
     */
    getOptionsInProduct(productId: number, step: any): any[] {
        const {options} = step;

        const keys = options ? Object.keys(options) : []

        if(!(keys && keys.length)) return []

        return keys.filter(k => options[k].productId === productId).map(k => options[k]);
    }

     /**
     * Return string options
     * 
     * @param {number} productId 
     * @param {*} step 
     * 
     * @returns {Array.<string>} 
     */
    getOptionsAsString(productId: number, step: any): string[] {      
      if(!step.options) return [];
      const raw = step.form.getRawValue();

      const optionIds = raw.groupOptions.map(({optionId}) => optionId);

      const options = Object.keys(step.options).reduce((acc, curr) => {
        const option = step.options[curr];
        if(productId !== option.productId) return acc;

        const childrens = option.option.childrens.filter(c => optionIds.includes(c.id))

        if(!childrens.length) return acc;

        return [...acc, ...childrens.map(({name, price}) => name + (price ? ` (${this.decimalPipe.transform(this.formatPrice(price), '1.2-2')} ${this.getCurrency(this.qrcode)})`: ''))];
      }, [])

      return options;
    }

    selectProduct(id, stepForm: FormGroup) {
      if(this.selectedProduct === id) {
        this.selectedProduct = null;
      } else {
        const step: any = Object.keys(this.stepObjects).reduce((acc, curr) => {
          if(acc) return acc;
          const {products} = this.stepObjects[curr];
          const product = products.find(pr => pr.product.id == id);

          return product ? this.stepObjects[curr] : acc;
        }, null)

        if(!step) return ;

        const obj = this.getProductIn(step.products, id)
        if(obj && obj.options && Object.keys(obj.options))  this.selectedProduct = id;
      }
    }

    get hasDetailsInCompo(): boolean {
      
      return this.selectedProducts.some(p => (this.qrcode.nutriscoreEnable && p.nutriscore && p.nutriscore !== '0') 
      || (this.qrcode.alergenEnable && p.alergen))
    }
    
    get selectedProducts(){
      const raw = this.menuForm.getRawValue();

      const productIds = Object.keys(raw.steps).reduce((acc, curr) => [...acc, raw.steps[curr].productId], [])
      return this.menuProducts.filter(({id}) => productIds.includes(id))
    }
    
  }
