import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { Client } from 'src/app/core/models/client.model';
import { Family } from 'src/app/core/models/family.model';
import { Product } from 'src/app/core/models/product.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { VersionService } from 'src/app/core/services/api/version.service';
import { RouterService } from 'src/app/core/services/router.service';

@Component({
	selector: 'app-qrcodes',
	templateUrl: './qrcodes.component.html',
	styleUrls: ['./qrcodes.component.scss']
})
export class QrCodesComponent implements OnInit {

	public version: string = "v2";
	public loading: boolean = false;
	public client: Client;
	public families: Family[];
	public suggestions: Product[];
	public selectedProduct: Product;
	public qrcode: Qrcode;
	public qrcodes: Qrcode[];
	public hash: string;
	public allergenById = {};
	public illustrations = {
		"0": [],
		"1": ["/assets/theme/1/1.jpg", "/assets/theme/1/2.jpg", "/assets/theme/1/3.jpg", "/assets/theme/1/4.png"],
		"2": ["/assets/theme/2/1.jpg", "/assets/theme/2/2.jpg", "/assets/theme/2/3.jpg"],
		"3": ["/assets/theme/3/1.jpg", "/assets/theme/3/2.jpg", "/assets/theme/3/3.jpg"],
		"4": ["/assets/theme/4/1.jpg", "/assets/theme/4/2.png", "/assets/theme/4/3.jpg"],
		"5": ["/assets/theme/5/1.jpg", "/assets/theme/5/2.jpg", "/assets/theme/5/3.jpg"],
		"6": ["/assets/theme/6/1.jpg", "/assets/theme/6/2.jpg", "/assets/theme/6/3.jpg"],
		"7": ["/assets/theme/7/1.jpg", "/assets/theme/7/2.jpg", "/assets/theme/7/3.jpg"],
		"8": ["/assets/theme/8/1.jpg", "/assets/theme/8/2.jpg", "/assets/theme/8/3.jpg"],
		"9": ["/assets/theme/9/1.jpg", "/assets/theme/9/2.jpg", "/assets/theme/9/3.jpg"],
		"10": ["/assets/theme/10/1.jpg", "/assets/theme/10/2.jpg", "/assets/theme/10/3.jpg"],
		"11": ["/assets/theme/11/1.jpg", "/assets/theme/11/2.jpg", "/assets/theme/11/3.jpg"],
		"12": ["/assets/theme/12/1.jpg", "/assets/theme/12/2.jpg", "/assets/theme/12/3.jpg"],
		"13": ["/assets/theme/13/1.jpg", "/assets/theme/13/2.jpg", "/assets/theme/13/3.jpg"],
		"14": ["/assets/theme/14/1.jpg", "/assets/theme/14/2.jpg", "/assets/theme/14/3.jpg"],
		"15": ["/assets/theme/15/1.jpg", "/assets/theme/15/2.jpg", "/assets/theme/15/3.jpg"]
	}
	public pageType: string;
	public table: string = 'any';

	constructor(
		protected route: ActivatedRoute,
		protected routerService: RouterService,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected versionService: VersionService

	) {
		this.hash = this.route.parent.snapshot.paramMap.get('hash');
		this.table = this.route.parent.snapshot.paramMap.get('table');
	}

	ngOnInit() {
		this.loading = true;

		this.route.data.subscribe(({pageType}) => {
			this.pageType = pageType;
		});

		this.clientService.getClient(this.hash).pipe(
			catchError(_ => {
				this.loading = false;
				return of(null);
			}),
			mergeMap(client => {
				this.client = client;

				if (!(client && client.isEnable)) {
					return of(null)
				}

				return this.qrcodeService.getQrcode(this.hash);
			}),
			mergeMap(qrcode => {
				this.qrcode = qrcode;
				//this.qrcodeService.setColors(qrcode);

				if (!qrcode) {
					return of(null)
				}
			
				return this.qrcodeService.getQrcodes(this.hash)
			}),
			mergeMap(qrcodes => {
				this.qrcodes = qrcodes;
				if(!qrcodes || qrcodes.length < 2) this.routerService.navigate(['/', this.version, 'qrcode', this.hash]);
				return this.qrcodeService.getIllustrations(this.hash);
			}),
			finalize(() => { this.loading = false })
			
		).subscribe(illustrations => {
			if (illustrations)
				this.illustrations[0] = illustrations.map(illu => illu.url);
			
			this.loading = false;
		})
	}

	public hasHeadline(qrcode: Qrcode) {
		return qrcode.headlineEnable && qrcode.headline && qrcode.headline.trim().length > 0
	}
	
	public hasClientName(client: Client, qrcode: Qrcode) {
		return qrcode.clientNameEnable && client && client.name.trim().length > 0
	}

	public redirectTo(uri) {
		this.routerService.navigate(uri);
	}
	
}
