import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Menu } from "src/app/core/models/menu.model";
import { Product } from "src/app/core/models/product.model";
import { Qrcode } from "src/app/core/models/qrcode.model";
import { ShopCart } from "src/app/core/models/shop-cart.model";
import { VersionService } from "src/app/core/services/api/version.service";
import { RouterService } from "src/app/core/services/router.service";
import { ShopCartService } from "src/app/core/services/shop-cart.service";

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

    @Input() menu: Menu;
    @Input() pageType: string = 'qrcode';
    @Input() table: string = 'any';
    @Input() hash: string;
    @Input() qrcode: Qrcode;
    @Input() allergens: any;

    public isOpen = false;
    public cart: ShopCart;
    public subscribe: Subscription;
    public version: string = 'v2';

    constructor(
        protected routerService: RouterService,
        protected versionService: VersionService,
		protected shopCartService: ShopCartService
    ) {}

    ngOnInit() {
        this.subscribe = this.shopCartService.getShopCart().subscribe(cart => this.cart = cart);
    }

    get orderMode() {
        return (this.pageType === 'click-go' || this.pageType === 'in') && !this.menu.isMultiprice;
    }

    ngOnDestroy(): void {
        this.subscribe.unsubscribe();
    }

    public toggleAction(): void {
        if(this.orderMode) {
            this.selectItem(this.menu)
        } else this.isOpen = !this.orderMode ? !this.isOpen : false;
    }

    public addItem(menu: Menu) {
        let added = false;

        this.cart.items.forEach((item, i) => {
            if(item.menu && item.menu.id === menu.id) {
                added = true;
                this.shopCartService.addMenu(menu)
            }
        });

        if(!added) {
            this.shopCartService.addMenu(menu);
        }
    }

    public removeItem(menu: Menu) {
        this.cart.items.forEach((item, i) => {
           if(item.menu && item.menu.id === menu.id) this.shopCartService.removeMenu(menu)
        });
    }

    public removeAllItems(menu: Menu) {
        this.shopCartService.removeAllMenu(menu.id);
    }

    public selectItem(menu: Menu) {
        if(this.pageType === "in")
            this.routerService.navigate(['/', this.version, this.pageType, this.hash, this.table, 'family', menu.famillyId, 'menu', menu.id])
        else 
            this.routerService.navigate(['/', this.version, this.pageType, this.hash, 'family', menu.famillyId, 'menu', menu.id])
    }  
    
    public haveSpecs(product: Product) {
        return product.isBio || product.isVegan || product.isVegetarian || product.isHomemade;
    }

    public haveNutriscoreOrAllergen(product: Product) {
        if(!(this.qrcode.nutriscoreEnable || this.qrcode.alergenEnable)) return false

        const hasNutriscore = this.qrcode.nutriscoreEnable && product.nutriscore && product.nutriscore !== '0';
        const hasAllergen = this.qrcode.alergenEnable && product.alergen;

        return hasNutriscore || hasAllergen;
    }

    get countItem() {
        return this.shopCartService.getMenuLength(this.menu.id);
    }
}