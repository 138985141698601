export class TimeSlot {
	id: number;
    beginAt: string;
    endAt: string;

	constructor(
		id: number,
        beginAt: string,
        endAt: string,
	) {
		this.id = id;
        this.beginAt = beginAt;
        this.endAt = endAt;
	}
}
