import { Component, Input, OnInit } from "@angular/core";
import { Product } from "src/app/core/models/product.model";

@Component({
    selector: 'app-suggestion-v2',
    templateUrl: './suggestion.component.html',
    styleUrls: ['./suggestion.component.scss']
})
export class SuggestionComponent {
    
    @Input() product: Product;
    @Input() productDescEnable: boolean = false;
  
}