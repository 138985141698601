import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AllergenAdapter } from '../../models/adapter/allergen.adapter';
import { Allergen } from '../../models/allergen.model';
import { ApiUrl } from './api.url';

@Injectable({
    providedIn: 'root'
})
export class AllergenService {

    constructor(
        protected http: HttpClient,
        protected allergenAdapter: AllergenAdapter
    ) {}

    public getAllergens(hash: string): Observable<Allergen[]> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_ALLERGEN}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return response.map(item => this.allergenAdapter.adapt(item));
            }),
        );
    }
}
