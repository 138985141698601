import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface FooterItem {
  name: string,
  title: string,
  picto: {
    width: number,
    height: number,
    name: string
  }
}

export interface FooterOptions {
  info?: boolean,
  card?: boolean,
  cards?: boolean,
  cc?: boolean,
  book?: boolean,
  shopcart?: boolean,
  itineraire?: boolean,
  tel?: boolean,
  whatsapp?: boolean,
  connect?: boolean,
  account?: boolean
}

@Component({
	selector: 'app-footer-v2',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public items: FooterItem[] = [
    {name: 'info', title: 'v2.components.footer.info_tab', picto: 
      {
        width: 60,
        height: 20,
        name: 'access-infos'
      }
    },
    {name: 'card', title: 'v2.components.footer.qrcode_tab', picto: 
      {
        width: 60,
        height: 20,
        name: 'access-qrcodes'
      }
    },
    {name: 'cards', title: 'v2.components.footer.qrcodes_tab', picto: 
      {
        width: 60,
        height: 20,
        name: 'access-qrcodes'
      }
    },
    {name: 'cc', title: 'v2.components.footer.clickgo_tab', picto: 
      {
        width: 60,
        height: 30,
        name: 'logo-click-go-vertical'
      }
    },
    {name: 'book', title: 'v2.components.footer.book_tab', picto: 
      {
        width: 60,
        height: 20,
        name: 'access-reserver'
      }
    },
    {name: 'shopcart', title: 'v2.components.footer.cart_tab', picto: 
      {
        width: 60,
        height: 20,
        name: 'access-shop-cart'
      }
    },
    {name: 'itineraire', title: 'v2.components.footer.map_tab', picto: 
      {
        width: 80,
        height: 20,
        name: 'access-pin'
      }
    },
    {name: 'tel', title: 'v2.components.footer.phone_tab', picto: 
      {
        width: 60,
        height: 20,
        name: 'access-appeler'
      }
    },
    {name: 'whatsapp', title: 'v2.components.footer.whatsapp_tab', picto: 
      {
        width: 60,
        height: 20,
        name: 'access-whatsapp'
      }
    },
    {name: 'connect', title: 'v2.components.footer.login_tab', picto: 
      {
        width: 60,
        height: 20,
        name: 'access-profil'
      }
    },
    {name: 'account', title: 'v2.components.footer.account_tab', picto: 
      {
        width: 60,
        height: 20,
        name: 'access-profil'
      }
    }
  ]

  @Input('cartLength') cartLength: number;
  @Input('options') options: FooterOptions;
  @Input('active') active: string;
  @Input('data') data: {[key: string]: any} = {};

  @Output('clickItem') onClickItem: EventEmitter<FooterItem>;
  
  constructor() {
    this.onClickItem = new EventEmitter<FooterItem>()
  }

  clickItemMenu(e, item) {
    e.preventDefault();
    this.onClickItem.emit(item);
  }

}
