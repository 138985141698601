import { Injectable } from "@angular/core";
import { Adapter } from "src/app/core/models/adapter/adapter";
import { Order } from "../order.model";

@Injectable({
    providedIn: "root",
})
export class OrderAdapter implements Adapter<Order> {
    adapt(item: any): Order {
        return new Order(
            item.id,
            item.customerId,
            item.clientId,
            item.qrcodeMenuId,
            item.status,
            item.order,
            item.comment,
            item.delivery,
            item.deliveryCosts,
            item.deliveryDetails,
            item.orderType,
            item.collectionPoint,
            item.total,
            item.tablenbr,
            item.createdAt,
            item.updatedAt,
            item.customer,
            item.qrcodeMenu,
            item.details,
            item.items,
            item.orderAt
        );
    }
}