import { Menu } from "./menu.model";
import { ProductGroupsOptions } from "./product-groups-options.model";
import { ProductPrice } from "./product-price.model";
import { Product } from "./product.model";

export enum ItemType {
    simple = "simple",
    multiprice = "multiprice",
    groupOption = "groupOption",
    both = "both",
    menu = "menu"
}

export class ShopItem {
    id: string;
    product?: Product;
    menu?: Menu;
    price?: ProductPrice;
    groupOptions: ProductGroupsOptions[];
    menuProducts: Product[];
    menuGroupOptions: ProductGroupsOptions[];
    type: ItemType;
    qt: number;

    constructor(
        id: string, 
        product: Product, 
        menu: Menu, 
        price: ProductPrice, 
        groupOptions: ProductGroupsOptions[], 
        menuProducts: Product[], 
        menuGroupOptions: ProductGroupsOptions[], 
        type: ItemType, 
        qt: number
    ) {
        this.id = id;
        this.product = product;
        this.menu = menu;
        this.price = price;
        this.groupOptions = groupOptions;
        this.menuProducts = menuProducts;
        this.menuGroupOptions = menuGroupOptions;
        this.type = type;
        this.qt = qt;
    }
}