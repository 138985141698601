import { Injectable } from "@angular/core";
import { CustomTextClient } from "../custom-text-client.model";
import { Adapter } from "./adapter";

@Injectable({
    providedIn: "root",
})
export class CustomTextClientAdapter implements Adapter<CustomTextClient> {

    adapt(item: any): CustomTextClient {
        
        return new CustomTextClient(
            item.id,
            item.name,
            item.text
        );
    }
}