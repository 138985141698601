import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  CountryISO,
  SearchCountryField
} from "ngx-intl-tel-input";
import { Client } from 'src/app/core/models/client.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { VersionService } from 'src/app/core/services/api/version.service';
import { RouterService } from 'src/app/core/services/router.service';

@Component({
	selector: 'app-verification',
	templateUrl: './verification.component.html',
	styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  public verificationForm: FormGroup;
  public newCode = false;
  public verificationError = false;
  public phoneNumber: any;
  public version: string = 'v2';

  public separateDialCode = false;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public preferredCountries: CountryISO[] = [
    CountryISO.France,
    CountryISO.Belgium,
    CountryISO.Netherlands,
    CountryISO.UnitedKingdom,
    CountryISO.Germany,
    CountryISO.Spain,
    CountryISO.Italy,
    CountryISO.Portugal
  ];

  @Input() client: Client;
  @Input() qrcode: Qrcode;
  @Input() hash: string;
  @Input() code: boolean;
  @Input() phone: boolean;
  @Input() logged: boolean;

  constructor(
		protected route: ActivatedRoute,
    protected routerService: RouterService,
    protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
    protected authService: AuthService,
    protected versionService: VersionService,
    protected formBuilder: FormBuilder
	) {}

  ngOnInit() {
    this.verificationForm = this.formBuilder.group({
      phone: ['', [Validators.required]],
      code: ['', [Validators.required]],
    });
	}

  public verificationUser() {

    if (this.verificationForm.get('code').value === this.authService.getVerifCode()) {

      localStorage.setItem('verifCode', '');
      this.routerService.navigate(['/', this.version, 'click-go', this.hash, 'order']);


    } else {

      this.verificationError = true;

    }
  }

  public resendCode() {

    this.verificationError = false;

    if(!this.verificationForm.get('phone').value) return;

    this.authService.resendCode(
      this.verificationForm.get('phone').value.e164Number,
      this.authService.getCustomerId() ).subscribe(response => {

        if(response.phone) localStorage.setItem('phone', response.phone);
        if(response.verifCode) localStorage.setItem('verifCode', response.verifCode);

        this.newCode = true;

    });

  }

}
