export class DeliveryZone {
    
    public id: number;
    public name: string; 
    public description: string;
    public delivery: string; 
    public position: number;
    public deliveryTime?: number; 
    public minOrder?: number;
    public freeDelivery?: number;
    public fixedDeliveryCost?: number;

    constructor(
        id: number,
        name: string, 
        description: string, 
        delivery: string, 
        position: number,
        deliveryTime?: number, 
        minOrder?: number, 
        freeDelivery?: number, 
        fixedDeliveryCost?: number, 

    ) {
        this.id = id;
        this.name = name; 
        this.description = description; 
        this.delivery = delivery; 
        this.position = position;
        this.deliveryTime = deliveryTime;
        this.minOrder = minOrder;
        this.freeDelivery = freeDelivery;
        this.fixedDeliveryCost = fixedDeliveryCost;
    }
}