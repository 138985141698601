import { OrderDetailsRequest } from "./order-details.request";
import { OrderItemRequest } from "./order-item.request";

export class OrderRequest {
    customerId: number;
    clientId: number;
    qrcodeMenuId: number;
    status: string;
    order: string;
    comment: string;
    delivery: string;
    deliveryCosts: string;
    deliveryDetails: string;
    orderType: string;
    collectionPoint: number;
    total: number;
    tablenbr: string;
    email: string;
    details: OrderDetailsRequest;
    items: OrderItemRequest[];
    token: string;
    onbehalof: string;
    currency: string;
    orderAt: string;

    constructor(
        customerId: number,
        clientId: number,
        qrcodeMenuId: number,
        status: string,
        order: string,
        comment: string,
        delivery: string,
        deliveryCosts: string,
        deliveryDetails: string,
        orderType: string,
        collectionPoint: number,
        total: number,
        tablenbr: string,
        email: string,
        details: OrderDetailsRequest,
        items: OrderItemRequest[],
        token: string,
        onbehalof: string,
        currency: string,
        orderAt: string
    
    ) {
        this.customerId = customerId;
        this.clientId = clientId;
        this.qrcodeMenuId = qrcodeMenuId;
        this.status = status;
        this.order = order;
        this.comment = comment;
        this.delivery = delivery;
        this.deliveryCosts = deliveryCosts;
        this.deliveryDetails = deliveryDetails;
        this.orderType = orderType;
        this.collectionPoint = collectionPoint;
        this.total = total;
        this.tablenbr = tablenbr;
        this.email = email;
        this.details = details;
        this.items = items;
        this.token = token;
        this.onbehalof = onbehalof;
        this.currency = currency;
        this.orderAt = orderAt;
    }
}