import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Client } from 'src/app/core/models/client.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { CustomerService } from 'src/app/core/services/api/customer.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { OverlayService } from 'src/app/core/services/overlay.service';

@Component({
	selector: 'app-edit-password',
	templateUrl: './edit-password.component.html',
	styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {
	
	public editPasswordForm: FormGroup;
	public loading: boolean;
	public errorPwd: boolean;
	public errorWrongPwd: boolean;
	public successPwd: boolean;

	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() hash: string;
	
	constructor(
		protected fb: FormBuilder,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected authService: AuthService,
		protected customerService: CustomerService,
		protected overlayService: OverlayService
	) {
	}

	ngOnInit(): void {
		this.loading = false;
		this.errorPwd = false;
		this.errorWrongPwd = false;
	
		this.editPasswordForm = this.fb.group({
			newPassword: [null, Validators.required],
			oldPassword: [null, Validators.required],
			confirm: [null, Validators.required]
		});
	}

	close(e: any) {
		e.preventDefault();
		this.overlayService.close()
	}

	saveForm(e: any) {
		e.preventDefault();
		this.errorPwd = false;
		this.successPwd = false;

		this.errorWrongPwd = false;
		const {newPassword, oldPassword, confirm} = this.editPasswordForm.value;

		this.loading = true;

		if(this.editPasswordForm.valid) {

			if(newPassword !== confirm) { 
				this.errorPwd = true;
				this.loading = false;
				return;
				
			} else {
				this.authService.editPassword(this.authService.getCustomerId(), oldPassword, newPassword).pipe(
					finalize(() => this.loading = false)

				).subscribe(v => {
					if(!v) this.errorWrongPwd = true;
					else this.successPwd = true;

				})
			}	

			
		}
	}
}
