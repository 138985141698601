export class CustomerAddressRequest {

    name: string;
    address: string;
    zipcode: string;
    city: string;
    country: string;
    longitude: string;
    latitude: string;
    favorite: boolean;
    customerId: number;

    constructor(
        name: string,
        address: string,
        zipcode: string,
        city: string,
        country: string,
        longitude: string,
        latitude: string,
        favorite: boolean,
        customerId: number
    
    ) {
        this.name = name;
        this.address = address;
        this.zipcode = zipcode;
        this.city = city;
        this.country = country;
        this.longitude = longitude;
        this.latitude = latitude;
        this.favorite = favorite;
        this.customerId = customerId;
    }
}
  