import { Injectable } from "@angular/core";
import { ProductGroupsOptions } from "../product-groups-options.model";
import { ProductPrice } from "../product-price.model";
import { Product } from "../product.model";
import { Adapter } from "./adapter";

@Injectable({
    providedIn: "root",
})
export class ProductAdapter implements Adapter<Product> {
    adapt(item: any): Product {

        let prices = (item.prices) 
        ? item.prices.map(subitem => this.adaptPrice(subitem))
            .sort((a, b) => (a.position > b.position) ? 1 : -1) 
        : [];

        let groupOptions = (item.groupsoptions) 
        ? item.groupsoptions.map(subitem => this.adaptGroupOptions(subitem))
            .sort((a, b) => (a.position > b.position) ? 1 : -1) 
        : [];

        let menuGroupOptions = (item.menugroupsoptions) 
        ? item.menugroupsoptions.map(subitem => this.adaptGroupOptions(subitem))
            .sort((a, b) => (a.position > b.position) ? 1 : -1) 
        : [];

        return new Product(
            item.id,
            item.position,
            item.name,
            item.ingredient,
            item.pictureUrl,
            item.descriptionPictureUrl,
            item.nutriscore,
            item.price,
            item.priceIndex,
            item.cartPrice,
            item.alergen,
            item.isEnable,
            item.isPromote,
            item.isHomemade,
            item.isMultiprice,
            item.isVegan,
            item.isVegetarian,
            item.isBio,
            item.scheduling,
            item.isScheduled,
            item.is18YearOld,
            item.availability,
            item.famillyId,
            item.menuStepId,
            prices,
            groupOptions,
            menuGroupOptions
        );
    }

    adaptPrice(item: any): ProductPrice {
        return new ProductPrice(item.id, item.position, item.price, item.name);
    }

    adaptGroupOptions(item: any): ProductGroupsOptions {
      return new ProductGroupsOptions(item.id, item.position, item.parentId, item.name, item.isDefault, item.price);
    }
}
