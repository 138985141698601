export class ProductPrice {

    id: number;
    position: number;
    price: string;
    name: string;

    constructor(id: number, position: number, price: string, name: string) {
        this.id = id;
        this.price = price;
        this.position = position;
        this.name = name;
    }
}
