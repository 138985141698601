export class Client {

    id: number;
    name: string;
    websiteUrl: string;
    reservationUrl: string;
    facebookUrl: string;
    twitterUrl: string;
    instagramUrl: string;
    youtubeUrl: string;
    tripadvisorUrl: string;
    whatsAppLink: string;
    hours: string;
    phone: string;
    address: string;
    city: string;
    zipcode: string;
    country: string;
    qrcodes: number;
    color1: string;
    color2: string;
    color3: string;
    color4: string;
    color5: string;
    color6: string;
    color7: string;
    color8: string;
    theme: number;
    logoUrl: string;
    languageId: number;
    isEnable: boolean;
    createdAt: Date;

    constructor(
        id: number,
        name: string,
        websiteUrl: string,
        reservationUrl: string,
        facebookUrl: string,
        twitterUrl: string,
        instagramUrl: string,
        youtubeUrl: string,
        tripadvisorUrl: string,
        whatsAppLink: string,
        hours: string,
        phone: string,
        address: string,
        city: string,
        zipcode: string,
        country: string,
        qrcodes: number,
        color1: string,
        color2: string,
        color3: string,
        color4: string,
        color5: string,
        color6: string,
        color7: string,
        color8: string,
        theme: number,
        languageId: number,
        logoUrl: string,
        isEnable: boolean,
        createdAt: string) {

        this.id = id;
        this.name = name;
        this.websiteUrl = websiteUrl;
        this.reservationUrl = reservationUrl;
        this.facebookUrl = facebookUrl;
        this.twitterUrl = twitterUrl;
        this.instagramUrl = instagramUrl;
        this.youtubeUrl = youtubeUrl;
        this.tripadvisorUrl = tripadvisorUrl;
        this.whatsAppLink = whatsAppLink;
        this.hours = hours;
        this.phone = phone;
        this.address = address;
        this.city = city;
        this.zipcode = zipcode;
        this.country = country;
        this.qrcodes = qrcodes;
        this.color1 = color1;
        this.color2 = color2;
        this.color3 = color3;
        this.color4 = color4;
        this.color5 = color5;
        this.color6 = color6;
        this.color7 = color7;
        this.color8 = color8;
        this.theme = theme;
        this.logoUrl = logoUrl;
        this.languageId = languageId;
        this.isEnable = isEnable;
        this.createdAt = new Date(createdAt);
    }

    public getHours(): string[] {
        return this.hours ? this.hours.split(/(?:\r\n|\r|\n)/) : [];
    }

    public getAddressForGoogle(): string {
        return `${this.address.replace(' ', '+')}+${this.zipcode}+${this.city}`;
    }
}