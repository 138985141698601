import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerAddressRequest } from 'src/app/core/models/requests/customer-address.request';
import { Client } from 'src/app/core/models/client.model';
import { CustomerAddress } from 'src/app/core/models/customer-address.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { GoogleMapService } from 'src/app/core/services/google-map.service';
import { Subscription } from 'rxjs';
import { CustomerAddressAdapter } from 'src/app/core/models/adapter/customer-address.adapter';

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {
	
	public addressForm: FormGroup;
	public autoCompleteOptions =  {
		//componentRestrictions: { country: ["fr", "be", "de", "nl", "it", "es", "pt", "ch", "at", "dk"] },
		fields: ["address_components", "formatted_address", "geometry", "icon", "name"],
		types: ["geocode"]
	};

	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() address: CustomerAddress;
	@Input() hash: string;
	@Input() edit: boolean = false;

	@Output() onSaveForm: EventEmitter<CustomerAddressRequest>;
	@Output() onEditForm: EventEmitter<CustomerAddressRequest>;

	public apiloaded = false;
	public subscribe: Subscription;
	public hasNoNumber: boolean;
	public invalidSearchAddress: boolean = false;

	constructor(
		protected fb: FormBuilder,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected overlayService: OverlayService,
		protected googleMapService: GoogleMapService,
		protected customerAddressAdapter: CustomerAddressAdapter
	) {
		this.onSaveForm = new EventEmitter<CustomerAddressRequest>()
		this.onEditForm = new EventEmitter<CustomerAddressRequest>()
	}

	ngOnDestroy(): void {
		this.subscribe.unsubscribe();
	}

	ngOnInit(): void {
		this.addressForm = this.fb.group({
			name: [null, Validators.required],
			city: [null, Validators.required],
			country: [null, Validators.required],
			address: [null, Validators.required],
			zipcode: [null,  Validators.required],
			latitude: [null,  Validators.required],
			longitude: [null,  Validators.required]

		});

		if(this.edit && this.address) {
			this.addressForm.patchValue({
				name: this.address.name,
				city: this.address.city,
				country: this.address.country,
				address: this.address.address,
				zipcode: this.address.zipcode,
				latitude: this.address.latitude,
				longitude: this.address.longitude
			});
		}

		this.googleMapService.load().subscribe();
		this.subscribe = this.googleMapService.isLoaded().subscribe(v => this.apiloaded = v)
	}

	placeChanged(place) {
		this.addressForm.patchValue({
			latitude: null,
			longitude: null
		})

		this.invalidSearchAddress = false;
		this.hasNoNumber = false;

		if(!(place && place.geometry)) {
			this.invalidSearchAddress = true;
			return;
		}

		const {lat, lng} = place.geometry.location;
		const {address_components} = place;

		if(!address_components.find(e => e.types.includes('street_number'))) {
			this.hasNoNumber = true;
			return;
		}

		const values = ['route', 'locality', 'country', 'postal_code', 'street_number'].map(e => {
			const v = address_components.find(e2 => e2.types.includes(e));
			if(v) return v;
			return null;
		})
		
		this.addressForm.patchValue({
			address: values[0] ? values[0].long_name + (values[4] ? ", " + values[4].long_name : "") : this.addressForm.value.address,
			city: values[1] ? values[1].long_name : this.addressForm.value.city,
			country: values[2] ? values[2].long_name : this.addressForm.value.country,
			zipcode: values[3] ? values[3].long_name : this.addressForm.value.zipcode,
			latitude: lat(),
			longitude: lng()
		})
	}

	close(e: any) {
		e.preventDefault();
		this.overlayService.close()
	}

	getInvalids() {
		return Object.keys(this.addressForm.controls).reduce((acc, curr) => {
			if(this.addressForm.controls[curr].errors && Object.keys(this.addressForm.controls[curr].errors).length) acc= [...acc, curr]
			return acc;
		}, [])
	}

	saveForm(e: any) {
		e.preventDefault();

		this.invalidSearchAddress = false;
		this.hasNoNumber = false;

		if(this.addressForm.valid) {
			const {
				value: {
					address, 
					city, 
					country, 
					zipcode
				}

			} = this.addressForm;

			this.googleMapService.getCoordsByAddress(`${address}, ${zipcode}  ${city}, ${country}`).subscribe(
				v => {
	
					if(v.results 
						&& v.results.length 
						&& v.results[0].geometry 
						&& v.results[0].geometry.location) {
	
						const {lat, lng} = v.results[0].geometry.location;
						const {address_components} = v.results[0];

						if(!address_components.find(e => e.types.includes('street_number'))) {
							this.hasNoNumber = true;
							return;
						}
						const valid = ['route', 'locality', 'country', 'postal_code', 'street_number'].every(e => address_components.find(e2 => e2.types.includes(e)))
						
						if(!valid) this.invalidSearchAddress = true;
						else {
							this.addressForm.patchValue({
								latitude: lat,
								longitude: lng
							});
							
							this.overlayService.close({edit: this.edit, id: this.address ? this.address.id : null, form: this.addressForm.value})
						}
						
					} else this.invalidSearchAddress = true;
				}
			)
		}
	}
}
