import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class RouterService {

  constructor(protected router: Router) {}

  navigate(values: any[]) {
    this.router.navigate(values)
  }

  getRouter(): Router {
    return this.router;
  }
}
