import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ItemOrderProduct } from "src/app/core/models/item-order-product.model";
import { ProductGroupsOptions } from "src/app/core/models/product-groups-options.model";
import { Product } from "src/app/core/models/product.model";
import { ModalService } from "src/app/core/services/modal.service";
import { ShopCartService } from "src/app/core/services/shop-cart.service";
import { ModalRemoveProductComponent } from "../../modal/modal-remove-product/modal-remove-product.component";

@Component({
    selector: 'app-order-product',
    templateUrl: './order-product.component.html',
    styleUrls: ['./order-product.component.scss']
})
export class OrderProductComponent implements OnInit {

    @Input() currency: string = '€';
    @Input() product: Product;
    @Input() onSaveOrder: boolean = false;
    @Input() productDescEnable: boolean = false;
    @Input() item: ItemOrderProduct;
    @Input() index: number;

    @Output('productAction') onSelectProduct: EventEmitter<number>;
   
    public imageUrl: string;
    public descImageUrl: string;

    public childGrouptions: ProductGroupsOptions[] = [];

    constructor(protected shopCartService: ShopCartService,
        protected modalService: ModalService) {
        this.onSelectProduct = new EventEmitter();
    }
    
    ngOnInit() {
        if (this.product.pictureUrl) {
            this.imageUrl = `url(${this.product.pictureUrl})`
        }

        if (this.product.descriptionPictureUrl) {
            this.descImageUrl = `url(${this.product.descriptionPictureUrl})`
        }
        
        if(this.product && this.item.shopItem.groupOptions && this.item.shopItem.groupOptions.length > 0) {
            this.childGrouptions = this.product.groupOptions.filter(f => f.parentId
                && this.item.shopItem.groupOptions.map(({id}) => id).includes(f.id));
        }
    }

    remove() {
        if(this.onSaveOrder) return;

        const removeProduct = () => this.shopCartService.removeProduct(
            this.product, 
            this.item.shopItem.price, 
            this.item.shopItem.groupOptions,
        )

        const product = this.item.shopItem;

        if(this.shopCartService.getProductQuantity(this.product, this.item.shopItem.price, this.item.shopItem.groupOptions) < 2) {
            this.modalService.open(ModalRemoveProductComponent, {
                product,
                currency: this.currency

              }, removeProduct, () => {});

        } else removeProduct()
    }

    add() {
        if(this.onSaveOrder) return;
        this.shopCartService.addProduct(
            this.product, 
            this.item.shopItem.price, 
            this.item.shopItem.groupOptions,
        )
    }

    removeAll() {
        if(this.onSaveOrder) return;

        const product = this.item.shopItem;

        this.modalService.open(ModalRemoveProductComponent, {
            product,
            currency: this.currency

          }, () => this.shopCartService.setProductQuantity(
            0,
            this.product, 
            this.item.shopItem.price, 
            this.item.shopItem.groupOptions,
        ), () => {});
    }

    productAction(index: number) {
        if(this.onSaveOrder) return;
        this.onSelectProduct.emit(index)
    }

    getTotal(item) {
        return item.qt * parseFloat(item.price.replace(',',  '.'));
    }
}
