import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShopItemAdapter } from '../models/adapter/shop-item.adapter';
import { ProductGroupsOptions } from '../models/product-groups-options.model';
import { ProductPrice } from '../models/product-price.model';
import { Product } from '../models/product.model';
import { ShopCart } from '../models/shop-cart.model';
import { ItemType, ShopItem } from '../models/shop-item.model';
import * as uuid from 'uuid/v4';
import { Menu } from '../models/menu.model';

@Injectable({
    providedIn: 'root'
})
export class ShopCartService {

    protected useLocalStorage: boolean = false;
    protected $hash: BehaviorSubject<string>
    protected $cart: BehaviorSubject<ShopCart>
    protected name: string = 'accessmenuclickgocart';
    protected totalCart: number = 0;

    // contructor
    constructor(protected shopItemAdapter: ShopItemAdapter) {
        this.$hash = new BehaviorSubject<string>(null);
        this.$cart = new BehaviorSubject<ShopCart>(null);
    }

    /**
     * Cahnge le nom du cookie (différencier la partie à table ou emporter)
     * 
     * @param {string} value - nouveau nom du cookie
     */
    changeName(value: string) {
        this.name = value;
    }

    /**
     * Défini le hash
     * 
     * @param {string} hash 
     */
     setHash(hash: string) {
        let newShopCart = true;
        this.$hash.next(hash);

        const item = this.useLocalStorage 
        ? localStorage.getItem(this.name)
        : sessionStorage.getItem(this.name);

        if(item) {
            const values = JSON.parse(item);
            
            if(values && values[hash])  {
                const cart = new ShopCart(hash, values[hash].map(item => this.shopItemAdapter.adapt(item)));
                this.$cart.next(cart);
                newShopCart = false;
            }
        }

        // init new shop cart
        if(newShopCart) {
            const cart = new ShopCart(hash, []);
            this.$cart.next(cart);
        }
    }

    /**
     * Récupère le hash en observable
     * 
     * @returns {Observable.<string>} - Renvoie un observable du hash
     */
    getHash(): Observable<string> {
        return this.$hash.asObservable();
    }

    /**
     * Récupère le panier en observable
     * 
     * @returns {Observable.<ShopCart>} - Renvoie un observable de le panier
     */
    getShopCart(): Observable<ShopCart> {
        return this.$cart.asObservable()
    }

    /**
     * Rajoute au panier
     * 
     * @param {Product} product
     * @param {ProductPrice} price
     * @param {Array.<ProductGroupsOptions>} groupOptions 
     * @param {number} qt 
     */
    addProduct(product: Product, price?: ProductPrice, groupOptions: ProductGroupsOptions[] = [], qt: number = 1) {
        const cart = this.$cart.value;
        const type = price && groupOptions.length ? ItemType.both
            : (price ? ItemType.multiprice 
                : (groupOptions.length ? ItemType.groupOption : ItemType.simple));

        const selItem = this.findProduct(product, price, groupOptions);

        if(selItem) {
            // exists => add +1 qt
            if(typeof(selItem.qt) === 'undefined' || selItem.qt === null) selItem.qt = 0;
            selItem.qt += qt;

        } else if(qt > 0) {
            // add new
            cart.items = [...cart.items, new ShopItem(uuid(), product, null, price, groupOptions, null, null, type, qt)];
        }

        this.save(cart.items)
    }

    /**
     * Rajoute au panier
     * 
     * @param {Menu} menu
     * @param {Product[]} products
     * @param {Array.<ProductGroupsOptions>} groupOptions 
     * @param {number} qt 
     */
     addMenu(menu: Menu, products: Product[] = [], groupOptions: ProductGroupsOptions[] = [], qt: number = 1) {
        const cart = this.$cart.value;
        const selItem = this.findMenu(menu, products, groupOptions);
        const type = ItemType.menu;

        if(selItem) {
            // exists => add +1 qt
            if(typeof(selItem.qt) === 'undefined' || selItem.qt === null) selItem.qt = 0;
            selItem.qt += qt;

        } else if(qt > 0) {
            // add new
            cart.items = [...cart.items, new ShopItem(uuid(), null, menu, null, null, products, groupOptions, type, qt)];
        }

        this.save(cart.items)
    }

    /**
     * Retire du panier
     * 
     * @param {Product} product
     * @param {ProductPrice} price
     * @param {Array.<ProductGroupsOptions>} groupOptions 
     * @param {number} qt 
     */
    removeProduct(product: Product, price?: ProductPrice, groupOptions: ProductGroupsOptions[] = [], qt: number = 1) {
        const cart = this.$cart.value;

        const selItem = this.findProduct(product, price, groupOptions);

        if(selItem) {
            // exists => -1 qt
            selItem.qt = (selItem.qt - (qt - 1) > 0 ? selItem.qt - qt : 0);
            this.save(cart.items.filter(it => it.qt > 0))
        }
    }

    /**
     * Retire du panier
     * 
     * @param {Menu} menu
     * @param {Product[]} products
     * @param {Array.<ProductGroupsOptions>} groupOptions 
     * @param {number} qt 
     */
     removeMenu(menu: Menu, products: Product[] = [], groupOptions: ProductGroupsOptions[] = [], qt: number = 1) {
        const cart = this.$cart.value;

        const selItem = this.findMenu(menu, products, groupOptions);

        if(selItem) {
            // exists => -1 qt
            selItem.qt = (selItem.qt - (qt - 1) > 0 ? selItem.qt - qt : 0);
            this.save(cart.items.filter(it => it.qt > 0))
        }
    }

    /**
     * Change la quantité
     * 
     * @param {number} qt  
     * @param {Product} product
     * @param {ProductPrice} price
     * @param {Array.<ProductGroupsOptions>} groupOptions 
     */
    setProductQuantity(qt: number = 1, product: Product, price?: ProductPrice, groupOptions: ProductGroupsOptions[] = []) {
        const cart = this.$cart.value;
    
        const selItem = this.findProduct(product, price, groupOptions);

        if(selItem) {
            selItem.qt = qt;
            this.save(cart.items.filter(it => it.qt > 0))
        } 
    }

     /**
     * Récupère la quantité
     * 
     * @param {Product} product
     * @param {ProductPrice} price
     * @param {Array.<ProductGroupsOptions>} groupOptions
     * 
     * @returns {number} quantité 
     */
    getProductQuantity(product: Product, price?: ProductPrice, groupOptions: ProductGroupsOptions[] = []) {
        const selItem = this.findProduct(product, price, groupOptions);
        return  selItem && selItem.qt ? selItem.qt : 0;
      
    }


    /**
     * Change la quantité
     * 
     * @param {number} qt  
     * @param {Menu} menu
     * @param {Product[]} products
     * @param {Array.<ProductGroupsOptions>} groupOptions 
     */
     setMenuQuantity(qt: number = 1, menu: Menu, products: Product[] = [], groupOptions: ProductGroupsOptions[] = []) {
        const cart = this.$cart.value;
    
        const selItem = this.findMenu(menu, products, groupOptions);

        if(selItem) {
            selItem.qt = qt;
            this.save(cart.items.filter(it => it.qt > 0))
        } 
    }

     /**
     * Récupère la quantité
     * 
     * @param {Menu} menu
     * @param {Product[]} products
     * @param {Array.<ProductGroupsOptions>} groupOptions 
     * 
     * @returns {number} quantité 
     */
    getMenuQuantity(menu: Menu, products: Product[] = [], groupOptions: ProductGroupsOptions[] = []) {
        const selItem = this.findMenu(menu, products, groupOptions);
        return  selItem && selItem.qt ? selItem.qt : 0;
      
    }


    /**
     * Retire tout les produits du panier
     * 
     * @param {number} productId 
     */
     removeAllProduct(productId: number) {
        const cart = this.$cart.value;
        const selItems = cart.items.filter(i => i.product && i.product.id === productId);

        if(selItems.length > 0) {
            // exists => -1 qt
            selItems.forEach(item => item.qt = 0);
            this.save(cart.items.filter(it => it.qt > 0))
        }
    }

    /**
     * Retire tout les menus du panier
     * 
     * @param {number} menuId 
     */
     removeAllMenu(menuId: number) {
        const cart = this.$cart.value;
        const selItems = cart.items.filter(i => i.menu && i.menu.id === menuId);

        if(selItems.length > 0) {
            // exists => -1 qt
            selItems.forEach(item => item.qt = 0);
            this.save(cart.items.filter(it => it.qt > 0))
        }
    }

    /**
     * Récupère le nombre d'éléments
     * 
     * @returns {number} - Renvoie le nombre de produit dans le panier
     */
    length(): number {
        const {value} = this.$cart;

        if(!value) return 0;

        return value.items.reduce((acc, curr) => {
            return acc + curr.qt;
        }, 0);
    }

    /**
     * Récupère le nombre d'éléments du produit
     * 
     * @returns {number} - Renvoie le nombre de produit
     */
     getProductLength(productId: number): number {
        const {value} = this.$cart;

        if(!value) return 0;

        const products = value.items.filter(p => p.product && p.product.id === productId);

        if(products.length < 1) return 0;
        return products.reduce((acc, curr) => acc + curr.qt, 0);
    }

    /**
     * Récupère le nombre d'éléments du menu
     * 
     * @returns {number} - Renvoie le nombre de menu
     */
     getMenuLength(menuId: number): number {
        const {value} = this.$cart;

        if(!value) return 0;

        const menus = value.items.filter(m => m.menu && m.menu.id === menuId);

        if(menus.length < 1) return 0;
        return menus.reduce((acc, curr) => acc + curr.qt, 0);
    }

    /**
     * Vide le panier
     */
    empty(): void {
        this.save([])
    }
   
    /**
     * Sauvegarde le panier
     * 
     * @param {Array.<ShopItem>} values 
     */
    save(values: ShopItem[]): void {
        const cart = this.$cart.value;
        cart.items = values;
        const json = JSON.parse(this.useLocalStorage ? localStorage.getItem(this.name) : sessionStorage.getItem(this.name));

        if(json) {
            let toStore = JSON.stringify({...json, [this.$hash.value]: values});
            this.useLocalStorage ? 
                localStorage.setItem(this.name, toStore) 
                : sessionStorage.setItem(this.name, toStore);

        } else {
            let toStore = JSON.stringify({[this.$hash.value]: values})
            this.useLocalStorage ? 
                localStorage.setItem(this.name, toStore)  
                : sessionStorage.setItem(this.name, toStore);
        }
        
        this.$cart.next(cart);
    }

    /**
     * Défini le panier total
     * 
     * @param {number} value 
     */
    setTotalCart(value: number) {
      this.totalCart = value;
    }

    /**
     * Récupère la valeur du panier total
     * 
     * @returns {number} - Renvoie le panier total
     */
    getTotalCart(): number {
      return this.totalCart;
    }


    /**
     * Trouve l'objet
     * 
     * @param {Product} product
     * @param {ProductPrice} price
     * @param {Array.<ProductGroupsOptions>} groupOptions 
     * 
     * @returns {ShopItem} - si trouvé
     */
    findProduct(product: Product, price?: ProductPrice, groupOptions: ProductGroupsOptions[] = []): ShopItem {
        const cart = this.$cart.value;

        let complex = price || groupOptions.length > 0;

        return cart.items.find(i => {
            if(!(i && i.product && i.product.id)) return false;

            if(!complex) return i.product.id === product.id && !i.price && !(i.groupOptions && i.groupOptions.length > 0);

            let goIds: string = '';

            if(groupOptions.length > 0) {
                if(!(i.groupOptions && i.groupOptions.length > 0)) return false;
                goIds = i.groupOptions.map(({id}) => id).sort().join('-');
            }
                
            if(price && groupOptions.length > 0) {
                return i.product.id === product.id && i.price && i.price.id == price.id 
                && goIds === groupOptions.map(({id}) => id).sort().join('-');

            } else if(price) {
                return i.product.id === product.id && i.price && i.price.id == price.id 
                && !(i.groupOptions && i.groupOptions.length > 0);

            } else {
                return i.product.id === product.id && !i.price && goIds === groupOptions.map(({id}) => id).sort().join('-');

            }
            
        });
    }

    /**
     * Trouve l'objet
     * 
     * @param {Menu} menu
     * @param {ProductPrice} products
     * @param {Array.<ProductGroupsOptions>} groupOptions 
     * 
     * @returns {ShopItem} - si trouvé
     */
     findMenu(menu: Menu, products: Product[] = [], groupOptions: ProductGroupsOptions[] = []): ShopItem {
        const cart = this.$cart.value;

        return cart.items.find(i => {
            if(!(i && i.menu && i.menu.id)) return false;

            let goIds: string = '';
            let productIds: string = '';

            if(groupOptions.length > 0) {
                if(!(i.menuGroupOptions && i.menuGroupOptions.length > 0)) return false;
                goIds = i.menuGroupOptions.map(({id}) => id).sort().join('-');
            }

            if(products.length > 0) {
                if(!(i.menuProducts && i.menuProducts.length > 0)) return false;
                productIds = i.menuProducts.map(({id}) => id).sort().join('-');
            }  

            if(products.length > 0 && groupOptions.length > 0) {
                return i.menu.id === menu.id 
                && productIds === products.map(({id}) => id).sort().join('-')
                && goIds === groupOptions.map(({id}) => id).sort().join('-');

            } else if(products.length > 0) {
                return i.menu.id === menu.id 
                && productIds === products.map(({id}) => id).sort().join('-')
                && !(i.menuGroupOptions && i.menuGroupOptions.length > 0);

            } else {
                return i.menu.id === menu.id 
                && !(i.menuProducts && i.menuProducts.length > 0)
                && goIds === groupOptions.map(({id}) => id).sort().join('-');

            }
        });
    }
}
