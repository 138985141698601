import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { Client } from 'src/app/core/models/client.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { ClientService } from 'src/app/core/services/api/client.service';
import { GoogleLoginProvider, FacebookLoginProvider, SocialUser, SocialAuthService } from 'angularx-social-login';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { ProcessService } from 'src/app/core/services/process.service';
import { VersionService } from 'src/app/core/services/api/version.service';
import { AppStateService } from 'src/app/core/services/app-state.service';
import { RouterService } from 'src/app/core/services/router.service';
import { CustomerService } from 'src/app/core/services/api/customer.service';
import { environment } from 'src/environments/environment';
import jwt_decode  from 'jwt-decode';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public loading = false;
  public registerForm: FormGroup;
  public loginError = false;
  public externalError = false;
  public registerError = false;
  public registerError2 = false;
  public horaireOpen = false;
  public loggedIn: any;
  public login = true;
  public register = false;
  public validPhone: boolean = false;
  public fakePassword = ''; 
  public version: string = 'v2';
  public success: boolean = false;
  
  @Input() table:string = 'any'; 
  @Input() hash: string;
  @Input() client: Client;
  @Input() from: string;
  @Input() qrcode: Qrcode;
  @Input() edit: boolean = false;

  constructor(
		protected route: ActivatedRoute,
    protected customerService: CustomerService,
    protected routerService: RouterService,
    protected authService: AuthService,
    protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
    protected formBuilder: FormBuilder,
    protected overlayService: OverlayService,
    protected socialAuthService: SocialAuthService,
    protected processService: ProcessService,
    protected versionService: VersionService,
    protected appStateService: AppStateService
	) {}

  ngOnInit() {
    const baseGroup = {
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      fullPhone: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required])],
    };

    this.registerForm = this.formBuilder.group(this.edit ? baseGroup : {
      ...baseGroup,
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', Validators.required]
    });

    if(this.edit) {
      this.customerService.getCustomer(this.authService.getCustomerId()).subscribe(customer => {
        this.registerForm.patchValue({
          firstname: customer.firstname || '',
          lastname: customer.lastname || '',
          email: customer.email || '',
          phone: customer.phone || '',
        });
      })
      
      this.fakePassword = "*************";
    } 
  }

  get getConnectedBy() {
    return this.authService.getConnectedBy() && this.authService.getConnectedBy().trim();
  }

  public registerUser(from = 'click-go'): void {
    this.registerError = false;
    this.success = false;

    if(this.registerForm.invalid || !this.validPhone) return ;

    this.loading = true;

    if(this.edit) {
      const {
        firstname,
        lastname,
        email,
        fullPhone
        
      } = this.registerForm.value;
      
      this.registerUserInformation(
        firstname,
        lastname,
        email,
        fullPhone,
        null,
        localStorage.getItem('externalId'),
        localStorage.getItem('customer'),
        from
      );

    } else {
      const {
        firstname,
        lastname,
        email,
        fullPhone,
        password, 
        confirmpassword
        
      } = this.registerForm.value;

      if (password !== confirmpassword) {
        this.registerError = true;
        this.loading = false;
  
      } else {
        this.registerUserInformation(
          firstname,
          lastname,
          email,
          fullPhone,
          password,
          localStorage.getItem('externalId'),
          localStorage.getItem('customer'),
          from
        );
      }
    }
  }

  isInvalidPhone(form) {
    const errors = form && form.get('phone').errors ? form.get('phone').errors : [];
    return form.get('phone').touched && (!(errors &&  errors.length < 1) || !this.validPhone)
  }

  public registerUserInformation(firstname, lastname, email, phone, password, externalId, customerId = null, from = 'click-go', connectedBy: string = null) {
    this.loading = true;
    this.success = false;
    
    if(this.edit) {
      this.authService.resetData(firstname, lastname, email, phone, externalId, customerId)
        .subscribe(e => {          
          if(e) this.success = true;
          else this.authService.logout();
        })

    } else {
        if(!(firstname && lastname && email && password)) {
          return;
    
        }
        this.authService.register(firstname, lastname, email, phone, password, externalId)
        .subscribe(registered => {
          this.loading = false;

          if (registered) {
            this.authService.login(email, password, externalId, connectedBy).subscribe(connected => {
              if (!connected) {
                this.loginError = true;
              } else {
                this.processService.removeProcessOrder();

                if(this.appStateService.redirectAfterLoginOnOrder) {
                  this.appStateService.redirectAfterLoginOnOrder = false;
                  
                  if(from === "in")
                    this.routerService.navigate(['/', this.version, this.from, this.hash, this.table, 'order']);
                  else
                    this.routerService.navigate(['/', this.version, from, this.hash, 'order']);
                  
                } else {
                  if(from === "in")
                    this.routerService.navigate(['/', this.version, this.from, this.hash, this.table, 'list']);
                  else
                    this.routerService.navigate(['/', this.version, from, this.hash, 'list']);
                }
              }
            });

          } else {
              if (origin === 'login') {
                this.externalError = true;
              } else this.registerError2 = true;
          }
      });
    }
  }

  OnPhoneChange(region) {
		this.validPhone = region && region.hasOwnProperty('valid') ? region.valid : this.validPhone
		this.registerForm.patchValue({fullPhone: region.number})
	}

  changePwd(e: any) {
    e.preventDefault();

    this.overlayService.open(EditPasswordComponent, {
      client: this.client
    })
  }

  public onChangeRegisterData(registered, email, password, externalId, from = 'click-go') {
    if (registered) 
      this.authService.login(email, password, externalId).subscribe(connected => {
        this.loading = false;
        
        if (!connected) {
          this.loginError = true;

        } else {
          this.processService.removeProcessOrder();
          this.routerService.navigate(['/', this.version, from, this.hash, 'list'])
        }
      });
  }

  public externalLogin(provider: string, from = 'click-go'): void {
    let providerObject;

    switch(provider) {
      // case 'google' :
      //   providerObject = GoogleLoginProvider.PROVIDER_ID
      //   break;

      case 'facebook' :
        providerObject = FacebookLoginProvider.PROVIDER_ID
        break;
    }

      this.socialAuthService.signIn(providerObject).then((user: SocialUser) => {

        if (user) {
          this.authService.login(user.email, user.id, user.id, provider).pipe(
            catchError(_ => of(false))
            
          ).subscribe(connected => {
            if (!connected) {
              this.registerUserInformation(
                user.firstName,
                user.lastName,
                user.email,
                '',
                user.id,
                user.id,
                'login',
                from, 
                provider
              );

            } else {
              this.processService.removeProcessOrder();

              if(this.appStateService.redirectAfterLoginOnOrder) {
                this.appStateService.redirectAfterLoginOnOrder = false;
                
                if(from === "in")
                  this.routerService.navigate(['/', this.version, this.from, this.hash, this.table, 'order']);
                else
                  this.routerService.navigate(['/', this.version, from, this.hash, 'order']);
                
              } else {
                if(from === "in")
                  this.routerService.navigate(['/', this.version, this.from, this.hash, this.table, 'list']);
                else
                  this.routerService.navigate(['/', this.version, from, this.hash, 'list']);
              }
            }
          });
        }
    });
  }

  public signInWithGoogle(credentials, from) {
    if(!credentials) return;
    try {
      const obj: any = jwt_decode(credentials);

      this.authService.login(obj.email, obj.sub, obj.sub, 'google').pipe(
        catchError(_ => of(false))
        
      ).subscribe(connected => {
        if (!connected) {
          this.registerUserInformation(
            obj.given_name,
            obj.family_name,
            obj.email,
            '',
            obj.sub,
            obj.sub,
            'login',
            from,
            'google'
          );
        } else {
          this.processService.removeProcessOrder();
          
          if(from === "in")
            this.routerService.navigate(['/', this.version, from, this.hash, this.table, 'list']);
          else
            this.routerService.navigate(['/', this.version, from, this.hash, 'list']);
        }

      })

    } catch(e) {
      throw e
    }
  }

  get clientId() {
    return environment.googleAppId;
  }
}
