export class OrderDetailsRequest {
    stripeChargeId: string;
    stripeAccountId: string;
    stripeStatus: string;
    qrcodeName: string;
    currency: string;
    collectionPointName: string;
    collectionPointAddress: string;
    deliveryAddress: string;
    tableNumber: string;
    orderAt: string;

    constructor(
        stripeChargeId: string,
        stripeAccountId: string,
        stripeStatus: string,
        qrcodeName: string,
        currency: string,
        collectionPointName: string,
        collectionPointAddress: string,
        deliveryAddress: string,
        tableNumber: string,
        orderAt: string
    
    ) {
        this.stripeChargeId = stripeChargeId;
        this.stripeAccountId = stripeAccountId;
        this.stripeStatus = stripeStatus;
        this.qrcodeName = qrcodeName;
        this.currency = currency;
        this.collectionPointName = collectionPointName;
        this.collectionPointAddress = collectionPointAddress;
        this.deliveryAddress = deliveryAddress;
        this.tableNumber = tableNumber;
        this.orderAt = orderAt;
    }
}