import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { Family } from 'src/app/core/models/family.model';
import { ItemMenuProduct } from 'src/app/core/models/item-menu-product.model';
import { Product } from 'src/app/core/models/product.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { Client } from 'src/app/core/models/client.model';
import { VersionService } from 'src/app/core/services/api/version.service';

@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

	public swiperConfigSuggestion: SwiperOptions = {
		pagination: {
			bulletClass: 'swiperBullet',
			bulletActiveClass: 'swiperBulletActive'
		},
		autoplay: {
			delay: 3000
		},
	};

	public noImageMode = false;
	public version: string = 'v2';

	@Input() loading = false;
	@Input() family: Family;
	@Input() table: string = 'any';
	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() items: ItemMenuProduct[] = [];
	@Input() suggestions: Product[] = [];
	@Input() pageType: string = 'qrcode';
	@Input() allergens: any;

	@Output() onSuggestionClick: EventEmitter<number>;
	@Output() onProductClick: EventEmitter<number>;

	constructor(protected versionService: VersionService) {
		this.onSuggestionClick = new EventEmitter<number>()
		
		this.onProductClick = new EventEmitter<number>()
	}

	ngOnInit() {
		if (this.suggestions && this.suggestions.length == 1) {
			this.swiperConfigSuggestion.pagination = null;
		}

		this.noImageMode = this.family.products.every(product => product.pictureUrl === null || product.pictureUrl === '');
	}

	public productAction(index: number): void {
		this.onProductClick.emit(index);
	}

	public suggestionAction(index: number): void {
		this.onSuggestionClick.emit(index);
	}
}
