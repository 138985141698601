// TODO
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Client } from 'src/app/core/models/client.model';
import { CustomerAddress } from 'src/app/core/models/customer-address.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { ClientService } from 'src/app/core/services/api/client.service';
import { CustomerService } from 'src/app/core/services/api/customer.service';
import { QrCodeService } from 'src/app/core/services/api/qrcode.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { ModalCustomComponent } from '../../../modal/modal-custom/modal-custom.component';
import { AddressComponent } from './address/address.component';

@Component({
	selector: 'app-register-address-list',
	templateUrl: './address-list.component.html',
	styleUrls: ['./address-list.component.scss']
})
export class RegisterAddressListComponent implements OnInit, OnDestroy {
	public datetime: Date;

	public addresses: CustomerAddress[] = [];
	public subscribe: Subscription;
	public loading: boolean = false;
	public selectedAddress: CustomerAddress;

	@Input() client: Client;
	@Input() qrcode: Qrcode;
	@Input() hash: string;

	constructor(
		protected authService: AuthService,
		protected clientService: ClientService,
		protected qrcodeService: QrCodeService,
		protected customerService: CustomerService,
		protected overlayService: OverlayService,
		protected modalService: ModalService,
		protected fb: FormBuilder,
		protected translateService: TranslateService
	) {}
	
	ngOnInit(): void {
		this.loading = true;
		this.subscribe = this.overlayService.getDataAfterClose().pipe(
			mergeMap(data => {
				if(!this.authService.getCustomerId()) return of([]);
				
				if(data && data.form) {
					const {form} = data;
					form.favorite = false;

					form.customerId = this.authService.getCustomerId();

					return (!data.edit ? this.customerService.addAddress(form) :
					this.customerService.editAddress(data.id, form) ).pipe(
						mergeMap(_ => this.customerService.getAddresses(this.authService.getCustomerId()))
					);
				}

				return this.customerService.getAddresses(this.authService.getCustomerId())
			})

		).subscribe((addresses: CustomerAddress[]) => {
			this.loading = false;
			this.addresses = addresses;
		})
	}

	ngOnDestroy(): void {
		this.subscribe.unsubscribe();
	}

	deleteAdress(e: any, address: CustomerAddress) {
		e.preventDefault();
		this.selectedAddress = address;
		this.modalService.open(ModalCustomComponent, 
			{
				title:  this.translateService.instant('globals.warning'),
				intro: this.translateService.instant('v2.components.profile.register.address_list.delete_address'), 
				text: this.translateService.instant('v2.components.profile.register.address_list.delete_address_confirm')
			}, 
		this.deleteAdressConfirm(),
		this.deleteAdressCancel())
	}

	deleteAdressConfirm() {
		return () => {
			this.customerService.deleteAddress(this.selectedAddress.id).pipe(
				mergeMap(v => {
					this.selectedAddress = null;
					return this.customerService.getAddresses(this.authService.getCustomerId());
				})
			).subscribe(addresses => this.addresses = addresses)
		}
	}

	deleteAdressCancel() {
		return () => this.selectedAddress = null;
		

	}

	addAddress(e: any) {
		e.preventDefault();

		this.overlayService.open(AddressComponent, {
			client: this.client
		});
	}

	editAddress(e: any, address: CustomerAddress) {
		e.preventDefault();
		e.stopPropagation();
		
		this.overlayService.open(AddressComponent, {
			edit: true,
			address,
			client: this.client
		});
	}


}
