import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VersionGuard } from './core/guards/version.guard';
import { NavigationPage } from './core/services/navigation.service';
import { NotFoundComponent } from './feature/not-found/not-found.component';
import { VersionComponent } from './feature/version/version.component';

const routes: Routes = [
	{
		path: '',
		component: NotFoundComponent,
		data: {
			page: NavigationPage.notFound,
		}
	},
	{
		path: 'v1',
		loadChildren: () => import('./feature/v1/menu/menu.module').then(m => m.MenuModule),
	},
	{
		path: 'v2/profile',
		loadChildren: () => import('./feature/v2/profile/profile.module').then(m => m.ProfileModule),
	},
	{
		path: 'v2/qrcode',
		loadChildren: () => import('./feature/v2/qrcode/qrcode.module').then(m => m.QrCodeModule),
	},
	{
		path: 'v2/click-go',
		loadChildren: () => import('./feature/v2/click-go/click-go.module').then(m => m.ClickGoModule),
	},
	{
		path: 'v2/in',
		loadChildren: () => import('./feature/v2/in/in.module').then(m => m.InModule),
	},
	{
		path: 'v2/:hash',
		redirectTo: 'v2/qrcode/:hash',
		pathMatch: 'full'
	},
	{
		path: 'v2/:hash/qrcodes',
		redirectTo: 'v2/qrcode/:hash/qrcodes',
		pathMatch: 'full'
	},
	{
		path: 'qrcode/:hash',
		redirectTo: 'v2/qrcode/:hash',
		pathMatch: 'full'
	},
	{
		path: 'click-go/:hash',
		redirectTo: 'v2/click-go/:hash',
		pathMatch: 'full'
	},
	{
		path: 'in/:hash/:table',
		redirectTo: 'v2/in/:hash/:table',
		pathMatch: 'full'
	},
	{
		path: 'in/:hash',
		redirectTo: 'v2/in/:hash',
		pathMatch: 'full'
	},
	{
		path: ':hash',
		component: VersionComponent,
		canActivate: [VersionGuard],
		pathMatch: 'full'
	},
	{
		path: ':hash/qrcodes',
		component: VersionComponent,
		canActivate: [VersionGuard],
		pathMatch: 'full',
		data: {
			to: 'qrcodes'
		}
	},
	{
		path: '**',
		redirectTo: '',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
