import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const apiKey = '3ebea918-5649-11eb-ae93-0242ac130002';
      const token = this.authService.getToken();

      return this.authService.getDecodedToken().pipe(
        take(1),
        switchMap(v => {
          if(v) {
            return next.handle(req.clone({
              headers: req.headers
                .set('Accept-Language', navigator.language)
                .set('apiKey', apiKey)
                .set('X-Timezone-Offset', (new Date()).getTimezoneOffset().toString())
                .set('Authorization', `Bearer ${token}`)
            }));
          }

          return next.handle(req.clone({
            headers: req.headers
              .set('Accept-Language', navigator.language)
              .set('X-Timezone-Offset', (new Date()).getTimezoneOffset().toString())
              .set('apiKey', apiKey)
          }));        
        })
      );
  }
}
