import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-account-order',
	templateUrl: './account-order.component.html',
	styleUrls: ['./account-order.component.scss']
})
export class AccountOrderComponent implements OnInit {

	public connectionMode: boolean = false;
	public registerMode: boolean = false;

	@Input() version: string;
	@Input() hash: string;
	@Input() from: string;

	ngOnInit(): void {}

	connect() {
		this.resetModes();
		this.connectionMode = true;
	}

	register() {
		this.resetModes();
		this.registerMode = true;
	}

	resetModes() {
		this.connectionMode = false;
		this.registerMode = false;
	}
}
