import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client } from 'src/app/core/models/client.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';

@Component({
	selector: 'app-footer-info',
	templateUrl: './info.component.html',
	styleUrls: ['./info.component.scss']
})
export class FooterInfoComponent {

  @Input() client: Client;
  @Input() qrcode: Qrcode;
  @Input() open: boolean = false;
  @Input() canDisplayWifi: boolean = false;

  @Output() onCloseInfo: EventEmitter<void>;

  constructor(
		protected route: ActivatedRoute,
	) {
    this.onCloseInfo = new EventEmitter<void>();
  }

  public closeInfo(): void {
    this.onCloseInfo.emit();
  }

  hasSocials(qrcode: Qrcode) {
    return [
      "websiteUrl",
      "twitterUrl",
      "facebookUrl",
      "instagramUrl",
      "youtubeUrl",
      "tripadvisorUrl",
      "whatsAppUrl",
      "tikTokUrl"

    ].some(social => qrcode[social] && qrcode[social].length > 0)
  }

}
