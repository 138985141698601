import { Component, Input, EventEmitter, Output, HostListener, ElementRef, OnInit } from '@angular/core';
import { Menu } from 'src/app/core/models/menu.model';
import { Product } from 'src/app/core/models/product.model';

@Component({
	selector: 'app-counter',
	templateUrl: './counter.component.html',
	styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
	
	public expanded = false;
	public lastScrollState: number;
	public scrollDiff: number = 50;

	@Input() product: Product;
	@Input() menu: Menu;

	@Input() count: number;

	@Output() onAddItem: EventEmitter<Product | Menu>;
	@Output() onRemoveItem: EventEmitter<Product | Menu>;
	@Output() onRemoveAllItems: EventEmitter<Product | Menu>;
	@Output() onSelectItem: EventEmitter<Product | Menu>;

	public constructor(protected elementRef: ElementRef) {
		this.onAddItem = new EventEmitter<Product | Menu>();
		this.onRemoveItem = new EventEmitter<Product | Menu>();
		this.onRemoveAllItems = new EventEmitter<Product | Menu>();
		this.onSelectItem = new EventEmitter<Product | Menu>();
	}

	ngOnInit(): void {
		this.lastScrollState = window.scrollY;
	}

	delete(e: any) {
		e.preventDefault();
		e.stopPropagation();
		this.onRemoveAllItems.emit(this.product ?? this.menu);
		this.expanded = false;
	}

	minus(e: any) {
		e.preventDefault();
		e.stopPropagation();
		this.onRemoveItem.emit(this.product ?? this.menu);
	}

	plus(e: any) {
		e.preventDefault();
		e.stopPropagation();
		this.onAddItem.emit(this.product ?? this.menu);
		this.lastScrollState = window.scrollY;
		this.expanded = true;
	}

	option(e: any) {
		e.preventDefault();
		e.stopPropagation();
		this.onSelectItem.emit(this.product ?? this.menu);
	}

	@HostListener('click', ['$event'])
	setExpanded(e) {
		e.preventDefault();
		e.stopPropagation();
		this.lastScrollState = window.scrollY;
		this.expanded = true;
	}

	@HostListener('document:click', ['$event'])
	@HostListener('document:touchstart', ['$event'])
	public setCollapsed(e) {
	  if (!this.elementRef.nativeElement.contains(e.target)) {
		this.expanded = false;
	  }
	}

	@HostListener('window:scroll', ['$event'])
	checkScrolling(e) {
		const diff = Math.abs(this.lastScrollState - window.scrollY)
		if(diff > this.scrollDiff && this.expanded) {
			this.lastScrollState = window.scrollY;
			this.expanded = false;
		}
	}

	get complex() {
		if(this.menu) return true
		return (this.product.groupOptions && this.product.groupOptions.length) || this.product.isMultiprice;
	}
}
