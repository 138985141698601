import { Product } from "./product.model";

export class MenuStep {

    id: number;
    position: number;
    name: string;
    menuId: number;
    products: Product[];

    constructor(id: number, position: number, name: string, menuId: number, products: Product[]) {
        this.id = id;
        this.position = position;
        this.name = name;
        this.menuId = menuId;
        this.products = products;
    }
}