import { Injectable } from "@angular/core";
import { MenuPrice } from "../menu-price.model";
import { MenuStep } from "../menu-step.model";
import { Menu } from "../menu.model";
import { Adapter } from "./adapter";
import { ProductAdapter } from "./product.adapter";

@Injectable({
    providedIn: "root",
})
export class MenuAdapter implements Adapter<Menu> {

    constructor(
        protected productAdapter: ProductAdapter
    ) { }

    adapt(item: any): Menu {

        let steps = (item.steps) 
        ? item.steps.map(step => this.adaptStep(step))
            .sort((a, b) => (a.position > b.position) ? 1 : -1) 
        : [];

        let prices = (item.prices) 
        ? item.prices.map(price => this.adaptPrice(price))
            .sort((a, b) => (a.position > b.position) ? 1 : -1) 
        : [];
        
        return new Menu(
            item.id,
            item.position,
            item.name,
            item.price,
            item.isMultiprice,
            item.isEnable,
            item.scheduling,
            item.isScheduled,
            item.availability,
            item.pictureUrl,
            item.famillyId,
            steps,
            prices
        );
    }

    adaptStep(item: any): MenuStep {

        let products = (item.products) ? item.products.map(product => this.productAdapter.adapt(product)) : [];
        return new MenuStep(
            item.id,
            item.position,
            item.name,
            item.menuId,
            products
        );
    }

    adaptPrice(item: any): MenuPrice {
        return new MenuPrice(item.id, item.position, item.price, item.name);
    }
}