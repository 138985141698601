import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { ClickCollect } from 'src/app/core/models/click-collect.model';
import { Client } from 'src/app/core/models/client.model';
import { Product } from 'src/app/core/models/product.model';
import { Qrcode } from 'src/app/core/models/qrcode.model';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { ProcessOrder, ProcessType } from 'src/app/core/models/process-order.model';
import { ShopCartService } from 'src/app/core/services/shop-cart.service';
import { AppStateService } from 'src/app/core/services/app-state.service';
import { ShopCart } from 'src/app/core/models/shop-cart.model';
import { StripeService } from 'src/app/core/services/stripe.service';
import { ItemMenuProduct } from 'src/app/core/models/item-menu-product.model';
import { CustomerAddress } from 'src/app/core/models/customer-address.model';
import { CollectionPoint } from 'src/app/core/models/collection-point.model';
import { FormGroup } from '@angular/forms';
import { DeliveryZone } from 'src/app/core/models/delivery-zone.model';
import { VersionService } from 'src/app/core/services/api/version.service';
import { RouterService } from 'src/app/core/services/router.service';

@Component({
	selector: 'app-order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, OnDestroy {

  public selectedProduct: Product;
  public paymentHandler: any = null;
  protected subscriptions: Subscription[] = [];
  public version: string = 'v2';
  
  @Input() hash: string;
  @Input() onSaveOrder: boolean = false;
  @Input() loading = false;
  @Input() logged = false;
  @Input() processOrder: ProcessOrder;
	@Input() client: Client;
	@Input() qrcode: Qrcode;
  @Input() clickcollect: ClickCollect;
	@Input() items: ItemMenuProduct[] = [];
  @Input() totalCart = 0;
  @Input() cartValues: Product[] = [];
	@Input() pageType: string = 'click-go';
  @Input() minOrder = false;
  @Input() cart: ShopCart;
  @Input() deliveryCost = 0;
  @Input() confirmationMode: boolean = false;
  @Input() confirmationType: ProcessType = ProcessType.delivery;
  @Input() formChoice: FormGroup;
  @Input() formOrder: FormGroup;
  @Input() stripeError: boolean = false;
  @Input() has18YearOldItems: boolean = false;
  @Input() is18YearOldError: boolean = false;
  @Input() tableError: boolean = false;
  @Input() table: string;
  @Input() zones: DeliveryZone[] = [];
	@Input() exceptions: DeliveryZone[] = [];
	@Input() editMode: boolean = false;
  @Input() editProcessOrder: ProcessOrder;
	@Output() onReloadCart: EventEmitter<void>;
	@Output() onChangeEditMode: EventEmitter<boolean>;
	@Output() onSelectDeliveryZone: EventEmitter<DeliveryZone>;

	constructor(
    protected routerService: RouterService,
    protected overlayService: OverlayService,
    protected authService: AuthService,
    protected shopCartService: ShopCartService,
    protected appStateService: AppStateService,
    protected stripeService: StripeService,
    protected versionService: VersionService,

	) {
    this.onReloadCart = new EventEmitter<void>();
    this.onChangeEditMode = new EventEmitter<boolean>();
    this.onSelectDeliveryZone = new EventEmitter<DeliveryZone>();
  }

	ngOnInit() {
    this.invokeStripe();
	}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if(sub) sub.unsubscribe();
    })
  }

  public getCurrency(qrcode: Qrcode) {
		if(!qrcode) return '';
		return qrcode.currencyTableValue || qrcode.currency;
	}

	public productAction(index: number): void {
		let product = this.items[index].product;
    if(this.pageType === "in") 
      this.routerService.navigate(['/', this.version, this.pageType, this.hash, this.table, 'family', product.famillyId,'product', product.id]);
		else this.routerService.navigate(['/', this.version, this.pageType, this.hash, 'family', product.famillyId,'product', product.id]);
	}

  public menuAction(index: number): void {
		let menu = this.items[index].menu;
    if(this.pageType === "in")
      this.routerService.navigate(['/', this.version, this.pageType, this.hash, this.table, 'family', menu.famillyId,'menu', menu.id]);
		else this.routerService.navigate(['/', this.version, this.pageType, this.hash, 'family', menu.famillyId,'menu', menu.id]);
	}


  public selectDateTime(date: Date) {
		if(date) this.editProcessOrder.orderAt = date ? date.toISOString() : null;
	}

  public selectDeliveryZone(zone: DeliveryZone) {
		this.editProcessOrder.deliveryZone = zone;
    this.onSelectDeliveryZone.emit(zone)
	}

	public selectAddress(address: CustomerAddress) {
		this.editProcessOrder.address = address;
	}

	public selectCollectionPoint(cp: CollectionPoint) {
		this.editProcessOrder.collectionPoint = cp;
	}

  public selectEditMode(value: boolean) {
    this.onChangeEditMode.emit(value);
  }

  public invokeStripe() {
    this.stripeService.invokeStripe().subscribe(
      e => this.paymentHandler = e
    );
  }

  get cartLength() {
    return this.shopCartService.length();
  }
}

